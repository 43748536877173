import React from 'react';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const ButtonsDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Buttons</h1>

            <h2 className="mb-3">Group of buttons with long text (in toolbar container)</h2>
            <h3>If we there is a group of buttons with long text, place them one under another with 100% width. </h3>
            <ul className="basic-list mb-2">
                <li>This will prevent the button from breaking the text in two lines and becoming too high.</li>
            </ul>

            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "300px", border:"4px solid #f3f3f3" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/buttons/button-wth-long-text.png`} />
            </div>
        </div>
    )
}

export default ButtonsDocs;