import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const LayoutClientPortalDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Layout Client Portal</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">There is a specific UI structure for <strong>Client Portal</strong> and the design system provides the necessary building blocks for it. It is crucial to implement strictly the showcased structure of layout components.</p>

            <h5 className="mb-2"><Link to="/docs/demo-pages">Demo Pages</Link></h5>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { ReactAux, MainContainer, NavMenu, Header, Banner, Footer, Tooltip } from '@jkhy/vsg-design-system';

class Example extends Component {
    render() {

        const navMenu = <NavMenu ... />;

        const header = <Header
            accountBtn={{
                content: <div className="mt-2 mb-2 ml-2 mr-2">
                    <h3 className="mb-2">Account</h3>
                    <p className="mb-1">John Preston</p>
                    <p className="mb-2">webuser35@mailinator.com</p>
                    <LinkWithIcon className="mb-2" icon={IconsSolid.faLockAlt}>
                        <Link to="/client-portal">
                        Change password
                        </Link>
                    </LinkWithIcon>
                    <ActionsWrapper directionCol>
                        <Button btnType="secondary" iconLeft={IconsSolid.faSignOutAlt}>Sign out now</Button>
                    </ActionsWrapper>
                </div>,
                isVisible: this.state.accountVisible,
                onOpen: this.handleOpenAccount,
                onClose: this.handleCloseAccount,
            }}
            navMenu={navMenu}
            isMenuVisible={this.state.navMenuVisible}
            onMenuOpen={this.handleOpenMenu}
            onMenuClose={this.handleCloseMenu}
        />;

        const banner = <Banner ... />;

        const footer = <Footer
            topText='All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review.'
            bottomText='© Copyright 2020. Powered by Jack Henry'
            version="Version 1.22.33.44"
        />;

        return (
            <ReactAux>
                <MainContainer
                    header={header}
                    leftSideElement={navMenu}
                    bannerElement={banner}
                    footer={footer}
                >
                    <Switch>
                        <Route path="..." component={Page} />
                        <Route path="..." component={Page2} />
                        <Route path="..." component={Page3} />
                    </Switch>
                </MainContainer>
            </ReactAux>
        );
    }
}

export default Example; 
    `
                }
            </Highlight>
        </div>
    )
}

export default LayoutClientPortalDocs;