import React, { useState } from 'react';

import {
  Button, Section, FormGroup, Select, Radio, Input, ActionsWrapper, IconsSolid,
  ReactAux, MaskedInput
} from '@jkhy/vsg-design-system';

const PageRenInformation = () => {
  const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isNewPhone, setIsNewPhone] = useState(false);
  const [isNewTax, setIsNewTax] = useState(false);
  return (
    <ReactAux>
      <Section
        title="Information"
        footerText="Click ‘Continue’ to proceed."
      >
        <p className="mb-3 text-muted">Please answer the following questions.</p>
        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Has your mailing address changed?.">
          <Radio htmlFor="changeAdressYes" id="changeAdressYes" className="d-inline-block" name="change_address" value="yes" onChange={() => setIsNewAddress(true)}>Yes</Radio>
          <Radio htmlFor="changeAdressNo" id="changeAdressNo" className="d-inline-block" name="change_address" value="no" onChange={() => setIsNewAddress(false)}>No</Radio>
        </FormGroup>
        {
          isNewAddress && (
            <>
              <FormGroup label="Address" isRequired htmlFor="address" className="mb-2">
                <Input type="text" name="address" id="address" />
              </FormGroup>

              <FormGroup label="City" isRequired htmlFor="city" className="mb-2">
                <Input type="text" name="city" id="city" />
              </FormGroup>

              <FormGroup isRequired label="State Code" className="mb-2" htmlFor="stateCode">
                <Select
                  id="stateCode"
                  placeholder="Select..."
                  options={[
                    { value: 'AL', label: 'AL' },
                    { value: 'AK', label: 'AK' },
                    { value: 'AZ', label: 'AZ' },
                    { value: 'CA', label: 'CA' },
                    { value: 'CO', label: 'CO' },
                    { value: 'DE', label: 'DE' },
                    { value: 'DC', label: 'DC' },
                    { value: 'etc', label: 'etc...' },
                  ]}
                />
              </FormGroup>

              <FormGroup label="Zipcode" isRequired htmlFor="zipcode" className="mb-2">
                <Input type="text" name="zipcode" id="zipcode" />
              </FormGroup>
            </>
          )
        }

        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Has your phone number changed?">
          <Radio htmlFor="changePhoneYes" id="changePhoneYes" className="d-inline-block" name="change_phone" value="yes" onChange={() => setIsNewPhone(true)}>Yes</Radio>
          <Radio htmlFor="changePhoneNo" id="changePhoneNo" className="d-inline-block" name="change_phone" value="no" onChange={() => setIsNewPhone(false)}>No</Radio>
        </FormGroup>

        {
          isNewPhone && (
            <FormGroup isRequired label="Phone number" htmlFor="phone" className="mb-2">
              <MaskedInput
                id="phone"
                type='phone'
                mask={usPhoneCountryCodeMask}
                icon={IconsSolid.faPhoneAlt}
                name="phone"
              />
            </FormGroup>
          )
        }

        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Are any taxes currently past due by the business or any principal?">
          <Radio htmlFor="changeTaxYes" id="changeTaxYes" className="d-inline-block" name="change_tax" value="yes" onChange={() => setIsNewTax(true)}>Yes</Radio>
          <Radio htmlFor="changeTaxNo" id="changeTaxNo" className="d-inline-block" name="change_tax" value="no" onChange={() => setIsNewTax(false)}>No</Radio>
        </FormGroup>
        {
          isNewTax && (
            <FormGroup label="Please explain" htmlFor="explainTax" className="mb-2">
              <Input type="text" name="explain_tax" id="explainTax" />
            </FormGroup>
          )
        }

        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Are you an Executive Officer, Director, or Principal Shareholder of a bank?">
          <Radio htmlFor="isDirectorYes" id="isDirectorYes" className="d-inline-block" name="change_director" value="yes">Yes</Radio>
          <Radio htmlFor="isDirectorNo" id="isDirectorNo" className="d-inline-block" name="change_director" value="no">No</Radio>
        </FormGroup>

      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: `/renewals/pages/4` }}>Next step</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PageRenInformation;