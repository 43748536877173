import React from 'react';
import Highlight from 'react-highlight';

const Testing = () => {

  return <div>
    <h1 className='mb-3'>UI Testing utilities</h1>

    <p className='mb-4'>In order to make UI testing easier, all components provide a special property - <strong>dataUI</strong>.
      It helps querying the component elements in the DOM, with a generic approach in the whole Design System.
      The value of the <strong>dataUI</strong> property is always set as the value of a unique <strong>data-ui</strong> HTML attribute
      to the wrapping element of a given component.</p>

    <Highlight className="React mb-4">
      {
        `
import React from 'react';
import { Container, Input } from '@jkhy/vsg-design-system';

// Adding the testing prop to components:
<Container dataUI="testContainer">
  <FormGroup htmlFor="testInput" label='Test input'>
    <Input dataUI="testInput" id="testInput"/>
  </FormGroup>
</Container>

// The rendered HTML output (the elements are specific for the demo, but all components 
// in the Design System are suitable for dataUI testing property and regardless of the
// HTML elements' names, the data-ui attribute will be attached):
<div class="container" data-ui="testContainer">
  <FormGroup htmlFor="testInput" label='Test input'>
      <input class="form-control" id="testInput" data-ui="testInput">
  </FormGroup>
</div>

`
      }
    </Highlight>
  </div>;
}

export default Testing;