import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Fab, Avatar, IconsSolid, LinkWithIcon } from '@jkhy/vsg-design-system';

const FabDocs = () => {
    const [isFabOpen, setFabOpen] = useState(false);
    return (
        <div>
            <h1 className='mb-3'>Fab</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Fixed button in right bottom of the page, which opens container with list of items and header.</p>

            <Fab
                icon={IconsSolid.faPiggyBank}
                isOpen={isFabOpen}
                onFabClick={() => setFabOpen(!isFabOpen)}
                onFabClose={() => setFabOpen(false)}
                headerLogo
                tolltipText='This is Fab component'
                items={[
                    <a href="/#" className="d-flex"><Avatar className="mr-05 ml-05" src="https://www.w3schools.com/howto/img_avatar2.png" />Chat with us</a>,
                    <a href="/#" className="d-flex"><Avatar className="mr-05 ml-05" src="https://www.w3schools.com/howto/wrong_url.png" />Default avatar</a>,
                    <LinkWithIcon icon={IconsSolid.faPhoneAlt}><a href="/#">0800 800 800</a></LinkWithIcon>,
                    <p className="body2">P text here</p>,
                    <p>P Small text here</p>,
                    <div>Div text here</div>,
                    <span>Span text here</span>,
                    <h2>h2 text here</h2>,
                    <h3>h3 text here</h3>,
                    <h4>h4 text here</h4>,
                    <h5>h5 text here</h5>,
                    <h6>h6 text here</h6>,
                    <>DUMMY text here</>,
                    'just string'
                ]}
            />
            <Highlight className="React mb-4">
                {
                    `
import React, { useState } from 'react';
import {  Fab, Avatar, IconsSolid, LinkWithIcon  } from '@jkhy/vsg-design-system';

const Example = (props) => {
    const [isFabOpen, setFabOpen] = useState(false);
    return (
        <div>
            <Fab
                icon={IconsSolid.faPiggyBank}
                isOpen={isFabOpen}
                onFabBtnClick={()=> setFabOpen(!isFabOpen)}
                onCloseFab={() => setFabOpen(false)}
                fabHeaderLogo
                fabTolltipText='This is Fab component'
                fabItems={[
                    <a href="/#" className="d-flex"><Avatar className="mr-05 ml-05" src="https://www.w3schools.com/howto/img_avatar2.png" />Chat with us</a>,
                    <a href="/#" className="d-flex"><Avatar className="mr-05 ml-05" src="https://www.w3schools.com/howto/wrong_url.png" />Default avatar</a>,
                    <LinkWithIcon icon={IconsSolid.faPhoneAlt}><a href="/#">0800 800 800</a></LinkWithIcon>,
                    <p className="body2">P text here</p>,
                    <p>P Small text here</p>,
                    <div>Div text here</div>,
                    <span>Span text here</span>,
                    <h2>h2 text here</h2>,
                    <h3>h3 text here</h3>,
                    <h4>h4 text here</h4>,
                    <h5>h5 text here</h5>,
                    <h6>h6 text here</h6>,
                    <>DUMMY text here</>,
                    'just string'
            ]}
        />
        </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>items</td>
                        <td>Array of JSX Elements or strings</td>
                        <td className='text-center'>-</td>
                        <td>Set fab items.</td>
                    </tr>
                    <tr>
                        <td>headerLogo</td>
                        <td>boolean</td>
                        <td className='text-center'>false</td>
                        <td>Set header logo to be visible or hidden.</td>
                    </tr>
                    <tr>
                        <td>tolltipText</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set tooltip to Fab button.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>IconDefinition</td>
                        <td>IconsSolid.faQuestion</td>
                        <td>Set Fab button icon.</td>
                    </tr>
                    <tr>
                        <td>isOpen</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set Fab content to show or hide.</td>
                    </tr>
                    <tr>
                        <td>onFabClick</td>
                        <td>void, you will receive as param the native click event</td>
                        <td className='text-center'>-</td>
                        <td>Set click to Fab button.</td>
                    </tr>
                    <tr>
                        <td>onFabClose</td>
                        <td>void</td>
                        <td className='text-center'>-</td>
                        <td>Close Fab when click out of the component.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native div attributes to Fab wrapper element.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FabDocs;