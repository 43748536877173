import React from 'react';
import { Link } from 'react-router-dom';

const LinkItem = (props: any) => {

    const locationPath = window.location.pathname;

    let isActive = null;

    const links = props.links.map((l: any, i: number) => {

        return <li key={i} className={locationPath === l.path ? isActive = 'active' : ''}>
            <Link to={l.path}>{l.title}</Link>
        </li>
    });

    return <div className={`link-item${isActive === 'active' ? ' active' : ''}`}>
        <Link to={props.links[0].path} className="link">{props.title}</Link>
        <ul className="sidenav">
            {links}
        </ul>
    </div>
}

export default LinkItem;