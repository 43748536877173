import React from 'react';
import Highlight from 'react-highlight';
import { LoanRequestBox, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const LoanRequestBoxDocs = () => {

	return <div>
		<h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Loan Request Box</h1>

		<h2 className='mb-2'>Description</h2>
		<p className='mb-2'>Represents a group of title, description and call-to-action button for different loan request types.</p>
		<p className='mb-4'><strong>Commercial</strong> and <strong>Consumer</strong> loan request types are supported currently.</p>

		<p className="mb-2">
			<strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> The text content for each loan request box
			is not hard-coded. The values for title, description and the button title are <strong>dynamic and are loaded
				through CSS variables</strong>. The list of CSS variables is as follows:
		</p>
		<ul className="mb-2">
			<li><strong>--loan-req-commercial-title</strong></li>
			<li><strong>--loan-req-commercial-description</strong></li>
			<li><strong>--loan-req-commercial-cta</strong></li>
			<li><strong>--loan-req-consumer-title</strong></li>
			<li><strong>--loan-req-consumer-description</strong></li>
			<li><strong>--loan-req-consumer-cta</strong></li>
		</ul>
		<p className="mb-4">
			<strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> The values of the CSS variables should
			be of <strong>string data type</strong>.
			<br />
			If set in a stylesheet, the values should look like this:
			<br />
			<i>--loan-req-commercial-title: "Some text"</i>
			<br />
			If the CSS variables are going to be set dynamically through JavaScript, the quotes have to be escaped
			or just wrapped with another set of quotes.
		</p>

		<div className='mb-2' style={{ width: '500px' }}>
			<LoanRequestBox requestType="commercial" onButtonClick={() => alert('Click on commercial loan request.')} className="mb-3" />
			<LoanRequestBox requestType="consumer" onButtonClick={() => alert('Click on consumer loan request.')} />
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';
import { LoanRequestBox } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
	<LoanRequestBox requestType="commercial" onButtonClick={()=>alert('Click on commercial loan request.')} />
	<LoanRequestBox requestType="consumer" onButtonClick={()=>alert('Click on consumer loan request.')} />
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>

		<table className="doc-table mb-3">
			<thead>
				<tr>
					<th colSpan={4}>Props</th>
				</tr>
				<tr>
					<td>
						<strong>Name</strong>
					</td>
					<td>
						<strong>Options</strong>
					</td>
					<td>
						<strong>Default</strong>
					</td>
					<td>
						<strong>Description</strong>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>requestType</td>
					<td>"commercial", "consumer"</td>
					<td className='text-center'>-</td>
					<td>Sets the type of the loan request. Depending on this value, different text content will be rendered.
						This prop is <strong>required</strong>.</td>
				</tr>
				<tr>
					<td>onButtonClick</td>
					<td>{'()=>void'}</td>
					<td className='text-center'>-</td>
					<td>Callback, executed after the call-to-action button of the given loan request box is clicked. This prop is <strong>required</strong>.</td>
				</tr>
			</tbody>
		</table>
	</div>;
}

export default LoanRequestBoxDocs;