import React from 'react';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const RadioVsSelectDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Radio vs Select</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Guidelines on when to use Radio or Select.</p>

            <h2 className='mb-3'>Use radio buttons:</h2>
            <h3>When you have less than 5 options</h3>
            <ul className="basic-list mb-2">
                <li>User scans the option easily and quickly.</li>
                <li>He provides quick response instead of opening a drop-down menu and select from multiple options.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select.png`} />
            </div>

            <h3 className="mb-2">When You Want to Emphasize Different Options</h3>
            <ul className="basic-list mb-2">
                <li>There is no default or recommended option.</li>
                <li>You want user to read all options - they are unfamiliar to the user and he needs to read them.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-2.png`} />
            </div>

            <h3 className="mb-2">When Comparison of Options needs to be easy</h3>
            <ul className="basic-list mb-2">
                <li>User can see them at a glance and compare them easily.</li>
                <li>Comparing and deciding about an option from a drop-down menu takes time.</li>
                <li>User has to open the menu and compare options each time he wants to review the selected option.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-3.png`} />
            </div>

            <h3 className="mb-2">When Visibility and Quick Response Is A Priority</h3>
            <ul className="basic-list mb-2">
                <li>For longer forms, it becomes easier and quicker to scan the options and mark the required ones.</li>
                <li>Clicking a drop-down each time to select something takes a lot of time.</li>
            </ul>
            <div className="d-flex mb-6">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-4.png`} />
            </div>

            <h2 className='mb-3'>Layout of Radio Buttons:</h2>
            <h3>Vertical Lay out</h3>
            <ul className="basic-list mb-2">
                <li>Use for 3 or more options.</li>
                <li>Easier to scan and understand - better user experience.</li>
                <li>No risk of user getting confused which radio goes with which label.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "300px", border:"4px solid #f3f3f3" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-5.png`} />
            </div>

            <h3>Horizontal Layout</h3>
            <ul className="basic-list mb-2">
                <li>Use for Yes/No options.</li>
                <li>Could be used for up to 2 options with short labels.</li>
            </ul>
            <div className="d-flex">
                <img className="w-100" style={{ maxWidth: "300px", border:"4px solid #f3f3f3" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-6.png`} />
            </div>
            <ul className="basic-list mb-2">
                <li>Make it challenging to tell with which label the radio button corresponds: the one before the button or the one after. If you do need to use horizontal alignment make sure to space the buttons and labels so that it’s absolutely clear which choice goes with which label.</li>
            </ul>
            <div className="d-flex mb-6">
                <img className="w-100" style={{ maxWidth: "700px", border:"4px solid #f3f3f3" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-7.png`} />
            </div>

            <h2 className='mb-3'>Use Dropdown menus:</h2>
            <h3>When you have more than 5 options</h3>
            <ul className="basic-list mb-2">
                <li>With many options UI becomes cluttered and user the can get confused.</li>
                <li>It takes time to scan a large list of radio options.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-8.png`} />
            </div>

            <h3>When a large number of familiar options are available</h3>
            <ul className="basic-list mb-2">
                <li>User can predict them easily.</li>
                <li>There is no need for him to see options side by side.</li>
            </ul>
            <div className="d-flex mb-5">
                <img className="w-100" style={{ maxWidth: "700px" }} alt="img" src={`${process.env.PUBLIC_URL}/img/guidelines/radio-select/radio-select-9.png`} />
            </div>
        </div>
    )
}

export default RadioVsSelectDocs;