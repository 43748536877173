import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const HeaderDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Header</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-1">Header is navigation components that displays information and actions relating to the current screen. Use it in <Link to="/docs/mainContainer">Main Container</Link>.</p>
            <p className="mb-1">The logo in Header is <strong>set</strong> by <Link to="/docs/introduction">CSS variable</Link> <strong>--header-logo</strong>.</p>
            <p className="mb-4">
                There are three supported sizes for the logo: "S", "M" & "L", which are set
                through <Link to="/docs/introduction">CSS variables:</Link> <strong>--header-logo-height</strong> & <strong>--header-logo-margin</strong>.
                The design system stylesheet sets the default values for these variables as for size "S".
                These sizes are meant to be modified externaly by the design system client.
                <br />
                As a guideline, the margin styles are an exposed implementation detail property, serving to ensure 
                the Header container does not expand excessively.
            </p>

            <h5 className="mb-2"><Link to="/docs/demo-pages">Demo Pages with Header</Link></h5>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { Header, NavMenu, IconsSolid } from '@jkhy/vsg-design-system';

class Example extends Component {

    state = {
        visible: false,
        logoConfirmVisible: false,
        homeConfirmVisible: false
    }

    handleOpenMenu = () => {
        this.setState({ visible: true });
    }

    handleCloseMenu = () => {
        this.setState({ visible: false });
    }

    openLogoPopup = () => this.setState({ logoConfirmVisible: true });

    closeLogoPopup = () => this.setState({ logoConfirmVisible: false });

    openHomeLinkPopup = () => this.setState({ homeConfirmVisible: true });

    closeHomeLinkPopup = () => this.setState({ homeConfirmVisible: false });

    getHomePopupContent = (onSuccess: any, onCancel: any) => (
        <>
            <h3 className="mb-2">Go to Client Portal</h3>
            <p className="mb-3">Are you sure you want go leave the page ?</p>
            <div className="d-flex">
                <Link to="/client-portal">
                    <Button
                        btnType="primary mr-2"
                        onClick={onSuccess}
                    >
                        Yes
                    </Button>
                </Link>
                <Button btnType="secondary" onClick={onCancel}>No</Button>
            </div>

        </>
    )

    render() {

        const navMenu = <NavMenu .... />;

        return (
            <Header
            links={[
                { text: 'Home', path: '/client-portal', icon: IconsSolid.faHome, isHomeLink: true, title:'Home', ariaLabel: 'Home Page' },
                { text: 'FAQs', path: '#', icon: IconsSolid.faComments, title:'FAQs', ariaLabel: 'Faqs Page' },
                { text: 'Sign Out', path: '#', icon: IconsSolid.faSignOutAlt,title:'Sign out', ariaLabel: 'Sign out' }
            ]}
                
                // nav menu
                navMenu={navMenu}
                isMenuVisible={this.state.visible}
                onMenuOpen={this.handleOpenMenu}
                onMenuClose={this.handleCloseMenu}

                // set popup for logo and homelink
                homeLinkConfirmation={{
                    content: this.getHomePopupContent(this.closeHomeLinkPopup, this.closeHomeLinkPopup),
                    isVisible: this.state.homeConfirmVisible,
                    onOpen: this.openHomeLinkPopup,
                    onClose: this.closeHomeLinkPopup,
                }}
                logoLinkConfirmation={{
                    content: this.getHomePopupContent(this.closeLogoPopup, this.closeLogoPopup),
                    isVisible: this.state.logoConfirmVisible,
                    onOpen: this.openLogoPopup,
                    onClose: this.closeLogoPopup,
                }}
                
                // set simple link to logo/text if logoLinkConfirmation is not set
                logoSimpleLink={{path: '/client-portal'}}

                // set header logo/text accessibility props
                logoAccessibility={{
                    title: 'Home',
                    ariaLabel: 'Home Page'
                }}
            />
        );
    }
}

export default Example; 
    `
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" /> logoText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set text on logo place. If logoText is seted the logo image is not showing.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" /> left</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" /> right</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />links</td>
                        <td>array of objects with keys: "text", "path", "icon", "onClick", "isHomeLink", title, ariaLabel</td>
                        <td className="text-center">-</td>
                        <td>Set action links in right side of the header.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> accountBtn</td>
                        <td>object with keys: "content", "isVisible", "onOpen", "onClose"</td>
                        <td className="text-center">-</td>
                        <td>Set the account button.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> homeLinkConfirmation</td>
                        <td>object with keys: "content", "isVisible", "onOpen", "onClose"</td>
                        <td className="text-center">-</td>
                        <td>This enables a popover, that will show a confirmation before redirecting and this prop contains its data.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> logoLinkConfirmation</td>
                        <td>object with keys: "content", "isVisible", "onOpen", "onClose"</td>
                        <td className="text-center">-</td>
                        <td>This enables a popover, that will show a confirmation before redirecting and this prop contains its data.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> logoSimpleLink</td>
                        <td>object with keys: <br />
                            "path", "onClick"
                        </td>
                        <td className="text-center">-</td>
                        <td>Set router Link wrapper to header logo or text if "logoLinkConfirmation" is not set.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> logoAccessibility</td>
                        <td>object with keys: <br />
                            "title": string,<br />
                            "ariaLabel": string
                        </td>
                        <td className="text-center">-</td>
                        <td>Set logo/text title and aria-label.</td>
                    </tr>
                    <tr>
                        <td>navMenu</td>
                        <td><Link to="/docs/navMenu">JSX.Element</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the navMenu.</td>
                    </tr>
                    <tr>
                        <td>isMenuVisible</td>
                        <td>boolen</td>
                        <td>false</td>
                        <td>Set navMenu visibility.</td>
                    </tr>
                    <tr>
                        <td>onMenuOpen</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the navMenu is open.</td>
                    </tr>
                    <tr>
                        <td>onMenuClose</td>
                        <td>function</td>
                        <td className="text-center">-</td>
                        <td>Callback to execute when the navMenu is closed.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default HeaderDocs;