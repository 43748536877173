import React, { useState } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio, Select,
    ScrollableContainer, Checkbox, Modal, Input,
} from '@jkhy/vsg-design-system';

const PageDemoModal = () => {
    const [showSecondModal, setShowSecondModal] = useState(false);

    return (
        <ReactAux>
            <Section title="Let’s get Started" footerText="Please continue to the next step.">
                <Button className="d-inline-block mb-1 mr-1" onClick={() => { setShowSecondModal(!showSecondModal) }} >Modal</Button>
                <Modal
                    isVisible={showSecondModal}
                    parentClass="parent-modal-2"
                    title="Modal example"
                    isClosable
                    onClose={() => { setShowSecondModal(!showSecondModal) }}
                    onSubmit={() => { setShowSecondModal(!showSecondModal) }}
                    closeBtnName="cancel"
                    closeBtnIcon={IconsSolid.faTimes}
                    actionBtnName="save"
                    actionBtnIcon={IconsSolid.faCheck}
                >
                    <FormGroup className="mb-2" htmlFor="name" isRequired label="First Name">
                        <Input type="text" name="name" id="name" />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="name2" isRequired label="Last Name">
                        <Input type="text" name="name2" id="name2" />
                    </FormGroup>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Existing Customer">
                        <Radio className="d-inline-block" htmlFor="agreeYes5" id="agreeYes5" name="agree5" value="yes5">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo5" id="agreeNo5" name="agree5" value="no5">No</Radio>
                    </FormGroup>
                </Modal>

                <h4 className="mb-3">Thank you for starting a loan application with us. Please review the items below before continuing.</h4>
                <h3 className="mb-1">Declarations</h3>
                <h5 className="mb-2 text-muted">Please answer the questions below.</h5>
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you agree to have your credit card pulled?">
                    <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
                </FormGroup>
                <FormGroup className="mb-3" htmlFor="select" label="Location of Businesss" isBold>
                    <Select
                        id="select"
                        placeholder="Select..."
                        options={[
                            { value: 'AK', label: 'AK' },
                            { value: 'KK', label: 'KK' },
                            { value: 'MK', label: 'MK' }]}
                    />
                </FormGroup>
                <h3 className="mb-1">Disclosures</h3>
                <h5 className="mb-1 text-muted">Please review the below disclosures.</h5>
                <ScrollableContainer className="mb-1">
                    <p className="mb-2">
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                    <p>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                </ScrollableContainer>
                <Checkbox htmlFor="accept" id="accept" name="accept">
                    I acknowledge that I have received and viewed these documents.
                </Checkbox>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/2' }}>Continue</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageDemoModal;