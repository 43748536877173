import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import {
    Button, AplicationInfoCard, IconsSolid, FontAwesomeIcon,
    Collapse, AplicationStepsProgress, ListBox, ListBoxContainer
} from '@jkhy/vsg-design-system';

const AplicationInfoCardDocs = () => {

    const [collapses, setCollapses] = useState([false]);

    const stepsNames = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];

    const listBoxes = [
        {
            title: {
                text: 'Primary Borrower:',
                isBold: true,
            },
            description: 'Sally Jones'
        },
        {
            title: {
                text: 'Date Started:',
                isBold: true
            },
            description: '01/02/2021'
        },
        {
            title: {
                text: 'Amount Requested:',
                isBold: true

            },
            description: '$35,000'
        },
        {
            iconSettings: {
                icon: IconsSolid.faInfoCircle,
                className: 'text-primary'
            },
            description: 'Expires in 3 days.'
        }
    ];

    const listBoxesSe =  [
        {
            iconSettings: {
                icon: IconsSolid.faComments,
                className: 'text-primary'
            },
            title: {
                text: 'Need Help?'
            },
            description: <Link to="/" title="Chat with us"> Chat with us</Link>
        },
        {
            iconSettings: {
                icon: IconsSolid.faIdCard,
                className: 'text-primary'
            },
            title: {
                text: 'Banker’s Name:',
                isBold: true
            },
            description: 'John Banker'
        },
        {
            iconSettings: {
                icon: IconsSolid.faPhone,
                className: 'text-primary'
            },
            title: {
                text: 'Mobile:',
                isBold: true
            },
            description: <a href="tel:555-555-5555" title="555-555-5555">555-555-5555</a>
        },
        {
            iconSettings: {
                icon: IconsSolid.faEnvelope,
                className: 'text-primary'
            },
            title: {
                text: 'E-mail:',
                isBold: true
            },
            description: <a href="mailto:john@banker.com" title="john@banker.com">john@banker.com </a>
        }
    ];

    const handleCollapseClick = (index: number) => {
        const collapsesCopy = [...collapses];
        collapsesCopy[index] = !collapses[index];

        setCollapses(collapsesCopy);
    }

    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Aplication Info Card</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">Information card which helps you list different information for application.</p>

            <AplicationInfoCard
                title="Application #A1235"
                buttonsRender={<>
                    <Button className="w-50 action-spacer" btnType="secondary">withdraw application</Button>
                    <Button className="w-50">open application</Button>
                </>}
            >
                <AplicationStepsProgress
                    className="mb-2"
                    steps={7}
                    currentStep={1}
                    description={<><strong>Current step:</strong> Waiting Co-Applicant</>}
                    stepsNames={stepsNames}
                />
                <ListBoxContainer>
                    <ListBox
                        boxItems={listBoxes} />
                    <ListBox
                        boxItems={listBoxesSe} />
                </ListBoxContainer>

                <Collapse
                    title="Required Documents"
                    expanded={collapses[0]}
                    onClick={() => handleCollapseClick(0)}
                    badgeContent={5}
                >
                    <p>Content...</p>
                </Collapse>
            </AplicationInfoCard>

            <Highlight className="React mb-4">
                {
                    `
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { 
    Button, AplicationInfoCard, IconsSolid, FontAwesomeIcon,
    Collapse, AplicationStepsProgress, ListBox, ListBoxContainer
} from '@jkhy/vsg-design-system';

const Example = (props) => {
    const stepsNames = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5', 'Step 6', 'Step 7'];
    
    const listBoxes = [
        {
            title: {
                text: 'Primary Borrower:',
                isBold: true,
            },
            description: 'Sally Jones'
        },
        {
            title: {
                text: 'Date Started:',
                isBold: true
            },
            description: '01/02/2021'
        },
        {
            title: {
                text: 'Amount Requested:',
                isBold: true

            },
            description: '$35,000'
        },
        {
            iconSettings: {
                icon: IconsSolid.faInfoCircle,
                className: 'text-primary'
            },
            description: 'Expires in 3 days.'
        }
    ];

    const listBoxesSe =  [
        {
            iconSettings: {
                icon: IconsSolid.faComments,
                className: 'text-primary'
            },
            title: {
                text: 'Need Help?'
            },
            description: <Link to="/" title="Chat with us"> Chat with us</Link>
        },
        {
            iconSettings: {
                icon: IconsSolid.faIdCard,
                className: 'text-primary'
            },
            title: {
                text: 'Banker’s Name:',
                isBold: true
            },
            description: 'John Banker'
        },
        {
            iconSettings: {
                icon: IconsSolid.faPhone,
                className: 'text-primary'
            },
            title: {
                text: 'Mobile:',
                isBold: true
            },
            description: <a href="tel:555-555-5555" title="555-555-5555">555-555-5555</a>
        },
        {
            iconSettings: {
                icon: IconsSolid.faEnvelope,
                className: 'text-primary'
            },
            title: {
                text: 'E-mail:',
                isBold: true
            },
            description: <a href="mailto:john@banker.com" title="john@banker.com">john@banker.com </a>
        }
    ];

    const [collapses, setCollapses] = useState([false]);

    const handleCollapseClick = (index: number) => {
        const collapsesCopy = [...collapses];
        collapsesCopy[index] = !collapses[index];

        setCollapses(collapsesCopy);
    }
    return (
    <div>
        
            <AplicationInfoCard
                title="Application #A1235"
                buttonsRender={<>
                    <Button className="w-50 action-spacer" btnType="secondary">withdraw application</Button>
                    <Button className="w-50">open application</Button>
                </>}
            >
                <AplicationStepsProgress
                    className="mb-2"
                    steps={7}
                    currentStep={1}
                    description={<><strong>Current step:</strong> Waiting Co-Applicant</>}
                    stepsNames={stepsNames}
                />
                <ListBoxContainer>
                    <ListBox
                        boxItems={listBoxes} />
                    <ListBox
                        boxItems={listBoxesSe} />
                </ListBoxContainer>

                <Collapse
                    title="Required Documents"
                    expanded={collapses[0]}
                    onClick={() => handleCollapseClick(1)}
                    badgeContent={5}
                >
                    <p>Content...</p>
                </Collapse>
            </AplicationInfoCard>

    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title to the card.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set description to the card.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>array of objects with keys: "text", "path", "isInvalid"</td>
                        <td className="text-center">-</td>
                        <td>Set details to the card.</td>
                    </tr>
                    <tr>
                        <td>buttonsRender</td>
                        <td>{'JSX.Element'}</td>
                        <td className="text-center">-</td>
                        <td>Render action buttons.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AplicationInfoCardDocs;