import React from 'react';
import Highlight from 'react-highlight';
import { Avatar, IconsSolid, FontAwesomeIcon } from '@jkhy/vsg-design-system';

const AvatarDocs = () => {

	return <div>
		<h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Avatar</h1>

		<h2 className='mb-2'>Description</h2>
		<p className='mb-4'>Avatar with img or icon.</p>

		<div className='mb-2 d-flex' style={{ background: 'var(--color-text)', padding:'2rem'}}>
			<Avatar className="mr-1" src="https://www.w3schools.com/howto/img_avatar2.png" alt="demoAvatar"/>
            <Avatar className="mr-1" src="https://www.w3schools.com/howto/wrong_url.png" alt="demoAvatar"/>
            <Avatar className="mr-1" defaultIcon={IconsSolid.faPiggyBank}/>
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';
import { Alert } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Avatar className="mr-1" src="https://www.w3schools.com/howto/img_avatar2.png" alt="demoAvatar"/>
        // Example src not loaded or not set
        <Avatar className="mr-1" src="https://www.w3schools.com/howto/wrong_url.png" alt="demoAvatar"/>

        <Avatar className="mr-1" defaultIcon={IconsSolid.faPiggyBank}/>
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>

		<table className="doc-table mb-3">
			<thead>
				<tr>
					<th colSpan={4}>Props</th>
				</tr>
				<tr>
					<td>
						<strong>Name</strong>
					</td>
					<td>
						<strong>Options</strong>
					</td>
					<td>
						<strong>Default</strong>
					</td>
					<td>
						<strong>Description</strong>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>src</td>
					<td>string</td>
					<td className='text-center'>-</td>
					<td>Set Avatar img src.</td>
				</tr>
                <tr>
					<td>alt</td>
					<td>string</td>
					<td className='text-center'>-</td>
					<td>Set Avatar img alt.</td>
				</tr>
				<tr>
					<td>defaultIcon</td>
					<td>IconDefinition</td>
					<td className='text-center'>IconsSolid.faUser</td>
					<td>Set default icon when no src or load issue.</td>
				</tr>
                <tr>
					<td>all native div attibutes</td>
					<td>IconDefinition</td>
					<td className='text-center'>IconsSolid.faUser</td>
					<td>You can use all native div attributes like: class, id ...</td>
				</tr>
			</tbody>
		</table>
	</div>;
}

export default AvatarDocs;