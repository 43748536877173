import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const MainContainerDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Main Container</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-1">There is a specific UI structure and the design system provides the necessary building blocks for it. It is crucial to implement strictly the showcased structure of layout components.</p>
            <p className="mb-4">MainContainer use <Link to="/docs/header">Header</Link>, <Link to="/docs/footer">Footer</Link> and <Link to="/docs/navSteps">NavSteps</Link> or <Link to="/docs/navMenu">NavMenu</Link> components.</p>

            <h5 className="mb-2"><Link to="/docs/demo-pages">Demo Pages with MainContainer</Link></h5>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { MainContainer, Header, Footer, NavSteps, } from '@jkhy/vsg-design-system';

class Example extends Component {
    render() {

        const header = <Header
            links={[
                { text: 'FAQs', path: '#', icon: IconsSolid.faComments },
                { text: 'Sign Out', path: '#', icon: IconsSolid.faSignOutAlt }
            ]} />;

        const navSteps = <NavSteps steps={...} />;

        const footer = <Footer 
            imgLeft={src:'...path/img/footer/footer-left.svg', alt:'BBB logo'}
            imgCenter={src:'...path/img/footer/footer-center.svg', alt:'EHO logo'}
            imgRight={src:'...path/img/footer/footer-right.svg', alt:'FEDIC logo'}
            topText='All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review.'
            bottomText='© Copyright 2020. Powered by Jack Henry'
            version="Version 1.22.33.44"
        />

        return (
            <MainContainer
                header={header}
                leftSideElement={navSteps}
                footer={footer}
            >
                Content...
            </MainContainer>
        );
    }
}

export default Example; 
    `
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>header</td>
                        <td><Link to="/docs/header">JSX.Element</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the <Link to="/docs/header">Header</Link>.</td>
                    </tr>
                    <tr>
                        <td>leftSideElement</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set the leftSideElement. Use <Link to="/docs/navSteps">NavSteps</Link> or <Link to="/docs/navMenu">NavMenu</Link>.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" version="4.3"/>bannerElement</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3"/>bannerProps</td>
                        <td><Link to="/docs/banner">all Banner props</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the <Link to="/docs/banner">Banner</Link>.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.3"/>isClientPortal</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set the prop when used for client portal</td>
                    </tr>
                    <tr>
                        <td>footer</td>
                        <td><Link to="/docs/footer">JSX.Element</Link></td>
                        <td className="text-center">-</td>
                        <td>Set the <Link to="/docs/footer">Footer</Link>.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default MainContainerDocs;