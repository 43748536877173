import React from 'react';
import Highlight from 'react-highlight';
import { NavMenu, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';


const NavMenuDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> NavMenu</h1>

            <div className='mb-2'>
                <NavMenu
                    onLinkClick={() => { console.log('Link is clicked') }}
                    sections={[
                        {
                            links: [
                                { title: 'HOME', path: '#', isActive: true }
                            ]
                        },
                        {
                            title: 'Requested Documents',
                            links: [
                                { title: 'REQUESTED', path: '#', number: 6, isActive: false },
                                { title: 'HISTORY', path: '#' },
                            ]
                        },
                        {
                            title: 'Loan Applications',
                            links: [
                                { title: 'NEW REQUEST', path: '#' },
                                { title: 'RENEWALS', path: '#', number: 14 },
                                { title: 'HISTORY', path: '#', number: 0 },
                            ]
                        }
                    ]} />
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { NavMenu } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <NavMenu
            onLinkClick={() => { console.log('Link is clicked') }}
            sections={[
                {
                    links: [
                        { title: 'HOME', path: '#', isActive: true }
                    ]
                },
                {
                    title: 'Requested Documents',
                    links: [
                        { title: 'REQUESTED', path: '#', number: 6, isActive: false },
                        { title: 'HISTORY', path: '#' },
                    ]
                },
                {
                    title: 'Loan Applications',
                    links: [
                        { title: 'NEW REQUEST', path: '#' },
                        { title: 'RENEWALS', path: '#', number: 14 },
                        { title: 'HISTORY', path: '#' },
                    ]
                }
            ]} 
        />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="obsolete" />user</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" />changePassword</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" />signOut</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td>onLinkClick</td>
                        <td>Function</td>
                        <td className="text-center">-</td>
                        <td>Callback, invoked when any router link in NavMenu is clicked.</td>
                    </tr>
                    <tr>
                        <td>sections</td>
                        <td>array of objects with keys: "title", "links"
                            <br /> <strong>links</strong> is array of objects with keys: "title", "path", "number", "isActive", "dataUI"
                        </td>
                        <td className="text-center">-</td>
                        <td>Set sections that contain links.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default NavMenuDocs;