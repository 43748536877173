import React, { useState } from 'react';
import Highlight from 'react-highlight';

import { FormGroup, Input } from '@jkhy/vsg-design-system';
import {
    contrastChecker, getShadowGlowValue,
    calculateLayoutRoundness, calculateBrightenColors
} from '@jkhy/vsg-design-system/style-utilities';

const FuncUtils = () => {

    const [colorInput, setColorInput] = useState('#0c2174');
    const [stepInput, setStepInput] = useState(10);

    const isContrast = contrastChecker('#FFFFFF', '#0c2174');
    console.log('CONTRAST:', isContrast);

    const shadowGlowValuePrimary = getShadowGlowValue('#0c2174', 'primary');
    const shadowGlowValueSuccess = getShadowGlowValue('#308140', 'success');
    const shadowGlowValueError = getShadowGlowValue('#cf3420', 'error');
    console.log('ShadowGlow Primary:', shadowGlowValuePrimary);
    console.log('ShadowGlow Success:', shadowGlowValueSuccess);
    console.log('ShadowGlow Error:', shadowGlowValueError);

    const layoutRoundness = calculateLayoutRoundness(6);
    const layoutRoundness2 = calculateLayoutRoundness(20);
    console.log('LayoutRoundness:', layoutRoundness);
    console.log('LayoutRoundness:', layoutRoundness2);

    return <div>
        <h1 className="mb-3">Function helpers</h1>

        <p className="mb-1">
            <strong>contrastChecker</strong>: Function to check if the contrast between two Hex colors is enough.
        </p>
        <p className="mb-1">
            <strong>getShadowGlowValue</strong>: Function that calculates shadowGlowValue by given 'hex' and 'type' and returns string value.
        </p>
        <p className="mb-1">
            <strong>calculateLayoutRoundness</strong>: Function that checks if the given value is between the min and max. If it is under the min, returns min. If it is beyond max, returns max.
        </p>
        <p className="mb-4">
            <strong>calculateBrightenColors</strong>: Function that returns an array of brightened colors for a given color.
        </p>

        <div className="d-flex">
            <FormGroup label="Color" htmlFor="color" isRequired className="mb-2">
                <Input type="color" name="color" id="color" value={colorInput} onChange={(e) => setColorInput(e.target.value)} />
            </FormGroup>
            <FormGroup label="Step" htmlFor="step" isRequired className="mb-2 ml-2">
                <Input type="number" name="step" id="step" value={stepInput} onChange={(e) => setStepInput(+e.target.value)} />
            </FormGroup>
        </div>
        <div className="d-flex" style={{ border: '1px solid var(--color-text' }}>
            {calculateBrightenColors(colorInput, stepInput).map((color: any, index: any) => {
                return (
                    <div
                        key={index}
                        className="d-flex justify-center align-items-center"
                        style={{ backgroundColor: color, height: '75px', width: '75px' }}>
                        {color}
                    </div>
                );
            })}
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import {
    contrastChecker, getShadowGlowValue,
    calculateLayoutRoundness, calculateBrightenColors
} from '@jkhy/vsg-design-system/style-utilities';

const isContrast = contrastChecker('#FFFFFF', '#0c2174');

const shadowGlowValuePrimary = getShadowGlowValue('#0c2174', 'primary');
const shadowGlowValueSuccess = getShadowGlowValue('#308140', 'success');
const shadowGlowValueError = getShadowGlowValue('#cf3420', 'error');

const layoutRoundness = calculateLayoutRoundness(6);
const layoutRoundness2 = calculateLayoutRoundness(20);

const brightenColors = calculateBrightenColors('#0c2174', 10, 6);

`
            }
        </Highlight>


        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Params</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>contrastChecker</td>
                    <td>
                        color1, color2, divider
                    </td>
                    <td>divider = 4.5</td>
                    <td>Checks contrast between two colors and returns "true" if contrast is enough or "false" if it is not.</td>
                </tr>
                <tr>
                    <td>getShadowGlowValue</td>
                    <td>
                        hex, type: 'primary' | 'success' | 'error'
                    </td>
                    <td className="text-center">-</td>
                    <td>Calculates and returns string value.</td>
                </tr>
                <tr>
                    <td>calculateLayoutRoundness</td>
                    <td>
                        roundness
                    </td>
                    <td className="text-center">-</td>
                    <td>Function that checks if the given value is between the min and max. If it is under the min, returns min. If it is beyond max, returns max.</td>
                </tr>
                <tr>
                    <td>calculateBrightenColors</td>
                    <td>
                        color, step, results
                    </td>
                    <td>step = 10 <br /> results = 6</td>
                    <td>Function that returns an array of brightened colors for a given color. 'step': indicates the difference between colors. 'results': defines how much results will be returned.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default FuncUtils;