import React from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section,
} from '@jkhy/vsg-design-system';

const CoApplicantsStepOne = () => {
    return (
        <ReactAux>
            <Section
                title="Co-Applicants"
                headerText="Header text: Would you like to a add a party to the application?"
                footerText="Footer text: Please continue to the next step."
            >
                <Button iconRight={IconsSolid.faPlus} link={{ path: '/views/pages/10-2' }}>Add</Button>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/10-2' }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default CoApplicantsStepOne;