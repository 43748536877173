import React from 'react';
import Highlight from 'react-highlight';
import { ZipCode } from '@jkhy/vsg-design-system';

const ZipcodeDocs = () => {
    return (
        <div>
            <h1 className='mb-3'>Zipcode</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Zip Code component provides a MaskedInput, which can switch the mask pattern to match 5 or 9-digit Zip code type. <a href="https://text-mask.github.io/text-mask/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a target="_blank" rel="noopener noreferrer" href="https://github.com/text-mask/text-mask/tree/master/react#readme">here</a>.</p>

            <div className='mb-2'>
                <ZipCode
                    name="zipCodeFirst"
                    isRequired={true}
                    className="mb-2"
                    id="zipCodeFirs"
                />
                <ZipCode
                    name="zipCodeSecond"
                    className="mb-2"
                    id="zipCodeSecond"
                />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { ZipCode } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <ZipCode 
            name="zipCodeFirst"
            isRequired={true}
        />
        <ZipCode 
            name="zipCodeSecond"
            /* 
                If value property is provided to the component,
                a callback function can be executed when the
                zip code format is changed to five digits.
                The returned value is the nine-digit value, trimmed to
                the fifth number.
                
                onSelectFiveDigits={(val)=>console.log(val)}
            */
        />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

        </div>
    )
}

export default ZipcodeDocs;