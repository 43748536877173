import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon, IconsSolid, NavSteps } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';


const NavStepsDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> NavSteps</h1>
            <h5 className="mb-2"><Link to="/views/pages/11">Demo Pages with NavSteps</Link></h5>
            <div className='mb-2'>
                <NavSteps className="mb-3" steps={[
                    { text: 'Application Info', path: '#', isActive: true },
                    { text: 'Purpose Product', path: '#' },
                    { text: 'Borrower', path: '#' },
                    { text: 'Guarantor', path: '#' },
                    { text: 'Reference', path: '#' },
                    { text: 'Status ', path: '#' }
                ]} />

                <NavSteps
                    steps={[
                        { text: 'Application Info', path: '#', isActive: true },
                        { text: 'Purpose Product', path: '#' },
                        { text: 'Borrower', path: '#' },
                        { text: 'Guarantor', path: '#' },
                        { text: 'Reference', path: '#' },
                        { text: 'Status ', path: '#' }
                    ]}
                    hideStepsNumber
                    className="mb-3"
                />

                <NavSteps
                    steps={[
                        {
                            text: 'Application Info',
                            path: '#',
                            subSteps: [
                                { text: 'Sub Application Info', path: '#', isActive: true },
                                { text: 'Sub Sec Application Info', path: '#' },
                                { text: 'Sub Last Application Info', path: '#' }
                            ]

                        },
                        { text: 'Purpose Product', path: '#' },
                        { text: 'Borrower', path: '#' },
                        { text: 'Guarantor', path: '#' },
                        { text: 'Reference', path: '#' },
                        { text: 'Status ', path: '#' }
                    ]}
                    hideStepsNumber
                />

            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { NavSteps } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <NavSteps className="mb-3" steps={[
            { text: 'Application Info', path: '#', isActive: true },
            { text: 'Purpose Product', path: '#' },
            { text: 'Borrower', path: '#' },
            { text: 'Guarantor', path: '#' },
            { text: 'Reference', path: '#' },
            { text: 'Status ', path: '#' }
        ]} />

        <NavSteps
            steps={[
                { text: 'Application Info', path: '#', isActive: true },
                { text: 'Purpose Product', path: '#' },
                { text: 'Borrower', path: '#' },
                { text: 'Guarantor', path: '#' },
                { text: 'Reference', path: '#' },
                { text: 'Status ', path: '#' }
            ]}
            hideStepsNumber
            className="mb-3"
        />

        <NavSteps
            steps={[
                {
                    text: 'Application Info',
                    path: '#',
                    subSteps: [
                        { text: 'Sub Application Info', path: '#', isActive: true },
                        { text: 'Sub Sec Application Info', path: '#' },
                        { text: 'Sub Last Application Info', path: '#' }
                    ]

                },
                { text: 'Purpose Product', path: '#' },
                { text: 'Borrower', path: '#' },
                { text: 'Guarantor', path: '#' },
                { text: 'Reference', path: '#' },
                { text: 'Status ', path: '#' }
            ]}
            hideStepsNumber
        />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>steps</td>
                        <td>array of objects with keys: <br/>
                             "text" : string,<br/> 
                             "path" : string,<br/>
                             "isActive": boolean,<br/>
                             "dataUI": string,<br/>
                             <PropVersionTag type="new" />subSteps:array of objects with keys:<br/>
                             "text" : string,<br/> 
                             "path" : string,<br/>
                             "isActive": boolean,<br/>
                             "dataUI": string,<br/>
                        </td>
                        <td className="text-center">-</td>
                        <td>Set steps ( links )</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />hideStepsNumber</td>
                        <td>boolean</td>
                        <td >false</td>
                        <td>Remove steps numbers</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" />onLastStep</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default NavStepsDocs;