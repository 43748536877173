import React from 'react';

import {
    Button, Section, ActionsWrapper, IconsSolid,
    ReactAux, ScrollableContainer, Checkbox
} from '@jkhy/vsg-design-system';

const PageRenTerms = (props:any) => {
    const {onSuccess} = props;
    return (
        <ReactAux>
            <Section
                title="Terms & Conditions"
                footerText="Click ‘Continue’ to proceed."
            >
                <p className="mb-3 text-muted">Please read and accept the terms and conditions below:</p>

                <ScrollableContainer className="mb-1">
                    <p className='mb-2'>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI.
                        The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                    </p>

                    <p className='mb-2'>
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI.
                        The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                </ScrollableContainer>
                <Checkbox htmlFor="accept" id="accept" name="accept">
                    I have read and agree to the disclosures above.
                </Checkbox>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: `/renewals/success/1` }} onClick={onSuccess}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageRenTerms;