import React from 'react';
import Highlight from 'react-highlight';
import { Input, Row, Col, FormGroup } from '@jkhy/vsg-design-system';

const GridDocs = () => {
    return (
        <div>
            <h1 className='mb-3'>Grid</h1>

            <h2 className='mb-2'>Description</h2>
            <p className="mb-s">If there are cases where the the content of the given view should be placed in rows and columns,
        there are components in LoanVantage Design System that handle such scenarios - <strong>Row</strong> and <strong>Col</strong>.</p>
            <p className="mb-l">Rows and columns are using <a href="https://reactstrap.github.io/components/layout/"
                target="_blank" rel="noopener noreferrer">Reactstrap grid</a>.</p>

            <Row>
                <Col xs="6" style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-1)' }}>First column</Col>
                <Col xs="3" style={{ backgroundColor: 'var(--color-select)', color: 'var(--color-text)' }}>Second column</Col>
                <Col xs="3" style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-1)' }}>Third column</Col>
            </Row>

            <h4 className='mb-1 mt-2'>Hide fields</h4>
            <p className='mb-3'>You can use 'isHidden' from 'Col' component to hide a field.</p>
            <Row>
                <Col lg="3" isHidden={true}>
                    <FormGroup htmlFor="input1" label="Input 1" className="mb-2">
                        <Input id="input1" />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup htmlFor="input2" label="Input 2" className="mb-2">
                        <Input id="input2" />
                    </FormGroup>
                </Col>
                <Col lg="3" isHidden={true}>
                    <FormGroup htmlFor="input3" label="Input 3" className="mb-2">
                        <Input id="input3" />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup htmlFor="input4" label="Input 4" className="mb-2">
                        <Input id="input4" />
                    </FormGroup>
                </Col>
            </Row>

            <Highlight className="React mb-l">
                {
                    `
import React from 'react';
import { Row, Col } from '@jkhy/vsg-loanvantage-design-system';

class Grid extends React.Component{
    render(){
        return (
            // Column color styles are only for demo purpose
            <Row>
                <Col xs="6">First column</Col>
                <Col xs="3">Second column</Col>
                <Col xs="3">Third column</Col>
            </Row>


         <Row>
                <Col lg="3" isHidden={true}>
                    <FormGroup htmlFor="input1" label="Input 1" className="mb-2">
                        <Input id="input1" />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup htmlFor="input2" label="Input 2" className="mb-2">
                        <Input id="input2" />
                    </FormGroup>
                </Col>
                <Col lg="3" isHidden={true}>
                    <FormGroup htmlFor="input3" label="Input 3" className="mb-2">
                        <Input id="input3" />
                    </FormGroup>
                </Col>
                <Col lg="3">
                    <FormGroup htmlFor="input4" label="Input 4" className="mb-2">
                        <Input id="input4" />
                    </FormGroup>
                </Col>
          </Row>
        );
    }
}
export default Grid;
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isHidden</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Determines whether the content of the Col is hidden.</td>
                    </tr>
                    <tr>
                        <td>all props from reactstrap doc</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td><a href="https://reactstrap.github.io/components/layout/"
                target="_blank" rel="noopener noreferrer">Reactstrap grid</a></td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default GridDocs;