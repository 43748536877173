import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid, PricingCardsWrapper, PricingCard, ReactAux } from '@jkhy/vsg-design-system';

const allPricingCards = [
    {
        title: '1 year line of credit',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: 'Payment Amount Varies', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '4 %', description: ['Variable Rate: 4%; 10% (Ceiling); 3% Floor'] },
            { title: 'APR', amount: '4.75 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
    {
        title: '3 Year term',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: '$2,209', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '5 %', description: ['Fixed Rate'] },
            { title: 'APR', amount: '5.25 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
    {
        title: '5 Year term',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: '$1,409', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '5.5 %', description: ['Fixed Rate'] },
            { title: 'APR', amount: '5.95 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
];

const PricingCardDocs = () => {
    const [selectedID, setSelectedID] = useState(1);

    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Pricing Card</h1>

            <h2 className="mb-2">Description</h2>
            <p className="mb-4">The QD Design System's custom Pricing Card components should be used to display pricing options, usually at the
                end of the user's application.</p>

            <h3 className="mb-1">Pricing Card module components:</h3>
            <p className="mb-05"><strong>PricingCardsWrapper</strong> - The main wrapper component</p>
            <p className="mb-4"><strong>PricingCard</strong> - The card with data</p>

            <h3 className='mb-3'>Default</h3>

            <div style={{ maxWidth: '400px' }}>
                <PricingCardsWrapper className='mb-4'>
                    {
                        allPricingCards.map((card, i) => {
                            const index = i;

                            return (
                                <ReactAux key={index}>
                                    <PricingCard
                                        className="mb-2"
                                        selected={selectedID === index}
                                        title={card.title}
                                        amount={card.amount}
                                        conditionsData={card.conditionsData}
                                        feesData={card.feesData}
                                        onSelect={() =>{
                                            setSelectedID(index)
                                        }}
                                    />
                                </ReactAux>
                            )
                        })
                    }
                </PricingCardsWrapper>

                <h3 className='mb-3'>View mode</h3>

                <PricingCardsWrapper>
                    {
                        allPricingCards.slice(0,1).map((card, i) => {
                            const index = i;

                            return (
                                <ReactAux key={index}>
                                    <PricingCard
                                        className="mb-2"
                                        selected={true}
                                        title={card.title}
                                        amount={card.amount}
                                        conditionsData={card.conditionsData}
                                        feesData={card.feesData}
                                        onSelect={() =>{
                                            setSelectedID(index)
                                        }}
                                        viewMode
                                    />
                                </ReactAux>
                            )
                        })
                    }
                </PricingCardsWrapper>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { PricingCardsWrapper, PricingCard, ReactAux } from '@jkhy/vsg-design-system';

const Example = (props) => {
    // Demo data
    const allPricingCards = [
        {
            title: '1 year line of credit',
            amount: '$ 73,950',
            conditionsData: [
                { title: 'Monthly Payment', amount: 'Payment Amount Varies', description: ['Principal & Interest'] },
                { title: 'Interest Rate', amount: '4 %', description: ['Variable Rate: 4%; 10% (Ceiling); 3% Floor'] },
                { title: 'APR', amount: '4.75 %' },
            ],
            feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
        },
        {
            title: '3 Year term',
            amount: '$ 73,950',
            conditionsData: [
                { title: 'Monthly Payment', amount: '$2,209', description: ['Principal & Interest'] },
                { title: 'Interest Rate', amount: '5 %', description: ['Fixed Rate'] },
                { title: 'APR', amount: '5.25 %' },
            ],
            feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
        },
        {
            title: '5 Year term',
            amount: '$ 73,950',
            conditionsData: [
                { title: 'Monthly Payment', amount: '$1,409', description: ['Principal & Interest'] },
                { title: 'Interest Rate', amount: '5.5 %', description: ['Fixed Rate'] },
                { title: 'APR', amount: '5.95 %' },
            ],
            feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
        },
    ];
    
    return (
    <div>
    {
        allPricingCards.map((card, i) => {
            const index = i;

            return (
                <div style={{ maxWidth: '400px' }}>
                    <PricingCardsWrapper>
                        {
                            allPricingCards.map((card, i) => {
                                const index = i;

                                return (
                                    <ReactAux key={index}>
                                        <PricingCard
                                            className="mb-2"
                                            selected={selectedID === index}
                                            title={card.title}
                                            amount={card.amount}
                                            conditionsData={card.conditionsData}
                                            feesData={card.feesData}
                                            onSelect={() => setSelectedID(index)}
                                            // Set for view mode state
                                            viewMode
                                        />
                                    </ReactAux>
                                )
                            })
                        }
                    </PricingCardsWrapper>
                </div>
            )
        })
    }
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>selected</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set selected style to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>amount</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set amount to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>conditionsData</td>
                        <td>array of objects with keys:
                            "title": string, <br />
                            "amount": string, <br />
                            "description": Array of strings || JSX.Element
                        </td>
                        <td className="text-center">-</td>
                        <td>Set conditions to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>feesData</td>
                        <td>array of objects with keys: "title", "amount"</td>
                        <td className="text-center">-</td>
                        <td>Set fees to the pricing card.</td>
                    </tr>
                    <tr>
                        <td>viewMode</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set card view mode.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default PricingCardDocs;