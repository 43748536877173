import React from 'react';

import { lightTheme, darkTheme } from './Themes';
import { FontAwesomeIcon, IconsSolid, Checkbox, FormGroup, Select, Radio } from '@jkhy/vsg-design-system';
import { withRouter } from 'react-router-dom';
import './docsThemeChanger.scss';

const fonts: any = {
    nunito: '\'Nunito\', sans-serif',
    IBMPlexSerif: '\'IBM Plex Serif\', serif',
    merriweather: '\'Merriweather\', serif',
    montserrat: '\'Montserrat\', sans-serif',
    roboto: '\'Roboto\', sans-serif',
    barlow: '\'Barlow\', sans-serif',
    cabin: '\'Cabin\', sans-serif',
}

class DocsThemeChanger extends React.Component<any> {

    state = {
        theme: 'light',
        font: 'nunito',
        logoUrl: 'logo-demo.svg',
        logoSize: 's'
    }

    componentDidMount() {
        const documentStyle = document.documentElement.style;

        documentStyle.setProperty('--header-logo', 'url(/logo-demo.svg)');
        documentStyle.setProperty('--background-image', 'url(/background-demo.jpg)');
    }

    setDocumentsStyles = (settings: any) => {
        const documentStyle = document.documentElement.style;

        for (const key in settings) {
            documentStyle.setProperty(key, settings[key]);
        }
    };

    switchTheme = () => {
        const { theme } = this.state;

        if (theme === 'light') {
            this.setDocumentsStyles(darkTheme);
            this.setState({ theme: 'dark' });
        } else {
            this.setDocumentsStyles(lightTheme);
            this.setState({ theme: 'light' });
        }
    }

    switchFont = (font: any) => {
        const documentStyle = document.documentElement.style;
        documentStyle.setProperty('--font-family', fonts[font]);
        this.setState({
            font: font
        })
    }

    switchBg = (e: any) => {
        const documentStyle = document.documentElement.style;

        if (e.target.checked) documentStyle.setProperty('--background-image', 'url(/background-demo.jpg)');
        else documentStyle.setProperty('--background-image', '');
    }

    onLogoChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.switchLogo(e.target.value)
    }

    switchLogo(logoUrl: string) {
        const documentStyle = document.documentElement.style;
        documentStyle.setProperty('--header-logo', `url(/${logoUrl})`);
        this.setState({ logoUrl });
    }

    onLogoSizeChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.switchLogoSize(e.target.value)
    }

    switchLogoSize(size: string) {
        const documentStyle = document.documentElement.style;
        let height = '';
        let margin = '';

        if (size === 'm') {
            height = '6.5rem';
            margin = '-4px 0 -9px 0';
        } else if (size === 'l') {
            height = '7.5rem';
            margin = '-5px 0';
        }

        documentStyle.setProperty('--header-logo-height', height);
        documentStyle.setProperty('--header-logo-margin', margin);
        this.setState({ logoSize: size });
    }

    render() {
        const { theme, font } = this.state;
        const { location } = this.props;
        const isDocsPage = location.pathname.includes('docs') ? true : false;
        return (
            <div className={`doc-theme-changer-fix-section${isDocsPage ? ' doc-page' : ''}`}>
                <FormGroup label="Select Font" className="mb-1" htmlFor="fontSelect" isRequired={true} isValid={true}>
                    <Select
                        id="fontSelect"
                        name="fontSelect"
                        value={font}
                        onChange={(obj: any) => this.switchFont(obj.value)}
                        options={[
                            { value: 'nunito', label: 'Nunito' },
                            { value: 'IBMPlexSerif', label: 'IBM Plex Serif' },
                            { value: 'merriweather', label: 'Merriweather' },
                            { value: 'montserrat', label: 'Montserrat' },
                            { value: 'roboto', label: 'Roboto' },
                            { value: 'barlow', label: 'Barlow' },
                            { value: 'cabin', label: 'Cabin' },
                        ]}
                        {...!isDocsPage && { menuPlacement: 'top' }}
                    />
                </FormGroup>
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className={`toggle-theme-wrapper ${theme === 'light' ? 'light' : 'dark'}`}>
                        <input onChange={this.switchTheme} type="checkbox" className="toggle-theme" id="toggleTheme" />
                        <label htmlFor="toggleTheme" className="changer-label">
                            <FontAwesomeIcon size="s" icon={IconsSolid.faMoon} />
                            <FontAwesomeIcon size="s" icon={IconsSolid.faSun} />
                            <div className="ball"></div>
                        </label>
                    </div>
                    <span className="d-flex ml-05">
                        <Checkbox htmlFor="bgImg" id="bgImg" onChange={this.switchBg} defaultChecked>Bg img</Checkbox>
                    </span>
                </div>
                <div>
                    <h4>Logo:</h4>
                </div>
                <div className="mb-1">
                    <Radio className="d-inline-block mr-05" htmlFor="logo-1" id="logo-1" name="logo"
                        onChange={(e) => this.onLogoChange(e)} value="logo-demo.svg"
                        checked={this.state.logoUrl === 'logo-demo.svg'}>1</Radio>
                    <Radio className="d-inline-block mr-05" htmlFor="logo-2" id="logo-2" name="logo"
                        onChange={(e) => this.onLogoChange(e)} value="logo-demo2.png"
                        checked={this.state.logoUrl === 'logo-demo2.png'}>2</Radio>
                </div>
                <div>
                    <h4>Header logo size:</h4>
                </div>
                <div>
                    <Radio className="d-inline-block mr-05" htmlFor="s" id="s" name="logo-size"
                        onChange={(e) => this.onLogoSizeChange(e)} value="s"
                        checked={this.state.logoSize === 's'}>S</Radio>
                    <Radio className="d-inline-block mr-05" htmlFor="m" id="m" name="logo-size"
                        onChange={(e) => this.onLogoSizeChange(e)} value="m"
                        checked={this.state.logoSize === 'm'}>M</Radio>
                    <Radio className="d-inline-block" htmlFor="l" id="l" name="logo-size"
                        onChange={(e) => this.onLogoSizeChange(e)} value="l"
                        checked={this.state.logoSize === 'l'}>L</Radio>
                </div>
            </div>
        );
    }
}

export default withRouter(DocsThemeChanger);
