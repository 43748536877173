import React from 'react';
import Highlight from 'react-highlight';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { Button, Modal, FontAwesomeIcon, IconsSolid, } from '@jkhy/vsg-design-system';

class ModalDocs extends React.Component<any, { showFirstModal: boolean, showSecondModal: boolean, showThirdModal: boolean, }> {
    constructor(props: any) {
        super(props);
        this.state = {
            showFirstModal: false,
            showSecondModal: false,
            showThirdModal: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    onModalSubmit() {
        console.log('Submit from modal');
    }

    toggleModal(propName: string, value: boolean) {
        if (propName === 'showFirstModal') {
            this.setState({
                showFirstModal: value
            });
        }
        if (propName === 'showSecondModal') {
            this.setState({
                showSecondModal: value
            });
        }
        if (propName === 'showThirdModal') {
            this.setState({
                showThirdModal: value
            });
        }
    }

    render() {
        return (
            <div>
                <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Modal</h1>

                <h2 className='mb-2'>Description</h2>
                <p className='mb-4'>The Modal component is a dialog box/popup window that is displayed on top of the current page.</p>

                <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                    <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showFirstModal', !this.state.showFirstModal) }} >Modal</Button>
                    <Modal
                        title="Modal example 1"
                        onClose={() => { this.toggleModal('showFirstModal', !this.state.showFirstModal) }}
                        isVisible={this.state.showFirstModal}
                    >
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                        containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                              including versions of Lorem Ipsum</p>
                    </Modal>

                    <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showSecondModal', !this.state.showSecondModal) }} >Modal 2</Button>
                    <Modal
                        isVisible={this.state.showSecondModal}
                        onClose={() => { this.toggleModal('showSecondModal', !this.state.showSecondModal) }}
                        parentClass='parent-modal-2'
                        title="Modal example 2"
                        onSubmit={this.onModalSubmit}
                        isClosable
                        closeBtnName='Close'
                        closeBtnIcon={IconsSolid.faTimes}
                        actionBtnName='Agree'
                        actionBtnIcon={IconsSolid.faCheck}
                    >
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                               and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                    </Modal>

                    <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showThirdModal', !this.state.showThirdModal) }} >Modal 3</Button>
                    <Modal
                        isVisible={this.state.showThirdModal}
                        onClose={() => { this.toggleModal('showThirdModal', !this.state.showThirdModal) }}
                        title="Modal example size 'S'"
                        onSubmit={this.onModalSubmit}
                        isClosable
                        closeBtnName='Close'
                        closeBtnIcon={IconsSolid.faTimes}
                        actionBtnName='Agree'
                        actionBtnIcon={IconsSolid.faCheck}
                        size="S"
                    >
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                               and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                    </Modal>
                </div>

                <Highlight className="JS mb-4">
                    {
                        `
import React from 'react';
import { Row, Col, Modal, Button } from '@jkhy/vsg-design-system';
    
class Example extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {
            showFirstModal: false,
            showSecondModal: false,
            showThirdModal: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    onModalSubmit() {
        console.log('Submit from modal');
    }

    toggleModal(propName: string, value: boolean) {
        if (propName === 'showFirstModal') {
            this.setState({
                showFirstModal: value
            });
        }
        if (propName === 'showSecondModal') {
            this.setState({
                showSecondModal: value
            });
        }
        if (propName === 'showThirdModal') {
            this.setState({
                showThirdModal: value
            });
        }
    }

    render(){
        return(
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showFirstModal', !this.state.showFirstModal) }} >Modal</Button>
                <Modal 
                    title="Modal example 1"
                    onClose={() => { this.toggleModal('showFirstModal', !this.state.showFirstModal) }}
                    isVisible={this.state.showFirstModal}
                >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    It has survived not only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                            including versions of Lorem Ipsum</p>
                </Modal>

                <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showSecondModal', !this.state.showSecondModal) }} >Modal 2</Button>
                <Modal 
                    isVisible={this.state.showSecondModal}
                    onClose={() => { this.toggleModal('showSecondModal', !this.state.showSecondModal) }}
                    parentClass='parent-modal-2'
                    title="Modal example 2"
                    onSubmit={this.onModalSubmit}
                    isClosable
                    closeBtnName='Close'
                    closeBtnIcon={IconsSolid.faTimes}
                    actionBtnName='Agree'
                    actionBtnIcon={IconsSolid.faCheck}
                >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                </Modal>

                <Button className="d-inline-block mb-1 mr-1" onClick={() => { this.toggleModal('showThirdModal', !this.state.showThirdModal) }} >Modal 3</Button>
                <Modal
                    isVisible={this.state.showThirdModal}
                    onClose={() => { this.toggleModal('showThirdModal', !this.state.showThirdModal) }}
                    title="Modal example size 'S'"
                    onSubmit={this.onModalSubmit}
                    isClosable
                    closeBtnName='Close'
                    closeBtnIcon={IconsSolid.faTimes}
                    actionBtnName='Agree'
                    actionBtnIcon={IconsSolid.faCheck}
                    size="S"
                >
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                    It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                </Modal>
            </div>
        )
    }
}

export default Example; 
    `
                    }
                </Highlight>

                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>This prop is <strong>required</strong>. Set title to the modal.</td>
                        </tr>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td className='text-center'>-</td>
                            <td>Set modal visibility.</td>
                        </tr>
                        <tr>
                            <td>onClose</td>
                            <td>function</td>
                            <td className='text-center'>-</td>
                            <td>Callback to execute when the modal is closed.</td>
                        </tr>
                        <tr>
                            <td>onSubmit</td>
                            <td>function</td>
                            <td className='text-center'>-</td>
                            <td>Callback to execute when the modal CTA is submitted/confirmed.</td>
                        </tr>
                        <tr>
                            <td>parentClass</td>
                            <td>string</td>
                            <td>The modal will be appended to the document's body</td>
                            <td>Set class to modal wrapper and append the modal in it.</td>
                        </tr>
                        <tr>
                            <td>closeBtnName</td>
                            <td>string</td>
                            <td>CANCEL</td>
                            <td>Set name to the modal cancel button.</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" /> closeBtnIcon</td>
                            <td>IconDefinition</td>
                            <td className="text-center">-</td>
                            <td>Set icon to the modal cancel button.</td>
                        </tr>
                        <tr>
                            <td>actionBtnName</td>
                            <td>string</td>
                            <td>OK</td>
                            <td>Set name to the modal submit button.</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" /> actionBtnIcon</td>
                            <td>IconDefinition</td>
                            <td className="text-center">-</td>
                            <td>Set icon to the modal submit button.</td>
                        </tr>
                        <tr>
                            <td>isClosable</td>
                            <td>boolean</td>
                            <td>true</td>
                            <td>Showing the buttons for closing.</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" /> size</td>
                            <td>'S' | 'M'</td>
                            <td>'M'</td>
                            <td>Set modal size.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ModalDocs;