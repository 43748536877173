import React from 'react';
import Highlight from 'react-highlight';
import { IconsSolid, FontAwesomeIcon, ListBox } from '@jkhy/vsg-design-system';

const ListBoxDoc = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> ListBox</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">ListBox component helps you to list different information.</p>

            <ListBox
                boxItems={
                    [
                        {
                            title: {
                                text: 'Primary Borrower:',
                                isBold: true,
                            },
                            description: 'Sally Jones'
                        },
                        {
                            title: {
                                text: 'Date Started:',
                                isBold: true
                            },
                            description: '01/02/2021'
                        },
                        {
                            title: {
                                text: 'Amount Requested:',
                                isBold: true

                            },
                            description: '$35,000'
                        },
                        {
                            iconSettings: {
                                icon: IconsSolid.faInfoCircle,
                                className: 'text-primary'
                            },
                            description: 'You have been added to this application.'
                        }
                    ]
                } />

            <Highlight className="React mb-4">
                {`
import React from 'react';
import { IconsSolid, FontAwesomeIcon, ListBox } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
       
        <ListBox
        boxItems={
            [
                {
                    title: {
                        text: 'Primary Borrower:',
                        isBold: true,
                    },
                    description: 'Sally Jones'
                },
                {
                    title: {
                        text: 'Date Started:',
                        isBold: true
                    },
                    description: '01/02/2021'
                },
                {
                    title: {
                        text: 'Amount Requested:',
                        isBold: true

                    },
                    description: '$35,000'
                },
                {
                    iconSettings: {
                        icon: IconsSolid.faInfoCircle,
                        className: 'text-primary'
                    },
                    description: 'You have been added to this application.'
                }
            ]
        } />

    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>boxItems</td>
                        <td>
                            array of Objects with props: <br />
                            "title": Object with props: "text", "isBold" <br />
                            "iconSettings": Object with props: "icon", "className"<br />
                            "description": string || JSX.Element
                        </td>
                        <td className="text-center">-</td>
                        <td>Set listbox data.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ListBoxDoc;