import React from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, HorizontalInfoCard, IconsLight, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const HorizontalInfoCardDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Horizontal Info Card</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Information card which helps you list different information.</p>

            <div className='mb-2'>
                <HorizontalInfoCard
                    title="Title"
                    icon={IconsLight.faUser}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Title"
                    className="mb-2"
                    onDelete={(e) => console.log(e)}
                    description="test desc"
                />

                <HorizontalInfoCard
                    title="Title"
                    className="mb-2"
                    description="test desc"
                />

                <HorizontalInfoCard
                    title="Title"
                    icon={IconsLight.faUser}
                    className="mb-2"
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Really long title Really long title Really long title Really long title Really Really long title Really long title Really long title Really long title Really"
                    icon={IconsLight.faUser}
                    description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English"
                    className="mb-2"
                    onEdit={(e) => console.log(e)}
                    onDelete={(e) => console.log(e)}
                />

                <h2 className="mb-2">Variations</h2>
                <HorizontalInfoCard
                    title="Personal Address Card"
                    icon={IconsLight.faHome}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Business Address Card"
                    icon={IconsLight.faBuilding}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Reference: Attorney"
                    icon={IconsLight.faGavel}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />


                <HorizontalInfoCard
                    title="Reference: Accountant"
                    icon={IconsLight.faCalculator}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Reference: Insurance Agent"
                    icon={IconsLight.faHandsUsd}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Business Obligations"
                    icon={IconsLight.faSackDollar}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Business Deposits"
                    icon={IconsLight.faMoneyCheckEditAlt}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Add Individual Co-Applicant"
                    icon={IconsLight.faUser}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Add Business Co-Applicant"
                    icon={IconsLight.faIndustryAlt}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <HorizontalInfoCard
                    title="Collateral"
                    icon={IconsLight.faClipboardList}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                />

                <h2 className="mb-2">Invalid Card</h2>
                <HorizontalInfoCard
                    title="Title"
                    icon={IconsLight.faUser}
                    className="mb-2"
                    onEdit={(e) => { console.log(e) }}
                    onDelete={(e) => console.log(e)}
                    isValid={false}
                    validationMessage={'Missing information'}
                />

                <HorizontalInfoCard
                    title="Title"
                    className="mb-2"
                    onDelete={(e) => console.log(e)}
                    description="test desc"
                    isValid={false}
                    validationMessage={'Missing information'}
                />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { HorizontalInfoCard, IconsLight } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <HorizontalInfoCard
            title="Title"
            icon={IconsLight.faUser}
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Title"
            onDelete={(e) => console.log(e)}
            description="test desc"
        />

        <HorizontalInfoCard
            title="Title"
            description="test desc"
        />

        <HorizontalInfoCard
            title="Title"
            icon={IconsLight.faUser}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Really long title Really long title Really long title Really long title Really Really long title Really long title Really long title Really long title Really"
            icon={IconsLight.faUser}
            description="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English"
            onEdit={(e) => console.log(e)}
            onDelete={(e) => console.log(e)}
        />


        // Variations
        <HorizontalInfoCard
            title="Personal Address Card"
            icon={IconsLight.faHome}
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Business Address Card"
            icon={IconsLight.faBuilding}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Reference: Attorney"
            icon={IconsLight.faGavel}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />


        <HorizontalInfoCard
            title="Reference: Accountant"
            icon={IconsLight.faCalculator}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Reference: Insurance Agent"
            icon={IconsLight.faHandsUsd}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Business Obligations"
            icon={IconsLight.faSackDollar}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Business Deposits"
            icon={IconsLight.faMoneyCheckEditAlt}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Add Individual Co-Applicant"
            icon={IconsLight.faUser}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Add Business Co-Applicant"
            icon={IconsLight.faIndustryAlt}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        <HorizontalInfoCard
            title="Collateral"
            icon={IconsLight.faClipboardList}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
        />

        // Invalid Card
        <HorizontalInfoCard
            title="Title"
            icon={IconsLight.faUser}
            className="mb-2"
            onEdit={(e) => { console.log(e) }}
            onDelete={(e) => console.log(e)}
            isValid={false}
            validationMessage={'Missing information'}
         />

         <HorizontalInfoCard
            title="Title"
            className="mb-2"
            onDelete={(e) => console.log(e)}
            description="test desc"
            isValid={false}
            validationMessage={'Missing information'}
         />
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>iconDefinition</td>
                        <td className='text-center'>-</td>
                        <td>Set the icon before title.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set the title.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set description.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version={'4.2'}/>isValid</td>
                        <td>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Set card invalid or valid state and show validation message text.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version={'4.2'}/>validationMessage</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set validation message text.</td>
                    </tr>
                    <tr>
                        <td>onEdit</td>
                        <td className='text-center'>() {"=>"} void</td>
                        <td className='text-center'>-</td>
                        <td>Callback to execute when edit icon btn is clicked. You will receive as param the native click event.</td>
                    </tr>
                    <tr>
                        <td>onDelete</td>
                        <td className='text-center'>() {"=>"} void</td>
                        <td className='text-center'>-</td>
                        <td>Callback to execute when delete icon btn is clicked. You will receive as param the native click event</td>
                    </tr>
                    <tr>
                        <td>all div attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native div attributes like: "className", "id"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default HorizontalInfoCardDocs;