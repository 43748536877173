import React from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, Footer, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const FooterDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Footer</h1>
            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The layout ending component, according to the QD Design System principles.</p>

            <div className='mb-2'>
                <Footer
                    topText="All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review."
                    bottomText="© Copyright 2020. Powered by Jack Henry"
                    version="Version 1.22.33.44"
                />
            </div>

            <div className='mb-2'>
                <Footer
                    imgLeft={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-left.svg`, alt: 'BBB logo' }}
                    imgCenter={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-center.svg`, alt: 'EHO logo' }}
                    imgRight={{ src: `${process.env.PUBLIC_URL}/img/footer/footer-right.svg`, alt: 'FEDIC logo' }}
                    topText="All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review."
                    bottomText="© Copyright 2020. Powered by Jack Henry"
                />
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Footer } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Footer 
            topText="All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review."
            bottomText="© Copyright 2020. Powered by Jack Henry"
            version="Version 1.22.33.44"
         />

         <Footer 
            imgLeft={{ src: '...path/img/footer/footer-left.svg', alt: 'BBB logo' }}
            imgCenter={{ src: '...path/img/footer/footer-center.svg', alt: 'EHO logo' }}
            imgRight={{ src: '...path/img/footer/footer-right.svg', alt: 'FEDIC logo' }}
            topText="All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review."
            bottomText="© Copyright 2020. Powered by Jack Henry"
         />
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />topText</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set footer top text</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />bottomText</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set footer bottom text</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />version</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set footer version</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />imgCenter</td>
                        <td>object with props:<br />
                            'src': string <br />
                            'alt': string
                        </td>
                        <td>alt='Footer image center'</td>
                        <td>Set footer center logo.<br />
                            Note: Add <strong>alt</strong> prop due to WCAG 2.0</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />imgRight</td>
                        <td>object with props:<br />
                            'src': string <br />
                            'alt': string
                        </td>
                        <td>alt='Footer image right'</td>
                        <td>Set footer right logo<br />
                            Note: Add <strong>alt</strong> prop due to WCAG 2.0</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />imgLeft</td>
                        <td>object with props:<br />
                            'src': string <br />
                            'alt': string
                        </td>
                        <td >alt='Footer image left'</td>
                        <td>Set footer left logo<br />
                            Note: Add <strong>alt</strong> prop due to WCAG 2.0</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" version="4.2" />text</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set footer wrapper class</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FooterDocs;