import React from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio, Input,
    FormSection,
} from '@jkhy/vsg-design-system';

const PageDeclarations = () => {
    return (
        <ReactAux>
            <Section
                title="Declarations"
                headerText="Header text: Please answer the questions below."
                footerText="Footer text: Please continue to the next step."
            >
                <FormSection className="mb-3">
                    <h3 className="mb-2">Applicant Name</h3>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you agree to have your credit report pulled by X Bank?">
                        <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="tax" isRequired label="Personal Tax Returns have been filed through what year:">
                        <Input type="text" name="tax" id="tax" />
                    </FormGroup>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you consent to receive communication electronically via e-mail? If you do not, the online application cannot be processed. Please contact us at 888-888-8888 for assistance with obtaining a loan.">
                        <Radio className="d-inline-block" htmlFor="agreeYes2" id="agreeYes2" name="agree2" value="yes">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo2" id="agreeNo2" name="agree2" value="no">No</Radio>
                    </FormGroup>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Are you an effective officer of X Bank?">
                        <Radio className="d-inline-block" htmlFor="agreeYes3" id="agreeYes3" name="agree3" value="yes">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo3" id="agreeNo3" name="agree3" value="no">No</Radio>
                    </FormGroup>
                    <FormGroup isRequired checkboxOrRadio label="How did you hear about this online loan application?">
                        <Radio htmlFor="loanApp1" id="loanApp1" name="loanApp" value="yes">Customer Referral</Radio>
                        <Radio htmlFor="loanApp2" id="loanApp2" name="loanApp" value="no">Banker Referral</Radio>
                        <Radio htmlFor="loanApp3" id="loanApp3" name="loanApp" value="no">E-mail</Radio>
                    </FormGroup>
                </FormSection>
                <FormSection>
                    <h3 className="mb-2">Co-Applicant Name</h3>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you agree to have your credit report pulled by X Bank?">
                        <Radio className="d-inline-block" htmlFor="agreeYes4" id="agreeYes4" name="agree4" value="yes">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo4" id="agreeNo4" name="agree4" value="no">No</Radio>
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="tax2" isRequired label="Personal Tax Returns have been filed through what year:">
                        <Input type="text" name="tax2" id="tax2" />
                    </FormGroup>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you consent to receive communication electronically via e-mail? If you do not, the online application cannot be processed. Please contact us at 888-888-8888 for assistance with obtaining a loan.">
                        <Radio className="d-inline-block" htmlFor="agreeYes5" id="agreeYes5" name="agree5" value="yes">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="agreeNo5" id="agreeNo5" name="agree5" value="no">No</Radio>
                    </FormGroup>
                </FormSection>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/9' }}>Continue</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageDeclarations;