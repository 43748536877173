import React, { useState } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio,
    MaskedInput, Textarea, Input
} from '@jkhy/vsg-design-system';

const PageLoanNumber = (props: any) => {
    const { title, nextPage } = props;
    const [notSame, setNotSame] = useState(props.test);
    const [isTax, setIsTax] = useState(false);

    const onReasonChange = (e: any) => {
        const compareValue = 'same';
        if (e.target.value !== compareValue && !notSame) {
            setNotSame(true);
        } else if (notSame && e.target.value === compareValue) {
            setNotSame(false);
        }
    }

    return (
        <ReactAux>
            <Section title={title} footerText="Click ‘Continue’ to proceed." key={props.pageKey}>
                <p className="mb-3 text-muted">Please answer the following questions.</p>
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Renewal Choice">
                    <Radio htmlFor="renewChoiceSame" id="renewChoiceSame" name="renew_choice" value="same" onChange={onReasonChange} >Renew for same amount</Radio>
                    <Radio htmlFor="renewChoiceIncrease" id="renewChoiceIncrease" name="renew_choice" value="increase" onChange={onReasonChange} >Renew with Increase</Radio>
                    <Radio htmlFor="renewChoiceDecrese" id="renewChoiceDecrese" name="renew_choice" value="decrease" onChange={onReasonChange} >Renew with Decrease</Radio>
                </FormGroup>

                {
                    notSame && (
                        <>
                            <FormGroup isRequired label="Enter Amount to Increase" htmlFor="amountIncrease" className="mb-2">
                                <MaskedInput
                                    id="amountIncrease"
                                    name="amount"
                                    type='number'
                                    icon={IconsSolid.faDollarSign}
                                />
                            </FormGroup>
                            <FormGroup isRequired htmlFor="reaseon" label="Specific Reason" className="mb-2">
                                <Textarea rows={4} id="reason" name="reason" />
                            </FormGroup>
                        </>
                    )
                }

                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Are any tax returns being audited or contested?">
                    <Radio className="d-inline-block" htmlFor="isTaxYes" id="isTaxYes" name="tax" value="yes" onChange={() => setIsTax(true)}>Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="isTaxNo" id="isTaxNo" name="tax" value="no" onChange={() => setIsTax(false)}>No</Radio>
                </FormGroup>
                {isTax && (
                    <FormGroup label="If yes, please explain (optional" htmlFor="explain" className="mb-2">
                        <Input type="text" name="explain" id="explain" />
                    </FormGroup>
                )}
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: nextPage }}>Continue</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageLoanNumber;