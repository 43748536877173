import React from 'react';
import Highlight from 'react-highlight';

import { Collapse, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';


class CollapsePage extends React.Component {

    state = {
        collapseDemo1: false,
        collapseDemo2: true,
        collapseDemo3: false,
        collapseDemo4: false
    }

    render() {
        return (
            <div>
                <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Collapse</h1>

                <h2 className="mb-2">Description</h2>
                <p className="mb-4">The Collapse component is used to show and hide content.</p>

                <Collapse
                    title="Item 1"
                    expanded={this.state.collapseDemo1}
                    onClick={() => this.setState({ collapseDemo1: !this.state.collapseDemo1 })}
                    dataUI="collapse-1"
                    badgeContent={3}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Item 2"
                    expanded={this.state.collapseDemo2}
                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Purchase:"
                    badgeContent={3}
                    subTitle="Very long text here Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                    expanded={this.state.collapseDemo3}
                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                >
                    <p>Content...</p>
                </Collapse>

                <h2 className='mt-3 mb-2'>Simple</h2>

                <Collapse
                    title="Disclosures"
                    expanded={this.state.collapseDemo4}
                    onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                    dataUI="Disclosures"
                    simple
                    smallText
                >
                    <p>When you apply for credit, your lender may calculate your debt-to-income (DTI) based on verified income and debt amounts, and the result may differ from the results shown on the page.</p>
                </Collapse>

                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Collapse } from '@jkhy/vsg-design-system';

class Example extends React.Component {

    state = {
        collapseDemo1: false,
        collapseDemo2: true,
        collapseDemo3: false,
        collapseDemo4: false,
    }

    render() {
        return (
            <div>
                <Collapse
                    title="Item 1"
                    badgeContent={3}
                    expanded={this.state.collapseDemo1}
                    onClick={() => this.setState({ collapseDemo1: !this.state.collapseDemo1 })}
                    dataUI="collapse-1"
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Item 2"
                    expanded={this.state.collapseDemo2}
                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Purchase:"
                    badgeContent={3}
                    subTitle="Very long text here Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
                    expanded={this.state.collapseDemo3}
                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                >
                    <p>Content...</p>
                </Collapse>

                // Simple
                <Collapse
                    title="Disclosures"
                    expanded={this.state.collapseDemo4}
                    onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                    dataUI="Disclosures"
                    simple
                    smallText
                 >
                    <p>When you apply for credit, your lender may calculate your debt-to-income (DTI) based on verified income and debt amounts, and the result may differ from the results shown on the page.</p>
                </Collapse>
            </div>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the title.</td>
                        </tr>
                        <tr>
                            <td>subTitle</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the subTitle.</td>
                        </tr>
                        <tr>
                            <td>expanded</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows the expanded content.</td>
                        </tr>
                        <tr>
                            <td>onClick</td>
                            <td>void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the component is clicked.</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" version="4.3" />badgeContent</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Set badge value</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" version="4.4" />simple</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Set simple view</td>
                        </tr>
                        <tr>
                            <td><PropVersionTag type="new" version="4.4" />smallText</td>
                            <td>boolean</td>
                            <td className="text-center">-</td>
                            <td>Set title and collapse content small font size text</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}

export default CollapsePage;
