import React from 'react';
import Highlight from 'react-highlight';
import { IntegerInput, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const IntegerInputDoc = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Integer Input</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The IntegerInput component extends the native input type number element.</p>

            <div className='mb-2'>
                <FormGroup className="mb-2" htmlFor="integerInput" label="Integer Input">
                    <IntegerInput id="integerInput" name="integer" />
                </FormGroup>

                <FormGroup className="mb-2" htmlFor="integerInput2" label="Integer Input no arrows">
                    <IntegerInput id="integerInput2" name="integer2" hideArrows />
                </FormGroup>

                <FormGroup htmlFor="integerInput3" label="Integer Input with maxLength">
                    <IntegerInput id="integerInput3" name="integer3" hideArrows maxLength={10} />
                </FormGroup>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { IntegerInput, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <FormGroup className="mb-2" htmlFor="integerInput" label="Integer Input">
            <IntegerInput id="integerInput" name="integer" />
        </FormGroup>

        <FormGroup className="mb-2" htmlFor="integerInput2" label="Integer Input no arrows">
            <IntegerInput id="integerInput2" name="integer2" hideArrows />
        </FormGroup>

        <FormGroup htmlFor="integerInput3" label="Integer Input with maxLength">
            <IntegerInput id="integerInput3" name="integer3" hideArrows maxLength={10} />
        </FormGroup>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" />id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>due to WCAG 2.0 and focus form control in form group on label click.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />hideArrows</td>
                        <td>boolean</td>
                        <td >false</td>
                        <td>Hide the default arrows from input.</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td><strong>Except "type"</strong>, you can use all other native input attributes: "disabled", "name", "placeholder"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default IntegerInputDoc;