import React from 'react';

import {
    Button, Section, ReactAux, ActionsWrapper, IconsSolid, FormGroup, Radio,
} from '@jkhy/vsg-design-system';

const CoApplicantsStepFour = () => {
    return (
        <ReactAux>
            <Section title="Co-Applicants Address">
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Is this your current address? 456 Main St, Some City, IL 60123">
                    <Radio className="d-inline-block" htmlFor="yes" id="yes" name="question" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="no" id="no" name="question" value="no">No</Radio>
                </FormGroup>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/10-5' }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default CoApplicantsStepFour;