import React, { useState, useEffect } from 'react';
import Highlight from 'react-highlight';

import PropVersionTag from '../PropVersionTag/PropVersionTag';
import {
    Button, TimeOutMessage, IconsSolid, FontAwesomeIcon,
} from '@jkhy/vsg-design-system';

const seconds = 66;
let interval: any;

const TimeOutMessageDocs: React.FC = () => {

    const [show, setShow] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(seconds);

    // Countdown used only for demo
    useEffect(() => {
        if (show) {
            interval = setInterval(() => {
                setSecondsLeft(secondsLeft => {
                    if (secondsLeft <= 0) {
                        setShow(false);
                        clearInterval(interval);
                        return 0;
                    }

                    return secondsLeft - 1
                });
            }, 1000);
        } else if (!show) {
            clearInterval(interval);
            setSecondsLeft(seconds);
        }
    }, [show])

    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Time Out Message</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The TimeOutMessage component is a dialog box/popup window that is displayed on top of the current page.</p>

            <div className="mb-2">
                <Button onClick={() => setShow(true)} >Show</Button>
                <TimeOutMessage
                    isVisible={show}
                    title="Long inactivity detected"
                    subText="We will sign you out to keep your account safe"
                    seconds={seconds}
                    secondsLeft={secondsLeft}
                    onClose={() => setShow(false)}
                    buttons={[
                        <Button className="mb-2" iconLeft={IconsSolid.faCheck} onClick={() => setShow(false)}>I am here</Button>,
                        <Button btnType="secondary" iconLeft={IconsSolid.faSignOutAlt} onClick={() => setShow(false)}>Sign out now</Button>,
                    ]}
                />
            </div>

            <Highlight className="JS mb-4">
                {
                    `
import React, { useState, useEffect } from 'react';
import { Button, TimeOutMessage, IconsSolid, FontAwesomeIcon, } from '@jkhy/vsg-design-system';

const seconds = 66;
let interval: any;

const Example = React.FC = () => {

    const [show, setShow] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(seconds);

    // Countdown used only for demo
    useEffect(() => {
        if (show) {
            interval = setInterval(() => {
                setSecondsLeft(secondsLeft => {
                    if (secondsLeft <= 0) {
                        setShow(false);
                        clearInterval(interval);
                        return 0;
                    }

                    return secondsLeft - 1
                });
            }, 1000);
        } else if (!show) {
            clearInterval(interval);
            setSecondsLeft(seconds);
        }
    }, [show])

    return (
        <div>
            <Button onClick={() => this.setState({ show: true })} >Show</Button>
            <TimeOutMessage
                isVisible={show}
                title="Long inactivity detected"
                subText="We will sign you out to keep your account safe"
                seconds={seconds}
                secondsLeft={secondsLeft}
                onClose={() => setShow(false)}
                buttons={[
                    <Button className="mb-2" iconLeft={IconsSolid.faCheck} onClick={() => setShow(false)}>I am here</Button>,
                    <Button btnType="secondary" iconLeft={IconsSolid.faSignOutAlt} onClick={() => setShow(false)}>Sign out now</Button>,
                ]}
            />
        </div>
    )
}

export default Example; 
    `
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isVisible</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Show and <strong>mounted</strong> the component in DOM.</td>
                    </tr>
                    <tr>
                        <td>seconds</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Give the total time for countdown in second.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> secondsLeft</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>Give the remaining time in second.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the title.</td>
                    </tr>
                    <tr>
                        <td>subText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the subText.</td>
                    </tr>
                    <tr>
                        <td>buttons</td>
                        <td>array of JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set buttons.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="obsolete" /> onTimeOut</td>
                        <td>() {'=>'} void</td>
                        <td className='text-center'>-</td>
                        <td>Callback to execute when the time out.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> onClose</td>
                        <td>() {'=>'} void</td>
                        <td className='text-center'>-</td>
                        <td>Callback to execute when the 'X' button is click.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TimeOutMessageDocs;