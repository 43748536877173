import React from 'react';
import Highlight from 'react-highlight';
import { Checkbox, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const CheckboxDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Checkbox</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The Checkbox component extends the native input type checkbox element.</p>

            <div className='mb-2'>
                <h4 className="mb-1">Default</h4>
                <Checkbox className="mb-05" htmlFor="vehicle1" id="vehicle1" name="vehicle1" value="Bike">I have a bike</Checkbox>
                <Checkbox className="mb-05" htmlFor="vehicle2" id="vehicle2" name="vehicle2" value="Car">I have a car</Checkbox>
                <Checkbox className="mb-05" disabled htmlFor="vehicle3" id="vehicle3" name="vehicle3" value="Boat">I have a boat</Checkbox>
                <Checkbox checked disabled htmlFor="vehicle4" id="vehicle4" name="vehicle4" value="Boat">I have a airplane</Checkbox>
            </div>

            <div className="mb-2">
                <h4 className="mb-1">Inline by adding utility class: "d-inline-block"</h4>
                <Checkbox className="d-inline-block mr-05" htmlFor="vehicle1-inline" id="vehicle1-inline" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
                <Checkbox className="d-inline-block mr-05" htmlFor="vehicle2-inline" id="vehicle2-inline" name="vehicle2-inline" value="Car">I have a car</Checkbox>
                <Checkbox className="d-inline-block" htmlFor="vehicle3-inline" id="vehicle3-inline" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>
            </div>

            <div className="mb-2">
                <h4 className="mb-1">In FormGroup</h4>
                <FormGroup label='Checkbox' checkboxOrRadio isBold={true} className="mb-2">
                    <Checkbox className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Checkbox>
                    <Checkbox className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Checkbox>
                </FormGroup>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Checkbox, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        // Default
        <Checkbox className="mb-05" htmlFor="vehicle1" id="vehicle1" name="vehicle1" value="Bike">I have a bike</Checkbox>
        <Checkbox className="mb-05" htmlFor="vehicle2" id="vehicle2" name="vehicle2" value="Car">I have a car</Checkbox>
        <Checkbox className="mb-05" disabled htmlFor="vehicle3" id="vehicle3" name="vehicle3" value="Boat">I have a boat</Checkbox>
        <Checkbox checked disabled htmlFor="vehicle4" id="vehicle4" name="vehicle4" value="Boat">I have a airplane</Checkbox>

        // Inline
        <Checkbox className="d-inline-block mr-05" htmlFor="vehicle1-inline" id="vehicle1-inline" name="vehicle1-inline" value="Bike">I have a bike</Checkbox>
        <Checkbox className="d-inline-block mr-05" htmlFor="vehicle2-inline" id="vehicle2-inline" name="vehicle2-inline" value="Car">I have a car</Checkbox>
        <Checkbox className="d-inline-block" htmlFor="vehicle3-inline" id="vehicle3-inline" name="vehicle3-inline" value="Boat">I have a boat</Checkbox>

        // In FormGroup
        <FormGroup label='Checkbox' checkboxOrRadio isBold={true} className="mb-2">
            <Checkbox className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Checkbox>
            <Checkbox className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Checkbox>
        </FormGroup>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" /> htmlFor</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set <strong>Label</strong> htmlFor text due to WCAG 2.0.</td>
                    </tr>
                    <tr>
                        <td>all native input type checkbox attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input type checkbox attributes like: "name", "value", "checked"...</td>
                    </tr>
                </tbody>
            </table>


        </div>
    )
}

export default CheckboxDocs;