import React from 'react';
import Highlight from 'react-highlight';
import { Input, FontAwesomeIcon, IconsSolid, FormGroup, FormSection, Select, MaskedInput } from '@jkhy/vsg-design-system';

const FormSectionDocs = () => {
    const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Form Section</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Separate your content with background. Helps you to visually group form control components.</p>

            <FormSection>
                <h3 className="mb-2">1. Form Section title</h3>
                <FormGroup htmlFor="fsection" label='Input' className="mb-2">
                    <Input id="fsection" />
                </FormGroup>
                <FormGroup htmlFor="fsSelect" label='Select' className="mb-2">
                    <Select
                        id="fsSelect"
                        options={[
                            { value: 'chocolate', label: 'Chocolate' },
                            { value: 'strawberry', label: 'Strawberry' },
                            { value: 'vanilla', label: 'Vanilla' }]}
                    />
                </FormGroup>
                <FormGroup htmlFor="fsMaskedInput" label='Phone Masked Input'>
                    <MaskedInput
                        id="fsMaskedInput"
                        type='phone'
                        mask={usPhoneCountryCodeMask}
                        icon={IconsSolid.faPhoneAlt}
                    />
                </FormGroup>
            </FormSection>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { 
    FormSection, FormGroup, Select,
    MaskedInput, Input
} from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
        <FormSection>
            <h3 className="mb-2">1. Form Section title</h3>
            <FormGroup htmlFor="fsection" label='Input' className="mb-2">
                        <Input id="fsection" />
            </FormGroup>
            <FormGroup htmlFor="fsSelect" label='Select' className="mb-2">
                        <Select
                            id="fsSelect"
                            options={[
                                { value: 'chocolate', label: 'Chocolate' },
                                { value: 'strawberry', label: 'Strawberry' },
                                { value: 'vanilla', label: 'Vanilla' }]}
                        />
            </FormGroup>
            <FormGroup htmlFor="fsMaskedInput" label='Phone Masked Input'>
                        <MaskedInput
                            id="fsMaskedInput"
                            type='phone'
                            mask={usPhoneCountryCodeMask}
                            icon={IconsSolid.faPhoneAlt}
                        />
            </FormGroup>
        </FormSection>
    );
}

export default Example; 
`
                }
            </Highlight>

        </div>
    )
}

export default FormSectionDocs;