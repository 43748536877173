import React, { forwardRef } from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid, Tooltip } from '@jkhy/vsg-design-system';


class CustomComponent extends React.Component<any> {
    render() {
        return <p {...this.props} >Tooltip Class component</p>;
    }
}

const CustomFuncComponent = forwardRef((props: any, ref: any) => {
    return <p {...props} ref={ref}>Tooltip Function component</p>;
})


const TooltipDocs = () => {

    return <div>
        <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Tooltip</h1>

        <h2 className="mb-2">Description</h2>
        <p className="mb-3">
            Tooltips are small, interactive, textual hints.
            <br /> QD Design System Tooltip components use "material-ui/tooltip" library.
            Check demo and documentation <a href="https://material-ui.com/components/tooltips/" target="_blank" rel="noopener noreferrer">here</a>. <br />
            Tooltips are visible on desktop only.
        </p>
        <p className="mb-4">
            <strong style={{ color: 'var(--color-warning)' }}>IMPORTANT:</strong> The tooltip needs to apply DOM event listeners to its child element. If the child is a custom React element, you need to make sure that it spreads its properties to the underlying DOM element. <a href="https://material-ui.com/components/tooltips/#custom-child-element" target="_blank" rel="noopener noreferrer">See here.</a>
        </p>

        <div className='mb-2'>
            <Tooltip title="Info text here" placement="right">
                <p className="d-inline-block mr-3 mb-1">Tooltip right</p>
            </Tooltip>

            <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                <p className="d-inline-block mr-3 mb-1">Tooltip top</p>
            </Tooltip>

            <Tooltip title="Tooltip" placement="bottom">
                <p className="d-inline-block mr-3 mb-1">Tooltip bottom</p>
            </Tooltip>

            <Tooltip title="Info text here" placement="left">
                <p className="d-inline-block mr-3 mb-1">Tooltip left</p>
            </Tooltip>

            <Tooltip title={<><i>Tooltip</i> <strong>with HTML</strong></>}>
                <p className="d-inline-block mr-3 mb-1">Tooltip with HTML</p>
            </Tooltip>

            <Tooltip title="<i>Tooltip</i> <strong>with HTML</strong>">
                <p className="d-inline-block mr-3 mb-1">Tooltip with HTML as string</p>
            </Tooltip>

            <Tooltip title="Custom Class component">
                <CustomComponent className="d-inline-block mr-3 mb-1" />
            </Tooltip>

            <Tooltip title="Custom Function component">
                <CustomFuncComponent className="d-inline-block mr-3 mb-1" />
            </Tooltip>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React, { forwardRef } from 'react';
import { Tooltip } from '@jkhy/vsg-design-system';

class CustomComponent extends React.Component<any> {
    render() {
        return <p {...this.props}>Tooltip Class component</p>;
    }
}

const CustomFuncComponent = forwardRef((props:any,ref: any) => {
    return <p {...props} ref={ref}>Tooltip Function component</p>;
})

const Example = (props) => {
    return (
    <div>
        <Tooltip title="Info text here" placement="right">
            <p className="d-inline-block mr-3">Tooltip right</p>
        </Tooltip>

        <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
            <p className="d-inline-block mr-3">Tooltip top</p>
        </Tooltip>

        <Tooltip title="Tooltip" placement="bottom">
            <p className="d-inline-block mr-3">Tooltip bottom</p>
        </Tooltip>

        <Tooltip title="Info text here" placement="left">
            <p className="d-inline-block mr-3">Tooltip left</p>
        </Tooltip>

        <Tooltip title={<><i>Tooltip</i> <strong>with HTML</strong></>}>
            <p className="d-inline-block mr-3">Tooltip with HTML</p>
        </Tooltip>

        <Tooltip title="<i>Tooltip</i> <strong>with HTML</strong>">
            <p className="d-inline-block mr-3 mb-1">Tooltip with HTML as string</p>
        </Tooltip>

        <Tooltip title="Custom Class component">
            <CustomComponent  className="d-inline-block mr-3 mb-1"/>
        </Tooltip>

        <Tooltip title="Custom Function component">
            <CustomFuncComponent className="d-inline-block mr-3 mb-1"/>
        </Tooltip>
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>title</td>
                    <td>string | JSX.Element</td>
                    <td className="text-center">-</td>
                    <td>Tooltip title. Zero-length titles string are never displayed.</td>
                </tr>
                <tr>
                    <td>placement</td>
                    <td>'top' | 'bottom' | 'left' | 'right'</td>
                    <td>'top'</td>
                    <td>Tooltip placement.</td>
                </tr>
                <tr>
                    <td>id</td>
                    <td>string</td>
                    <td className="text-center">-</td>
                    <td>This prop is used to help implement the accessibility logic. If you don't provide this prop. It falls back to a randomly generated id.</td>
                </tr>
                <tr>
                    <td>open</td>
                    <td>bool</td>
                    <td className="text-center">-</td>
                    <td>If <strong>true</strong>, the tooltip is shown.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default TooltipDocs;