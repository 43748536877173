import React from 'react';

import { Section, FileUploadHistoryCard } from '@jkhy/vsg-design-system';

const requestedHistoryPage = () => {
    return (
        <Section title="History">
            <p className="mb-3">A summary of all documents uploaded through your client portal are listed below.  Actual copies of the documents uploaded, are not available here for security purposes.</p>
            <FileUploadHistoryCard
                className="mb-2"
                title="Recurring document title goes here"
                description={{ title: 'Description:', text: 'DATE, FREQ, GDAYS' }}
                details={[
                    { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                    { title: 'Statement Date:', text: '04/12/2019' },
                    { title: 'Due:', text: '04/14/2019' },
                ]}>
                <div className="history-table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th><p className="body2 text-nowrap"><strong>Date:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Action:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Description:</strong></p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </FileUploadHistoryCard>

            <FileUploadHistoryCard
                className="mb-2"
                title="Recurring document title goes here"
                description={{ title: 'Description:', text: 'DATE, FREQ, GDAYS' }}
                details={[
                    { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                    { title: 'Statement Date:', text: '04/12/2019' },
                    { title: 'Due:', text: '04/14/2019' },
                ]}>
                <div className="history-table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th><p className="body2 text-nowrap"><strong>Date:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Action:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Description:</strong></p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </FileUploadHistoryCard>

            <FileUploadHistoryCard
                className="mb-2"
                title="Recurring document title goes here"
                description={{ title: 'Description:', text: 'DATE, FREQ, GDAYS' }}
                details={[
                    { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                    { title: 'Statement Date:', text: '04/12/2019' },
                    { title: 'Due:', text: '04/14/2019' },
                ]}>
                <div className="history-table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th><p className="body2 text-nowrap"><strong>Date:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Action:</strong></p></th>
                                <th><p className="body2 text-nowrap"><strong>Description:</strong></p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                            <tr>
                                <td><p className="body2">07/17/2019</p></td>
                                <td><p className="body2">Document uploaded</p></td>
                                <td><p className="body2">n/a:</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </FileUploadHistoryCard>
        </Section>
    );
}

export default requestedHistoryPage;