import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CoApplicantsStepOne from './CoApplicantsSteps/CoApplicantsStepOne';
import CoApplicantsStepTwo from './CoApplicantsSteps/CoApplicantsStepTwo';
import CoApplicantsStepThree from './CoApplicantsSteps/CoApplicantsStepThree';
import CoApplicantsStepFour from './CoApplicantsSteps/CoApplicantsStepFour';
import CoApplicantsStepFive from './CoApplicantsSteps/CoApplicantsStepFive';
import CoApplicantsStepSix from './CoApplicantsSteps/CoApplicantsStepSix';
import CoApplicantsStepSeven from './CoApplicantsSteps/CoApplicantsStepSeven';

const PageCoApplicants = () => {
    return (
        <Switch>
            <Route path="/views/pages/10-1" component={CoApplicantsStepOne} />
            <Route path="/views/pages/10-2" component={CoApplicantsStepTwo} />
            <Route path="/views/pages/10-3" component={CoApplicantsStepThree} />
            <Route path="/views/pages/10-4" component={CoApplicantsStepFour} />
            <Route path="/views/pages/10-5" component={CoApplicantsStepFive} />
            <Route path="/views/pages/10-6" component={CoApplicantsStepSix} />
            <Route path="/views/pages/10-7" component={CoApplicantsStepSeven} />
        </Switch>
    );
}

export default PageCoApplicants;