import React from 'react';
import Highlight from 'react-highlight';
import { Button, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import DesignGuidelinesLink from '../DesignGuidelinesLink/DesignGuidelinesLink';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const ButtonDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Button</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>Button components in QD Design System extend the native Button element. Use QD Design System's custom button styles for actions in forms, dialogs, and more.</p>

            <h3 className='mb-3'>Button types</h3>
            <p className='mb-2'>
                There are tree type of buttons : "primary", "secondary", "icon".
            </p>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" >Primary</Button>
                <Button btnType="secondary">Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" iconLeft={IconsSolid.faPlus} >Primary</Button>
                <Button btnType="secondary" iconLeft={IconsSolid.faPlus} >Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" iconRight={IconsSolid.faPlus}>Primary</Button>
                <Button btnType="secondary" iconRight={IconsSolid.faPlus}>Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" link={{
                    path: '/',
                    title: 'React link',
                }}>Link</Button>
                <Button btnType="secondary" link={{
                    path: '/',
                    title: 'Native link',
                    isNativeLink: true,
                }}>Native link</Button>
            </div>
            <div className='mb-2'>
                <Button btnType="icon" title="Delete"><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
            </div>

            <DesignGuidelinesLink link="/docs/buttons" />

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Button btnType="primary" >Primary</Button>
        <Button btnType="secondary">Secondary</Button>

        <Button btnType="primary" iconLeft={IconsSolid.faPlus} >Primary</Button>
        <Button btnType="secondary" iconLeft={IconsSolid.faPlus} >Secondary</Button>

        <Button btnType="primary" iconRight={IconsSolid.faPlus}>Primary</Button>
        <Button btnType="secondary" iconRight={IconsSolid.faPlus}>Secondary</Button>

        <Button btnType="primary" link={{
            path: '/',
            title: 'React link',
        }}>Link</Button>
        <Button btnType="secondary" link={{
            path: '/',
            title: 'Native link',
            isNativeLink: true,
        }}>Native link</Button>

        <Button btnType="icon" title="Delete"><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>btnType</td>
                        <td>"primary", "secondary", "icon"</td>
                        <td>"primary"</td>
                        <td>Set different style to the button.</td>
                    </tr>
                    <tr>
                        <td>iconLeft</td>
                        <td>iconDefinition</td>
                        <td className='text-center'>-</td>
                        <td>Set left icon to the button.</td>
                    </tr>
                    <tr>
                        <td>iconRight</td>
                        <td>iconDefinition</td>
                        <td className='text-center'>-</td>
                        <td>Set right icon to the button.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" /> link</td>
                        <td>
                            object with keys:
                            <br />"path" : string,
                            <br /> "title" :  string,
                            <br /> "isNativeLink" :  boolean,
                            <br /> "isOpenNewTab" :  boolean
                        </td>
                        <td className="text-center">-</td>
                        <td>If defined, an 'a' element will be used as the root node.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native button attributes like: "type", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

            <h3 className='mb-3'>Disabled Button</h3>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" disabled>Primary</Button>
                <Button btnType="secondary" disabled>Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" iconLeft={IconsSolid.faPlus} disabled>Primary</Button>
                <Button btnType="secondary" iconLeft={IconsSolid.faPlus} disabled>Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className="mr-2" btnType="primary" iconRight={IconsSolid.faPlus} disabled>Primary</Button>
                <Button btnType="secondary" iconRight={IconsSolid.faPlus} disabled>Secondary</Button>
            </div>
            <div className='mb-2'>
                <Button className='mr-2' title="Delete" btnType="icon" disabled><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Button btnType="primary" disabled>Primary</Button>
        <Button btnType="secondary" disabled>Secondary</Button>

        <Button btnType="primary" iconLeft={IconsSolid.faPlus} disabled>Primary</Button>
        <Button btnType="secondary" iconLeft={IconsSolid.faPlus} disabled>Secondary</Button>

        <Button btnType="primary" iconRight={IconsSolid.faPlus} disabled>Primary</Button>
        <Button btnType="secondary" iconRight={IconsSolid.faPlus} disabled>Secondary</Button>

        <Button btnType="icon" title="Delete" disabled><FontAwesomeIcon icon={IconsSolid.faTrash} /></Button>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>
        </div>
    )
}

export default ButtonDocs;