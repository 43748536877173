import React from 'react';

import {
    Button, Section, ReactAux, ActionsWrapper, IconsSolid, FormGroup, Input, Datepicker,
    MaskedInput,
} from '@jkhy/vsg-design-system';

const lastDigitsMaks = [/\d/, /\d/, /\d/, /\d/];

const CoApplicantsStepThree = () => {
    return (
        <ReactAux>
            <Section
                title="Co-Applicants"
                headerText="Header text: Let us see if we can locate your information. Please enter the Information following information."
            >
                <FormGroup className="mb-2" htmlFor="firstName" isRequired label="First Name">
                    <Input type="text" name="firstName" id="firstName" />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="secondName" isRequired label="Last Name">
                    <Input type="text" name="secondName" id="secondName" />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="dpDef" isRequired label="Date of Birth">
                    <Datepicker id="dpDef" name="dpDef" />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="lastDigits" isRequired label="Last four digits of your Social Security Number">
                    <MaskedInput
                        id="lastDigits"
                        mask={lastDigitsMaks}
                    />
                </FormGroup>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/10-4' }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default CoApplicantsStepThree;