import React from 'react';
import Highlight from 'react-highlight';

import { DataPreviewText, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const DataPreviewTextPage = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Data Preview Text</h1>

            <h2 className="mb-2">Description</h2>
            <p className="mb-4">Used to present data that has been submitted in the system.</p>

            <DataPreviewText className="mb-2" label="First Name:" text="Jane" />
            <DataPreviewText className="mb-2" label="Last Name:" text="Doe" isInline />
            <DataPreviewText className="mb-2" label="Vehicle Purchase:" text="Term Loan" bold="label" />
            <DataPreviewText className="mb-2" label="First Name:" text="Jane" isVisible={false} />

            <Highlight className="React">
                {
                    `
import React from 'react';
import { DataPreviewText } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
        <div>
            <DataPreviewText className="mb-2" label="First Name:" text="Jane" />
            <DataPreviewText className="mb-2" label="Last Name:" text="Doe" isInline />
            <DataPreviewText className="mb-2" label="Vehicle Purchase:" text="Term Loan" bold="label" />
            <DataPreviewText isVisible={false} className="mb-2" label="First Name:" text="Jane" />
        </div>
    );
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>label</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the label.</td>
                    </tr>
                    <tr>
                        <td>text</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text.</td>
                    </tr>
                    <tr>
                        <td>isInline</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Placed the texts next to each other.</td>
                    </tr>
                    <tr>
                        <td>bold</td>
                        <td>'label' | 'text'</td>
                        <td>'text'</td>
                        <td>Sets who text to be bold.</td>
                    </tr>
                    <tr>
                        <td>isVisible</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Render in DOM.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default DataPreviewTextPage;
