import React, {
    useEffect, useState, useRef
} from 'react';
import Highlight from 'react-highlight';
import {
    FontAwesomeIcon, IconsSolid, InfoCard, Button, Loader, Alert,
    DataPreviewText, Input, FormSection, FormGroup, ActionsWrapper
} from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';


const InfoCardDocs = () => {
    const userEmail = 'jeffreyhinman@dd.com';
    const inputRefs = useRef<HTMLInputElement>();
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [updatedEmail, setUpdatedEmail] = useState(userEmail);
    const [isLoading, setIsLoading] = useState(false);
    const [cardSuccess, setCardSuccess] = useState([false, false]);
    const [cardError, setCardError] = useState([false, false]);
    const [emailError, setEmailError] = useState(false);



    const saveEmail = () => {
        if (showInput) {
            // sending request to save e-mail
            setIsLoading(true);
            setTimeout(() => {
                const isError = Math.floor(Math.random() * 2);
                if (showInput) setIsLoading(false);
                // success request. E-mail is saved
                if (!isError) {
                    if (emailError) setEmailError(false)
                    setShowInput(false);
                    setUpdatedEmail(email);
                }

                // error request. E-mail not saved
                if (isError) setEmailError(true);
            }, 2000);
        } else {
            setShowInput(true);
        }
    }

    const resendEmail = (index: any) => {
        // send request for resend e-mail
        setTimeout(() => {
            const isError = Math.floor(Math.random() * 2);
            if (!isError) {
                // success resend e-mail
                const copyCardSuccess = [...cardSuccess];
                copyCardSuccess[index] = true;
                const copyCardError = [...cardError];
                copyCardError[index] = false;
                setCardSuccess(copyCardSuccess);
                setCardError(copyCardError);
            } else {
                const copyCardError = [...cardError];
                copyCardError[index] = true;
                setCardError(copyCardError);
            }
        }, 2000);
    }

    useEffect(() => {
        const isCardSucces = cardSuccess.find(el => el === true);
        if (isCardSucces) {
            const copyCardSuccess = [...cardSuccess];
            setCardSuccess(copyCardSuccess.map(el => false));
        }
    }, [cardSuccess]);

    useEffect(() => {
        if (showInput) {
            // focus and select input on show
            const currentInput = inputRefs.current as any;
            currentInput.current.focus();
            currentInput.current.select();
        }

    }, [showInput])

    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" />Info Card</h1>

            <h2 className="mb-2">Description</h2>

            <p className="mb-4">Information card which helps you list different information.</p>

            <div className="mb-4">
                <InfoCard
                    className="mb-3"
                    titles={['Requested Documents']}
                    description="You have 74 requested documents"
                    link={{ label: 'DETAILS', path: '#', isNativeLink: true, isOpenNewTab: true }} />

                <InfoCard
                    className="mb-3"
                    logo="https://i.ibb.co/mhGZ4kh/data-image-png-base.png"
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                    link={{ label: 'APPLY NOW', path: '#' }}
                />

                <InfoCard
                    className="mb-3"
                    mainLogo
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                    link={{ label: 'APPLY NOW', path: '#' }}
                />

                <InfoCard
                    className="mb-3"
                    titles={['***-308', '***-310', '***-314']}
                    details={[
                        { title: 'Required for', text: 'TWO BUCKLESSxx' },
                        { title: 'Date invited', text: '15/10/2019' },
                        { title: 'Renewal Due By', text: '06/11/2020' },
                        { title: 'Status', text: 'Sent' },
                    ]}
                    button={{
                        label: 'START', onClicked: () => {
                            console.log('Clicked');
                        }
                    }} />

                <InfoCard
                    className="mb-3"
                    button={{
                        label: 'OK', onClicked: () => {
                            console.log('Clicked');
                        }
                    }}
                >
                    <p className="mb-2">With custom content</p>
                </InfoCard>
            </div>


            <div className="mb-4">
                <h2 className="mb-2">Info card success</h2>
                <InfoCard
                    className="mb-3"
                    successState={{
                        show: cardSuccess[0],
                        displayMilliseconds: 3000,
                        content: {
                            description: `E-mail to ${email} was successfully sent!`,
                            icon: IconsSolid.faPaperPlane,
                        },
                        isError: cardError[0]
                    }}
                    button={{
                        label: 'Resend E-mail',
                        icon: IconsSolid.faRedo,
                        onClicked: () => resendEmail(0),
                        disabled: showInput
                    }}
                >
                    <DataPreviewText className="mb-1" isInline label="Name:" text="Jeffrey Hinman" />
                    <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
                    <DataPreviewText className="mb-2" isInline label="Response Date:" text="N/A" />

                    <FormSection className="mb-2">
                        {!showInput && <DataPreviewText className="mb-1" isInline label="Email:" text={email} />}
                        {showInput &&
                            <FormGroup className="mb-2" htmlFor="email" isRequired label="E-mail Address">
                                <Input ref={inputRefs} type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormGroup>
                        }
                        {
                            showInput && emailError && <Alert className="mb-2" type="danger" text="Email not saved!" />
                        }
                        <ActionsWrapper
                            {...isLoading ? { alignCenter: true } : { alignLeft: true }}
                            removePadding
                            removeBg
                        >
                            {!isLoading && <Button
                                btnType={!showInput ? "secondary" : 'primary'}
                                iconLeft={IconsSolid.faSave}
                                onClick={saveEmail}
                                type="button"
                                className={showInput ? "action-spacer" : ''}
                            >
                                {!showInput ? "Update e-mail address" : "Save e-mail address"}
                            </Button>}
                            {showInput && !isLoading &&
                                <Button
                                    btnType="secondary"
                                    onClick={() => {
                                        if (updatedEmail !== email) {
                                            setEmail(updatedEmail);
                                        }
                                        if (emailError) {
                                            setEmailError(false);
                                        }
                                        setShowInput(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            }

                            {isLoading && <Loader isSmall />}
                        </ActionsWrapper>
                    </FormSection>
                    <Alert className="mb-2" type="info" text="Application started" />
                    {cardError[0] && <Alert className="mb-2" type="danger" text="Email not resent!" />}
                </InfoCard>

                <InfoCard
                    className="mb-3"
                    successState={{
                        show: cardSuccess[1],
                        content: {
                            description: 'Your E-mail was resend!',
                            icon: IconsSolid.faPaperPlane,
                            title: "Success"
                        },
                        isError: cardError[1]
                    }}
                    button={{
                        label: 'Resend E-mail',
                        icon: IconsSolid.faRedo,
                        onClicked: () => resendEmail(1)
                    }}
                >
                    <DataPreviewText className="mb-1" isInline label="Name:" text="Steven Horton" />
                    <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
                    <DataPreviewText className="mb-2" isInline label="Response Date:" text="02/08/2021" />
                    <Alert className="mb-2" type="success" text="Completed" />
                    {cardError[1] && <Alert className="mb-2" type="danger" text="Email not resent!" />}
                </InfoCard>
            </div>

            <div className='mb-4'>
                <h2 className="mb-2">Info card with two actions (buttons or links)</h2>

                <InfoCard
                    className="mb-3"
                    mainLogo
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                    button={{ label: 'APPLY NOW', onClicked: () => console.log('clicked button') }}
                    buttonSec={{ label: 'Find Our if you qualify', onClicked: () => console.log('clicked button') }}
                />

                <InfoCard
                    className="mb-3"
                    mainLogo
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                    link={{ label: 'Details', path: '#' }}
                    linkSec={{ label: 'Find Our if you qualify', path: '#' }}
                />
            </div>

            <div className='mb-4'>
                <h2 className="mb-2">Info card without actions</h2>
                <InfoCard
                    className="mb-3"
                    mainLogo
                    titles={['Line of Credit - Man United']}
                    description="Unsecured loan application up to $100,000. No Collateral needed!"
                />
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React, { useEffect, useState, useRef } from 'react';
import { 
    FontAwesomeIcon, IconsSolid, InfoCard, Button, Loader, Alert,
    DataPreviewText, Input, FormSection, FormGroup, ActionsWrapper
 } from '@jkhy/vsg-design-system';

const Example = (props) => {
    const userEmail = 'jeffreyhinman@dd.com';
    const inputRefs = useRef<HTMLInputElement>();
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [updatedEmail, setUpdatedEmail] = useState(userEmail);
    const [isLoading, setIsLoading] = useState(false);
    const [cardSuccess, setCardSuccess] = useState([false, false]);
    const [cardError, setCardError] = useState([false, false]);
    const [emailError, setEmailError] = useState(false);


 
    const saveEmail = () => {
        if (showInput) {
            // sending request to save e-mail
            setIsLoading(true);
            setTimeout(() => {
                const isError = Math.floor(Math.random() * 2);
                if (showInput) setIsLoading(false);
                // success request. E-mail is saved
                if (!isError) {
                    if (emailError) setEmailError(false)
                    setShowInput(false);
                    setUpdatedEmail(email);
                }

                // error request. E-mail not saved
                if (isError) setEmailError(true);
            }, 2000);
        } else {
            setShowInput(true);
        }
    }

    const resendEmail = (index: any) => {
        // send request for resend e-mail
        setTimeout(() => {
            const isError = Math.floor(Math.random() * 2);
            if (!isError) {
                // success resend e-mail
                const copyCardSuccess = [...cardSuccess];
                copyCardSuccess[index] = true;
                const copyCardError = [...cardError];
                copyCardError[index] = false;
                setCardSuccess(copyCardSuccess);
                setCardError(copyCardError);
            }else{
                const copyCardError = [...cardError];
                copyCardError[index] = true;
                setCardError(copyCardError);
            }
        }, 2000);
    }

    useEffect(() => {
        const isCardSucces = cardSuccess.find(el => el === true);
        if (isCardSucces) {
            const copyCardSuccess = [...cardSuccess];
            setCardSuccess(copyCardSuccess.map(el => false));
        }
    }, [cardSuccess]);

    useEffect(() => {
        if (showInput) {
            // focus and select input on show
            const currentInput = inputRefs.current as any;
            currentInput.current.focus();
            currentInput.current.select();
        }

    }, [showInput])

    return (
    <div>
        <InfoCard
            className="mb-3"
            titles={['Requested Documents']}
            description="You have 74 requested documents"
            link={{ label: 'DETAILS', path: '#', isNativeLink: true, isOpenNewTab: true }} 
        />

        <InfoCard
            className="mb-3"
            logo="https://i.ibb.co/mhGZ4kh/data-image-png-base.png"
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
            link={{ label: 'APPLY NOW', path: '#' }} 
        />

        // Example with set header logo
        <InfoCard
            className="mb-3"
            mainLogo
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
            link={{ label: 'APPLY NOW', path: '#' }}
        />

        <InfoCard
            className="mb-3"
            titles={['***-308', '***-310', '***-314']}
            details={[
                { title: 'Required for', text: 'TWO BUCKLESSxx' },
                { title: 'Date invited', text: '15/10/2019' },
                { title: 'Renewal Due By', text: '06/11/2020' },
                { title: 'Status', text: 'Sent' },
            ]}
            button={{
                label: 'START', onClicked: () => {
                    console.log('Clicked');
                }
            }} />

        <InfoCard
            className="mb-3"
            button={{
                label: 'OK', onClicked: () => {
                    console.log('Clicked');
                }
            }}
        >
            <p className="mb-2">With custom content</p>
        </InfoCard>

        // Info card success

        <InfoCard
            className="mb-3"
            successState={{
                show: cardSuccess[0],
                displayMilliseconds: 3000,
                content: {
                    description: \`E-mail to \${email} was successfully sent!\`,
                    icon: IconsSolid.faPaperPlane,
                }
            }}
            button={{
                label: 'Resend E-mail',
                icon: IconsSolid.faRedo,
                onClicked: () => resendEmail(0),
                disabled: showInput
            }}
         >
            <DataPreviewText className="mb-1" isInline label="Name:" text="Jeffrey Hinman" />
            <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
            <DataPreviewText className="mb-2" isInline label="Response Date:" text="N/A" />

            <FormSection className="mb-2">
                {!showInput && <DataPreviewText className="mb-1" isInline label="Email:" text={email} />}
                {showInput && <FormGroup className="mb-2" htmlFor="email" isRequired label="E-mail Address">
                    <Input ref={inputRefs} type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormGroup>}
                <ActionsWrapper
                    {...isLoading ? { alignCenter: true } : { alignLeft: true }}
                    removePadding
                    removeBg
                >
                {!isLoading && <Button
                    btnType={!showInput ? "secondary" : 'primary'}
                    iconLeft={IconsSolid.faSave}
                    onClick={saveEmail}
                    type="button"
                    className={showInput ? "action-spacer" : ''}
                >
                    {!showInput ? "Update e-mail address" : "Save e-mail address"}
                </Button>}
                {showInput && !isLoading &&
                    <Button
                        btnType="secondary"
                        onClick={() => {
                            if (updatedEmail !== email) {
                                setEmail(updatedEmail);
                            }
                            if (emailError) {
                                setEmailError(false);
                            }
                            setShowInput(false);
                        }}
                    >
                        Cancel
                    </Button>
                }

                {isLoading && <Loader isSmall />}
            </ActionsWrapper>
            </FormSection>
            <Alert className="mb-2" type="info" text="Application started" />
        </InfoCard>

        <InfoCard
            className="mb-3"
            successState={{
                show: cardSuccess[1],
                content: {
                    description: 'Your E-mail was resend!',
                    icon: IconsSolid.faPaperPlane,
                    title: "Success"
                }
            }}
            button={{
                label: 'Resend E-mail',
                icon: IconsSolid.faRedo,
                onClicked: () => resendEmail(1)
            }}
        >
            <DataPreviewText className="mb-1" isInline label="Name:" text="Steven Horton" />
            <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
            <DataPreviewText className="mb-2" isInline label="Response Date:" text="02/08/2021" />
            <Alert className="mb-2" type="success" text="Completed" />
        </InfoCard>

        // Info card with two actions (buttons or links)

        <InfoCard
            className="mb-3"
            mainLogo
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
            button={{ label: 'APPLY NOW', onClicked: () => console.log('clicked button') }}
            buttonSec={{ label: 'Find Our if you qualify', onClicked: () => console.log('clicked button') }}
        />

        <InfoCard
            className="mb-3"
            mainLogo
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
            link={{ label: 'Details', path: '#' }}
            linkSec={{ label: 'Find Our if you qualify', path: '#' }}
        />

        // Info card without actions
        <InfoCard
            className="mb-3"
            mainLogo
            titles={['Line of Credit - Man United']}
            description="Unsecured loan application up to $100,000. No Collateral needed!"
        />
    </div>
    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>logo</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the logo.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />mainLogo</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Set logo from root css variable(--header-logo).</td>
                    </tr>
                    <tr>
                        <td>titles</td>
                        <td>array of strings</td>
                        <td className="text-center">-</td>
                        <td>Sets the given texts as titles in the exact order.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set the description text.</td>
                    </tr>
                    <tr>
                        <td>details</td>
                        <td>array of objects with keys: "title", "text"</td>
                        <td className="text-center">-</td>
                        <td>Set the details.</td>
                    </tr>
                    <tr>
                        <td>link</td>
                        <td>
                            object with keys:
                            <br />"label" : string,
                            <br />"path" : string,
                            <br /> "icon" :  FA icon,
                            <br /> "isNativeLink" :  boolean,
                            <br /> "isOpenNewTab" :  boolean
                            <br /> "dataUI" :  string,
                            <br /> "hidden" :  boolean,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the link.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.4" />linkSec</td>
                        <td>
                            object with keys:
                            <br />"label" : string,
                            <br />"path" : string,
                            <br /> "icon" :  FA icon,
                            <br /> "isNativeLink" :  boolean,
                            <br /> "isOpenNewTab" :  boolean
                            <br /> "dataUI" :  string,
                            <br /> "hidden" :  boolean,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the secondary link.</td>
                    </tr>
                    <tr>
                        <td>button</td>
                        <td>
                            object with keys:
                            <br />"label" : string,
                            <br /> "icon" :  FA icon,
                            <br /> "onClicked" :  void,
                            <br /> "dataUI" :  string,
                            <br /> "disabled" :  boolean,
                            <br /> "hidden" :  boolean,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the button.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.4" />buttonSec</td>
                        <td>
                            object with keys:
                            <br />"label" : string,
                            <br /> "icon" :  FA icon,
                            <br /> "onClicked" :  void,
                            <br /> "dataUI" :  string,
                            <br /> "disabled" :  boolean,
                            <br /> "hidden" :  boolean,
                        </td>
                        <td className="text-center">-</td>
                        <td>Set the secondary button.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />successState</td>
                        <td>object with keys:
                            <br />"show" : boolean
                            <br /> "content" : object with keys:<br />
                            &nbsp;&nbsp;&nbsp;  "title": string, <br />
                            &nbsp;&nbsp;&nbsp;  "description": string, <br />
                            &nbsp;&nbsp;&nbsp;  "icon": FA icon
                            <br /> "displayMilliseconds": number,
                            <br />"isError": boolean
                        </td>
                        <td>
                            <br />"show" : -
                            <br /> "content" : object with keys:<br />
                            &nbsp;&nbsp;&nbsp;  "title": 'Thank you.', <br />
                            &nbsp;&nbsp;&nbsp;  "description": 'Success', <br />
                            &nbsp;&nbsp;&nbsp;  "icon": -
                            <br /> "displayMilliseconds": 5000
                            <br />"isError": -
                        </td>
                        <td>Set Info card success state props.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" /> children</td>
                        <td>React.ReactNode</td>
                        <td className="text-center">-</td>
                        <td>React children to render custom content.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default InfoCardDocs;