import React from 'react';

import {
    Button, Section, ActionsWrapper, IconsSolid,
    ReactAux, ScrollableContainer, Checkbox
} from '@jkhy/vsg-design-system';

const PageRenDisclosures = () => {

    return (
        <ReactAux>
            <Section
                title="Disclosures"
                footerText="Click ‘Continue’ to proceed."
            >
                <p className="mb-3 text-muted">Please review the disclosures below:</p>

                <ScrollableContainer className="mb-1">
                    <h3>Truth in Lending</h3>
                    <p className='mb-2'>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI.
                        The can assing separate disclosures in Admin.
                    </p>

                    <h3>Application Notification</h3>
                    <p className='mb-2'>
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI.
                        This would be te location of any Disclosures.
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI.
                        This would be te location of any Disclosures.
                    </p>
                </ScrollableContainer>
                <Checkbox htmlFor="accept" id="accept" name="accept">
                    I have read and agree to the disclosures above.
                </Checkbox>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: `/renewals/pages/6` }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageRenDisclosures;