import React from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid, Loader } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const LoaderDocs = () => {

    return <div>
        <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Loader</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>Indicates the loading state of a component or page.</p>

        <div className='mb-2'>
            <Loader />
            <br />
            <br />
            <br />
            <Loader text="Sending..." noBackground/>
            <br />
            <br />
            <br />
            <Loader isSmall />
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Loader } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Loader />
        <br />
        <br />
        <br />
        <Loader text="Sending..." noBackground/>
        <br />
        <br />
        <br />
        <Loader isSmall />
    </div>
    );
}
export default Example; 
    `}
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>isSmall</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Set small loader.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" version={'4.2'}/>noBackground</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Remove big loader background and shadow.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" version={'4.2'}/>text</td>
                    <td>string</td>
                    <td>"Loading..."</td>
                    <td>Set big loader text.</td>
                </tr>
            </tbody>
        </table>

    </div>;
}

export default LoaderDocs;
