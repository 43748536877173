import React from 'react';
import Highlight from 'react-highlight';

import { FileUploadHistoryCard, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const FileUploadHistoryCardDocs = () => {

	return <div>
		<h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> File Upload History Card</h1>

		<h2 className="mb-2">Description</h2>
		<p className="mb-4">FileUploadHistoryCard is used to display information with a collapsible table for additional dynamic content.</p>

		<FileUploadHistoryCard
			className="mb-2"
			title="Recurring document title goes here"
			description={{ title: 'Description:', text: 'DATE, FREQ, GDAYS' }}
			details={[
				{ title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
				{ title: 'Statement Date:', text: '04/12/2019' },
				{ title: 'Due:', text: '04/14/2019' },
			]}>
			<div className="history-table-wrapper">
				<table>
					<thead>
						<tr>
							<th><p className="body2 text-nowrap"><strong>Date</strong></p></th>
							<th><p className="body2 text-nowrap"><strong>Action</strong></p></th>
							<th><p className="body2 text-nowrap"><strong>Description</strong></p></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><p className="body2">07/17/2019</p></td>
							<td><p className="body2">Document uploaded</p></td>
							<td><p className="body2">n/a:</p></td>
						</tr>
						<tr>
							<td><p className="body2">07/17/2019</p></td>
							<td><p className="body2">Document uploaded</p></td>
							<td><p className="body2">n/a:</p></td>
						</tr>
					</tbody>
				</table>
			</div>
		</FileUploadHistoryCard>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';
import { FileUploadHistoryCard } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
	<FileUploadHistoryCard
		className="mb-2"
		title="Recurring document title goes here"
		description={{ title: 'Description:', text: 'DATE, FREQ, GDAYS' }}
		details={[
			{ title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
			{ title: 'Statement Date:', text: '04/12/2019' },
			{ title: 'Due:', text: '04/14/2019' },
		]}>
		<div className="history-table-wrapper">
			<table>
				<thead>
					<tr>
						<th><p className="body2 text-nowrap"><strong>Date</strong></p></th>
						<th><p className="body2 text-nowrap"><strong>Action</strong></p></th>
						<th><p className="body2 text-nowrap"><strong>Description</strong></p></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td><p className="body2">07/17/2019</p></td>
						<td><p className="body2">Document uploaded</p></td>
						<td><p className="body2">n/a:</p></td>
					</tr>
					<tr>
						<td><p className="body2">07/17/2019</p></td>
						<td><p className="body2">Document uploaded</p></td>
						<td><p className="body2">n/a:</p></td>
					</tr>
				</tbody>
			</table>
		</div>
	</FileUploadHistoryCard>
    );
}

export default Example; 
	`}
		</Highlight>

		<table className="doc-table mb-3">
			<thead>
				<tr>
					<th colSpan={4}>Props</th>
				</tr>
				<tr>
					<td>
						<strong>Name</strong>
					</td>
					<td>
						<strong>Options</strong>
					</td>
					<td>
						<strong>Default</strong>
					</td>
					<td>
						<strong>Description</strong>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>title</td>
					<td>string</td>
					<td className="text-center">-</td>
					<td>Set title to the card.</td>
				</tr>
				<tr>
					<td><PropVersionTag type="new" /> description</td>
					<td>object with keys: "title", "text"</td>
					<td className="text-center">-</td>
					<td>Set the description.</td>
				</tr>
				<tr>
					<td>details</td>
					<td>array of objects with keys: "title", "text", "dataUI"</td>
					<td className="text-center">-</td>
					<td>Set the details.</td>
				</tr>
			</tbody>
		</table>
	</div>;
}

export default FileUploadHistoryCardDocs;