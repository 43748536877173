import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { AddressAutocomplete, FontAwesomeIcon, IconsSolid, FormGroup, } from '@jkhy/vsg-design-system';

const AddressAutocompleteDocs = () => {
    const [address, setAddress] = useState('');
    
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Address Autocomplete</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-1'>An Autocomplete component, which is integrated
                with the <a href="https://developers.google.com/maps/documentation/javascript/places-autocomplete" target="_blank" rel="noopener noreferrer">Google Maps API</a> and
                allows real address suggestions on search.</p>
            <p className="mb-1">Full example of address form with AddressAutocomplete is available <a href="/views/pages/address" target="_blank" rel="noopener noreferrer">here</a>.</p>
            <p className="mb-1">
                <strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> It is crucial to generate a Google Cloud API key and to add
                the <a href="https://developers.google.com/maps/documentation/javascript/places-autocomplete#loading-the-library" target="_blank" rel="noopener noreferrer">Maps API script</a>.
                Notice that the script should be added in the HTML document <strong>before the Design System is loaded.</strong>
            </p>
            <p className="mb-3">
                <strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> Billing must be configured for the Google Cloud account, which provides
                the API key. See more details <a href="https://developers.google.com/maps/documentation/places/web-service/usage-and-billing" target="_blank" rel="noopener noreferrer">here</a>.
            </p>

            <div className='mb-2'>
                <FormGroup className="mb-2" htmlFor="address" isRequired label="Address">
                    <AddressAutocomplete
                        type="text"
                        name="address"
                        id="address"
                        value={address}
                        onChange={(e: any) => {
                            setAddress(e)
                        }}
                        onAddressSelect={(addressDetails: any) => {
                            console.log(addressDetails);
                            setAddress(addressDetails.fullAddress);
                        }}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="disabledAddress" isRequired label="Address disabled">
                    <AddressAutocomplete
                        type="text"
                        name="disabledAddress"
                        id="disabledAddress"
                        placeholder="400 Moraga Street, San Francisco, CA, USA"
                        disabled
                    />
                </FormGroup>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React, { useState } from 'react';
import { AddressAutocomplete, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    const [address, setAddress] = useState('');
    return (
    <div>
        <FormGroup className="mb-2" htmlFor="address" isRequired label="Address">
            <AddressAutocomplete
                type="text"
                name="address"
                id="address"
                value={address}
                onChange={(e: any) => {
                    setAddress(e)
                }}
                onAddressSelect={(addressDetails: any) => {
                    console.log(addressDetails);
                    setAddress(addressDetails.fullAddress);
                }}
            />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="disabledAddress" isRequired label="Address disabled">
            <AddressAutocomplete
                type="text"
                name="disabledAddress"
                id="disabledAddress"
                placeholder="400 Moraga Street, San Francisco, CA, USA"
                disabled
            />
        </FormGroup>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>
            <p className="mb-1">AddressAutocomplete has React properties which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>
            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Required by WCAG 2.0 version. Ensures the form control is focused after click on the label of the
                            wrapper FormGroup.</td>
                    </tr>
                    <tr>
                        <td>onAddressSelect</td>
                        <td>{`(addressDetails : { `}
                            fullAddress: string,
                            <br />
                            streetAddress: string,
                            <br />
                            city: string,
                            <br />
                            state: string,
                            <br />
                            zipCode: string,
                            <br />
                            country: string
                            <br />
                            {`}) => void`}</td>
                        <td className='text-center'>-</td>
                        <td>Returns address details, after a new address has been selected from the place options.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>{`(fullAddress:string) => void`}</td>
                        <td className='text-center'>-</td>
                        <td>Returns the current input value.</td>
                    </tr>
                    <tr>
                        <td>Native attributes for the input element</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>"name", "id", "className"...</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Helpers</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>isGoogleApiUnavailable</td>
                        <td>{`() => boolean`}</td>
                        <td>
                            Check is google api unavailable.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default AddressAutocompleteDocs;