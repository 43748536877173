import React, { useState } from 'react';

import {
  Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormSection,
  ScrollableContainer, Checkbox, Alert, LinkWithIcon, Tooltip
} from '@jkhy/vsg-design-system';

const PageDisclosures = () => {
  const [clickedPdfLinks, setClickedPdfLinks] = useState({
    one: false,
    two: false,
    three: false
  });

  const onClickPdfLink = (e: any, linkNumber: 'one' | 'two' | 'three') => {
    if(clickedPdfLinks[linkNumber]){
      e.preventDefault();
    }
    
    setClickedPdfLinks({
      ...clickedPdfLinks,
      [linkNumber]: !clickedPdfLinks[linkNumber]
    })
  }

  const checkAllLinksClicked = () => {
    return clickedPdfLinks.one && clickedPdfLinks.two && clickedPdfLinks.three
  }
  console.log(clickedPdfLinks);
  return (
    <ReactAux>
      <Section
        title="Disclosures"
        headerText="Please review the disclosures."
      >
        <FormSection className="mb-3">
          <ScrollableContainer className="mb-3">
            <p className="mb-2">
              This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
              HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
            </p>
            <p>
              This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
              HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
            </p>
          </ScrollableContainer>
          <h4 className="mb-2">Select the links to open and review the disclosures.</h4>
          <LinkWithIcon
            icon={clickedPdfLinks.one ? IconsSolid.faFileCheck : IconsSolid.faFileAlt}
            iconColor={clickedPdfLinks.one ? 'success' : 'primary'}
            className="w-100 mb-1"
          >
            <a href="/dummy.pdf" target={"_blank"} onClick={(e) => onClickPdfLink(e, 'one')}>PDF Disclosure</a>
          </LinkWithIcon>
          <LinkWithIcon
            icon={clickedPdfLinks.two ? IconsSolid.faFileCheck : IconsSolid.faFileAlt}
            iconColor={clickedPdfLinks.two ? 'success' : 'primary'}
            className="w-100 mb-1">
            <a href="/dummy.pdf"  target={"_blank"} onClick={(e) => onClickPdfLink(e, 'two')}>Another PDF Disclosure</a>
          </LinkWithIcon>
          <LinkWithIcon
            icon={clickedPdfLinks.three ? IconsSolid.faFileCheck : IconsSolid.faFileAlt}
            iconColor={clickedPdfLinks.three ? 'success' : 'primary'}
            className="w-100"
          >
            <a href="/dummy.pdf"  target={"_blank"} onClick={(e) => onClickPdfLink(e, 'three')}>PDF Disclosure with a very very looong nam...</a>
          </LinkWithIcon>
        </FormSection>

        <FormSection className="mb-3">
          <h3 className="mb-2">Applicant Name</h3>
          <Tooltip title={checkAllLinksClicked() ? "" : "Review all PDf disclosure to enable checkbox."}>
            <div>
              <Checkbox htmlFor="accept" id="accept" name="accept" disabled={!checkAllLinksClicked()}>
                I have read and agree to the disclosures and terms above
              </Checkbox>
            </div>
          </Tooltip>
        </FormSection>
        <FormSection className="mb-3">
          <h3 className="mb-2">Co-Applicant Name</h3>
          <Tooltip title={checkAllLinksClicked() ? "" : "Review all PDf disclosure to enable checkbox."}>
            <div>
              <Checkbox htmlFor="accept2" id="accept2" name="accept2" disabled={!checkAllLinksClicked()}>
                I have read and agree to the disclosures and terms above
              </Checkbox>
            </div>
          </Tooltip>
        </FormSection>
        <Alert type="info" text="If you click each checkbox you will receive a PDF by e-mail." />
      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/10-1' }}>Continue</Button>
      </ActionsWrapper>
    </ReactAux >
  );
}

export default PageDisclosures;