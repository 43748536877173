import React from 'react';
import Highlight from 'react-highlight';
import { Alert, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const AlertDocs = () => {

	return <div>
		<h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Alert</h1>

		<h2 className='mb-2'>Description</h2>
		<p className='mb-4'>Provide contextual feedback messages for typical user actions with messages.</p>

		<h3 className='mb-3'>Alert types</h3>
		<p className='mb-2'>
			There are four type of alerts : "danger", "warning", "success", "info".
		</p>

		<div className='mb-3'>
			<Alert type="danger" text="Danger message here!" className="mb-2" />
			<Alert type="warning" text="Warning message here!" className="mb-2" />
			<Alert type="success" text="Success message here!" className="mb-2" />
			<Alert type="info" text="Info message here!" className="mb-2" />

			<Alert type="danger" text="Danger message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="warning" text="Warning message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="success" text="Success message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="info" text="Info message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />

		</div>

		<h3 className='mb-3'>Dismissible Alerts</h3>

		<div className='mb-2'>
			<Alert
				type="success"
				text="Success message here!"
				className="mb-2"
				dismissible
				onCloseAlert={() => console.log('alert closed')}
			/>
			<Alert
				type="danger"
				text="Danger message here!"
				className="mb-2"
				dismissible
				description="Some descriptive text here. Some descriptive text here. Some descriptive text here."
			/>
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';
import { Alert } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
	    	<Alert type="danger" text="Danger message here!" className="mb-2" />
			<Alert type="warning" text="Warning message here!" className="mb-2" />
			<Alert type="success" text="Success message here!" className="mb-2" />
			<Alert type="info" text="Info message here!" className="mb-2" />

			<Alert type="danger" text="Danger message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="warning" text="Warning message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="success" text="Success message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />
			<Alert type="info" text="Info message here!" className="mb-2" description="Some descriptive text here. Some descriptive text here. Some descriptive text here." />

			<Alert
				type="success"
				text="Success message here!"
				className="mb-2"
				dismissible
				onCloseAlert={() => console.log('alert dismissed')}
			/>
			<Alert
				type="danger"
				text="Danger message here!"
				className="mb-2"
				dismissible
				description="Some descriptive text here. Some descriptive text here. Some descriptive text here."
			/>
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>

		<table className="doc-table mb-3">
			<thead>
				<tr>
					<th colSpan={4}>Props</th>
				</tr>
				<tr>
					<td>
						<strong>Name</strong>
					</td>
					<td>
						<strong>Options</strong>
					</td>
					<td>
						<strong>Default</strong>
					</td>
					<td>
						<strong>Description</strong>
					</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>type</td>
					<td>"danger", "warning", "success", "info"</td>
					<td className='text-center'>-</td>
					<td>Set different style to the alert. This prop is <strong>required</strong>.</td>
				</tr>
				<tr>
					<td>text</td>
					<td>string</td>
					<td className='text-center'>-</td>
					<td>Set alert message.</td>
				</tr>
				<tr>
					<td><PropVersionTag type="new" version="4.3" />description</td>
					<td>string</td>
					<td className='text-center'>-</td>
					<td>Set alert message description.</td>
				</tr>
				<tr>
					<td><PropVersionTag type="new" version="4.3" />dismissible</td>
					<td>boolean</td>
					<td className='text-center'>-</td>
					<td>Set dismissible alert.</td>
				</tr>
				<tr>
					<td><PropVersionTag type="new" version="4.3" />dismissibleBtnAccessibility</td>
					<td>
						object with keys:<br />
						"title": string,<br />
						"ariaLabel": string
					</td>
					<td className='text-center'>-</td>
					<td>Set alert dismiss button aria-label and title.</td>
				</tr>
				<tr>
					<td><PropVersionTag type="new" version="4.3" />onCloseAlert</td>
					<td>
						function
					</td>
					<td className='text-center'>-</td>
					<td>Callback to execute when the Alert is dismissed.</td>
				</tr>
			</tbody>
		</table>
	</div>;
}

export default AlertDocs;