import React from 'react';

import { Section, InfoCard } from '@jkhy/vsg-design-system';

const CommericalRequestsPage = () => {
    return (
        <Section title="Commercial Loan Requests">
            <p className="mb-3">Below are some commercial loan opportunities you may apply for.  Each one has additional details and some may provide an immediate decision.</p>

            <InfoCard
                className="mb-3"
                mainLogo
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW', path: '/views' }} />
            
            <InfoCard
                className="mb-3"
                logo="https://pik-oplenac.com/wp-content/plugins/allsecure_woo/assets/images/light/unicredit.svg"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW', path: '/views' }} />

            <InfoCard
                className="mb-3"
                titles={['Line of Credit - Man United']}
                description="Unsecured loan application up to $100,000. No Collateral needed!"
                link={{ label: 'APPLY NOW', path: '/views' }} />

        </Section>
    );
}

export default CommericalRequestsPage;