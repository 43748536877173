import React, { Component } from 'react';

import { Section, FileUploadCard } from '@jkhy/vsg-design-system';

class RequestedPage extends Component {

    private fileUploadCardRef1 = null;
    private fileUploadCardRef2 = null;
    private fileUploadCardRef3 = null;

    handleOnSubmit = (ref: any, demoError?: boolean) => {
        // Get the files
        console.log(ref.state.files, ' onSubmit - files');

        // Simulated submit time
        setTimeout(() => {
            if (demoError) {
                // If error call this function with error message
                ref.onSubmitError('Internal Server Error please try again.');
            } else {
                // If success call this function
                ref.onSubmitSuccess();
            }
        }, 2000)
    }

    // Set custom Error messages
    getErrorMessage = (type: any, ref: any) => {
        switch (type) {
            case 'invalid name':
                return 'Invalid file name.'
            case 'exceeded maximum size':
                return `File exceeds maximum file size (${ref.props.maxFileSizeMB || 50}MB.)`;
            case 'invalid type':
                return 'Invalid file type.'
            default:
                return 'Failed to upload';
        }
    }

    handleOnChange = (files: any, ref: any) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.error) {
                const message = this.getErrorMessage(file.error, ref);

                // If file contains error call this function
                ref.onFileError(file, message);
            } else {
                // Simulated uploading with random time for upload.
                const time = +((Math.floor(Math.random() * 10) + 1) + '000');
                let progress = 0;

                const interval = setInterval(() => {
                    progress += 1;
                    ref.updateProgress(file, progress);
                }, time / 100)

                setTimeout(() => {
                    clearInterval(interval);

                    // If success call this function to set file success
                    ref.onFileSuccess(file);
                }, time)
            }
        }
    }

    render() {
        return (
            <Section title="Requested Documents">
                <p className="mb-3">The below documents have been requested to support a new or renewing loan or for ongoing needs.  The document title describes the document needed and which entity the document is required for.  If stated, the statement date or description will identity the statement period needed.  If a due date is listed, please attempt to provide the document by this date.  If the document cannot be returned by the due date, please contact us.</p>
                <FileUploadCard
                    className="mb-3"
                    title="Recurring document title goes here"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef1 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef1);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef1)}
                />

                <FileUploadCard
                    className="mb-3"
                    title="Recurring document with error on submit"
                    details={[
                        { title: 'Required for:', text: 'ADGASDFF HILL - LN 12093830483-298' },
                        { title: 'Statement Date:', text: '04/12/2019' },
                        { title: 'Description:', text: 'DATE, FREQ, GDAYS' },
                        { title: 'Due:', text: '04/14/2019' },
                    ]}
                    isMultiple
                    maxFileSizeMB={2}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef2 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef2);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef2, true)}
                />

                <FileUploadCard
                    cardErrorMessage="Reccuring Document was declined. Please upload a new file."
                    title="LV document"
                    details={[
                        { title: 'Declined on:', text: '15/10/2019', isInvalid: true },
                        { title: 'Required for:', text: 'FMDEF LS TEST CZAR' },
                        { title: 'Description:', text: 'This pre. doc will be sent to the Portal if the business party = TRUE.' },
                    ]}
                    removeOldErrorFiles
                    onRef={(ref: any) => (this.fileUploadCardRef3 = ref)}
                    onChange={(files: any) => {
                        console.log(files, ' onChange - files');
                        this.handleOnChange(files, this.fileUploadCardRef3);
                    }}
                    onSubmit={(files: any) => this.handleOnSubmit(this.fileUploadCardRef3)}
                />
            </Section>
        );
    }
}

export default RequestedPage;