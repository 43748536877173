import React from 'react';

import {
    Button, Section, ActionsWrapper, IconsSolid,
    ReactAux, FontAwesomeIcon
} from '@jkhy/vsg-design-system';

const PageSuccess = (props: any) => {
    const { onSuccess } = props;
    return (
        <ReactAux>
            <Section
            >
                <div className="text-center">
                    <FontAwesomeIcon className="text-success mb-2" icon={IconsSolid.faThumbsUp} size="l" />
                    <h1>Thank you</h1>
                    <h3 className="mb-3 text-color">Thank you for choosing our bank for your borrowing needs!</h3>
                </div>
                <p className="mb-2">While we review your loan selection, below are the steps you can expect:</p>
                <ul className="mb-2">
                    <li>Loan Documents will be uploaded to a secure location.</li>
                    <li>You will be notified via e-mail when your documents are ready for your review and digital signature.</li>
                    <li> We will be notified when you have successfully executed the documents.</li>
                    <li>Upon successful execution of your documents, we will deposit the funds into your Vanguard Bank Business checking account within 2 business days.</li>
                    <li> For a Line of Credit, funds will be made availablefor advance within 2 busoness days.</li>
                </ul>
                <p className="mb-2">Should you have any questions during this process, please contact us at 407-475-0005.</p>

                <p className='mb-2'>We appreciate your business!</p>


            </Section>

            <ActionsWrapper className="mt-2">
                <Button link={{ path: `/client-portal/renewals-page`, title: 'Back to Portal' }} onClick={onSuccess}>Back to Portal</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageSuccess;