import React from 'react';
import Highlight from 'react-highlight';

const pattern = '.m{direction}-{scale}';
const patternResponsive = '.m{direction}-{screen}-{scale}';
const SpacingUtilsDocs = () => (
  <div>
    <h1 className="mb-3">Spacing utility classes</h1>
    <p className="mb-1">Spacing between components and/or elements in VSG Design System is achieved by
    utility classes for margin.
    </p>
    <p className="mb-1">Consistent and gradual amounts of spacing are calculated
    with relation to <i>--html-font-size</i> and <i>--size-spacing</i> CSS variables. Therefore
      if any of them are modified, the spacing between the components throught the UIs will change, but
    the scaling will still be consistent. Any other spacing sizes would break the design system.</p>
    <p className="mb-1">The spacing utilities class names are constructed by a specific pattern: <b><i>{pattern}</i></b> .
    The starting 'm' stands for 'margin'.
    <br />
      The direction is a letter among:</p>
    <ul className="basic-list">
      <li>t - top</li>
      <li>b - bottom</li>
      <li>l - left</li>
      <li>r - right</li>
    </ul>
    <p className="mb-1">The scale is a value among:</p>
    <ul className="basic-list mb-5">
      <li>0 </li>
      <li>05 - stands for 0.5</li>
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
    </ul>

    <div className="mb-5 demo-spacing">
      <span className="ml-05">Margin left 0.5</span>
      <span className="ml-1">Margin left 1</span>
      <span className="ml-2">Margin left 2</span>
      <span className="ml-3">Margin left 3</span>
      <span className="ml-4">Margin left 4</span>
      <span className="ml-5">Margin left 5</span>
      <span className="ml-6">Margin left 6</span>
      <span className="ml-0">Margin left 0</span>
    </div>

    <div>
      <p className="mb-1">Padding utilities:</p>
      <ul className="basic-list">
        <li>px-0 - remove left and right padding</li>
      </ul>

      <div className="mb-5">
        <p style={{ padding: '16px', border: '1px solid black' }} className='px-0'>Padding 0</p>
      </div>
    </div>
    <Highlight className="React mb-4">
      {
        `
import React from 'react';

const Example = (props) => {
    return (
      // The colored borders in the showcase are only for demo purpose.
    <div>
      <span className="ml-05">Margin left 0.5</span>
      <span className="ml-1">Margin left 1</span>
      <span className="ml-2">Margin left 2</span>
      <span className="ml-3">Margin left 3</span>
      <span className="ml-4">Margin left 4</span>
      <span className="ml-5">Margin left 5</span>
      <span className="ml-6">Margin left 6</span>
      <span className="ml-0">Margin left 0</span>

      // padding utilities
      <p style={{padding: '16px', border:'1px solid black'}} className='px-0'>Padding 0</p>
    </div>
    );
}

export default Example; 
`
      }
    </Highlight>

    <h3 className="mb-3">Responsive spacing utiliies</h3>
    <p className="mb-1">The spacing utility class pattern can be extended with media queries
     support: <b><i>{patternResponsive}</i></b> .
     <br />
      The screen units are among:
    </p>
    <ul className="basic-list mb-5">
      <li>sm - Mobile screens</li>
      <li>md - Tablet screens</li>
      <li>lg - Desktop screens</li>
    </ul>
    <p className="mb-1">Consider the following example:</p>
    <Highlight className="React mb-1">
      {
        `<span className="mt-sm-3 mb-lg-2 mb-sm-3">Demo</span>`
      }
    </Highlight>
    <p>The span element will have margin top by scale 3 always, in any screen size, because the <i>sm</i> points
    this utility applies from small screens up until another and stronger rule is found. However, the bottom margin will
    be by scale 3 until a large, <i>lg</i> screen size is reached - then the margin bottom will be by scale 2.</p>
  </div>
)

export default SpacingUtilsDocs;