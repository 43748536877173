import React from 'react';
import Highlight from 'react-highlight';
import { Container } from '@jkhy/vsg-design-system';


const ContainerDocs = () => {
    return (
        <div>
            <h1 className="mb-3">Container</h1>

            <h2 className="mb-2">Description</h2>
            <p className="mb-2">
                Container component centers your content horizontally. It's the most basic layout element.
            </p>

            <div className="mb-2" style={{ backgroundColor: 'black' }}>
                <Container>
                    <div style={{ backgroundColor: '#e2f1f3', paddingTop: '40px', paddingBottom: '40px', height: '200px' }} />
                </Container>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Container } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
        <div className="mb-2" style={{ backgroundColor: 'black' }}>
            <Container>
                <div style={{ backgroundColor: '#e2f1f3', paddingTop: '40px', paddingBottom: '40px', height: '200px' }} />
            </Container>
        </div>
    );
}

export default Example; 
`
                }
            </Highlight>
        </div>
    )
}

export default ContainerDocs;
