import React from 'react';
import { Link } from 'react-router-dom';

const DemoPagesDocs = () => (
  <div>
    <h1 className="mb-3">Demo Pages</h1>
    <p><Link to="/views" target="_blink">Quick Decision</Link></p>
    <p><Link to="/client-portal" target="_blink">Client Portal</Link></p>
    <p><Link to="/renewals" target="_blank">Renewals</Link></p>
  </div>
);
export default DemoPagesDocs;