import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';

const LayoutQuickDecisionDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Layout Quick Decision</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">There is a specific UI structure for <strong>Quick Decision</strong> and the design system provides the necessary building blocks for it. It is crucial to implement strictly the showcased structure of layout components.</p>

            <h5 className="mb-2"><Link to="/docs/demo-pages">Demo Pages</Link></h5>

            <Highlight className="React mb-4">
                {
                    `
import React, { Component } from 'react';
import { ReactAux, MainContainer, Header, Footer, NavSteps, Fab, Tooltip } from '@jkhy/vsg-design-system';

class Example extends Component {
    render() {

        const header = <Header
            links={[
                { text: 'FAQs', path: '#', icon: IconsSolid.faComments },
                { text: 'Sign Out', path: '#', icon: IconsSolid.faSignOutAlt }
            ]} />;

        const navSteps = <NavSteps steps={...} />;

        const footer = <Footer
            imgLeft={src:'...path/img/footer/footer-left.svg', alt:'BBB logo'}
            imgCenter={src:'...path/img/footer/footer-center.svg', alt:'EHO logo'}
            imgRight={src:'...path/img/footer/footer-right.svg', alt:'FEDIC logo'}
            topText='All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review.'
            bottomText='© Copyright 2020. Powered by Jack Henry'
            version="Version 1.22.33.44"
        />

        return (
            <ReactAux>
                <MainContainer
                    header={header}
                    leftSideElement={navSteps}
                    footer={footer}
                >
                    <Switch>
                        <Route path="..." component={Page} />
                        <Route path="..." component={Page2} />
                        <Route path="..." component={Page3} />
                    </Switch>
                </MainContainer>

                <Fab ... />
            </ReactAux>
        );
    }
}

export default Example; 
    `
                }
            </Highlight>
        </div>
    )
}

export default LayoutQuickDecisionDocs;