import React from 'react';
import Highlight from 'react-highlight';
import { LinkWithIcon, IconsSolid, FontAwesomeIcon, } from '@jkhy/vsg-design-system';

const LinkWithIconDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Link With Icon</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>This component renders link with icon.</p>

            <div className='mb-2'>
                <LinkWithIcon icon={IconsSolid.faPlus} >
                    <a href="/" >Link with Icon</a>
                </LinkWithIcon>

                <LinkWithIcon icon={IconsSolid.faPlus} iconColor="success">
                    <a href="/" >Link with Icon</a>
                </LinkWithIcon>

                <LinkWithIcon icon={IconsSolid.faPlus} disabled>
                    <a href="/" >Link with Icon Disabled</a>
                </LinkWithIcon>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { LinkWithIcon, IconsSolid } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <LinkWithIcon icon={IconsSolid.faPlus} >
             <a href="/" >Link with Icon</a>
         </LinkWithIcon>

         <LinkWithIcon icon={IconsSolid.faPlus} iconColor="success">
             <a href="/" >Link with Icon</a>
         </LinkWithIcon>

        <LinkWithIcon icon={IconsSolid.faPlus} disabled>
            <a href="/" >Link with Icon Disabled</a>
        </LinkWithIcon>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>iconDefinition</td>
                        <td className='text-center'>-</td>
                        <td>Set the icon.</td>
                    </tr>
                    <tr>
                        <td>iconColor</td>
                        <td className='text-nowrap'>'primary' | 'success' | 'warning' | 'danger'</td>
                        <td className='text-center'>'primary'</td>
                        <td>Set the icon color.</td>
                    </tr>
                    <tr>
                        <td>disabled</td>
                        <td>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Set disabled styles to link.</td>
                    </tr>
                    <tr>
                        <td>className</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set class name to link wrapper.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default LinkWithIconDocs;