import React, { useEffect, useState, useRef } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Input, DataPreviewText,
    Alert, InfoCard, FormSection, Loader,
} from '@jkhy/vsg-design-system';

const PageStatus = () => {
    const userEmail = 'jeffreyhinman@dd.com';
    const inputRefs = useRef<HTMLInputElement>();
    const [showInput, setShowInput] = useState(false);
    const [email, setEmail] = useState(userEmail);
    const [updatedEmail, setUpdatedEmail] = useState(userEmail);
    const [isLoading, setIsLoading] = useState(false);
    const [cardSuccess, setCardSuccess] = useState([false, false]);
    const [cardError, setCardError] = useState([false, false]);
    const [emailError, setEmailError] = useState(false);


    const saveEmail = () => {
        if (showInput) {
            // sending request to save e-mail
            setIsLoading(true);
            setTimeout(() => {
                const isError = Math.floor(Math.random() * 2);
                if (showInput) setIsLoading(false);
                // success request. E-mail is saved
                if (!isError) {
                    if (emailError) setEmailError(false)
                    setShowInput(false);
                    setUpdatedEmail(email);
                }

                // error request. E-mail not saved
                if (isError) setEmailError(true);
            }, 2000);
        } else {
            setShowInput(true);
        }
    }

    const resendEmail = (index: any) => {
        // send request for resend e-mail
        setTimeout(() => {
            const isError = Math.floor(Math.random() * 2);
            if (!isError) {
                // success resend e-mail
                const copyCardSuccess = [...cardSuccess];
                copyCardSuccess[index] = true;
                const copyCardError = [...cardError];
                copyCardError[index] = false;
                setCardSuccess(copyCardSuccess);
                setCardError(copyCardError);
            } else {
                const copyCardError = [...cardError];
                copyCardError[index] = true;
                setCardError(copyCardError);
            }
        }, 2000);
    }

    useEffect(() => {
        const isCardSucces = cardSuccess.find(el => el === true);
        if (isCardSucces) {
            const copyCardSuccess = [...cardSuccess];
            setCardSuccess(copyCardSuccess.map(el => false));
        }
    }, [cardSuccess]);

    useEffect(() => {
        if (showInput) {
            // focus and select input on show
            const currentInput = inputRefs.current as any;
            currentInput.current.focus();
            currentInput.current.select();
        }

    }, [showInput])

    return (
        <ReactAux>
            <Section
                title="Status"
                headerText="Header Text: Welcome to the Loan Request Status page where you can check the application completio on other parties in the loan request."
                footerText="Footer Text: Please let co-applicant(s) know th check their spam or junk folder for the applicant invitation."
            >
                <p className="text-muted mb-05">• You may edit on e-mail address by clicking ‘Edit e-mail’ and then by typing the correct e-mail address</p>
                <p className="text-muted mb-3">• Click on ‘Resend e-mail’ to send a new link to the additional owner(s)</p>
                <p className="text-muted mb-3">Once the owner(s) have submitted their information, a ‘Completde’ status will appear.</p>
                <InfoCard
                    className="mb-3"
                    successState={{
                        show: cardSuccess[0],
                        displayMilliseconds: 3000,
                        content: {
                            description: `E-mail to ${email} was successfully sent!`,
                            icon: IconsSolid.faPaperPlane,
                        },
                        isError: cardError[0]
                    }}
                    button={{
                        label: 'Resend E-mail',
                        icon: IconsSolid.faRedo,
                        onClicked: () => resendEmail(0),
                        disabled: showInput
                    }}
                >
                    <DataPreviewText className="mb-1" isInline label="Name:" text="Jeffrey Hinman" />
                    <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
                    <DataPreviewText className="mb-2" isInline label="Response Date:" text="N/A" />

                    <FormSection className="mb-2">
                        {!showInput && <DataPreviewText className="mb-1" isInline label="Email:" text={email} />}
                        {showInput &&
                            <FormGroup className="mb-2" htmlFor="email" isRequired label="E-mail Address">
                                <Input ref={inputRefs} type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </FormGroup>
                        }
                        {
                            showInput && emailError && <Alert className="mb-2" type="danger" text="Email not saved!" />
                        }
                        <ActionsWrapper
                            {...isLoading ? { alignCenter: true } : { alignLeft: true }}
                            removePadding
                            removeBg
                        >
                            {!isLoading && <Button
                                btnType={!showInput ? "secondary" : 'primary'}
                                iconLeft={IconsSolid.faSave}
                                onClick={saveEmail}
                                type="button"
                                className={showInput ? "action-spacer" : ''}
                            >
                                {!showInput ? "Update e-mail address" : "Save e-mail address"}
                            </Button>}
                            {showInput && !isLoading &&
                                <Button
                                    btnType="secondary"
                                    onClick={() => {
                                        if (updatedEmail !== email) {
                                            setEmail(updatedEmail);
                                        }
                                        if (emailError) {
                                            setEmailError(false);
                                        }
                                        setShowInput(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            }

                            {isLoading && <Loader isSmall />}
                        </ActionsWrapper>

                    </FormSection>
                    <Alert className="mb-2" type="info" text="Application started" />
                    {cardError[0] && <Alert className="mb-2" type="danger" text="Email not resent!" />}
                </InfoCard>

                <InfoCard
                    className="mb-3"
                    successState={{
                        show: cardSuccess[1],
                        content: {
                            description: 'Your E-mail was resend!',
                            icon: IconsSolid.faPaperPlane,
                            title: "Success"
                        },
                        isError: cardError[1]
                    }}
                    button={{
                        label: 'Resend E-mail',
                        icon: IconsSolid.faRedo,
                        onClicked: () => resendEmail(1)
                    }}
                >
                    <DataPreviewText className="mb-1" isInline label="Name:" text="Steven Horton" />
                    <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
                    <DataPreviewText className="mb-2" isInline label="Response Date:" text="02/08/2021" />
                    <Alert className="mb-2" type="success" text="Completed" />
                    {cardError[1] && <Alert className="mb-2" type="danger" text="Email not resent!" />}
                </InfoCard>

                <InfoCard
                    className="mb-3"
                    button={{
                        label: 'Resend E-mail', icon: IconsSolid.faRedo, onClicked: () => { console.log('Clicked'); }
                    }}
                >
                    <DataPreviewText className="mb-1" isInline label="Name:" text="Douglas Cuney" />
                    <DataPreviewText className="mb-1" isInline label="Request Date:" text="02/08/2021" />
                    <DataPreviewText className="mb-2" isInline label="Response Date:" text="02/08/2021" />
                    <FormSection className="mb-2">
                        <FormGroup className="mb-2" htmlFor="email2" isRequired label="E-mail Address">
                            <Input type="email" name="email2" id="email2" />
                        </FormGroup>
                        <Button btnType="secondary" iconLeft={IconsSolid.faSave}>Update e-mail address</Button>
                    </FormSection>
                    <Alert className="mb-2" type="danger" text="Invitation rejected" />
                </InfoCard>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/14' }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageStatus;