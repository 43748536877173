import React from 'react';
import Highlight from 'react-highlight';
import { Button, ActionsWrapper, LinkWithIcon, IconsSolid, FontAwesomeIcon, } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const ActionsWrapperDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Actions wrapper</h1>
            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>A wrapper that separates content and emphasises on key actions.</p>
            <p className='mb-4'><strong>Note:</strong> use "action-spacer" class on actions when you want to render them one next to other on desktop. Use "mb-2" when you want to render them one under the another.</p>

            <div className='mb-2'>
                <ActionsWrapper>
                    <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
                    <Button iconLeft={IconsSolid.faSave}>Save</Button>
                </ActionsWrapper>
            </div>

            <div className='mb-2'>
                <ActionsWrapper>
                    <Button btnType='secondary' className="w-50 action-spacer" iconLeft={IconsSolid.faChevronLeft}>Previous step</Button>
                    <Button className="w-50" iconRight={IconsSolid.faChevronRight}>Next step</Button>
                </ActionsWrapper>
            </div>

            <div className='mb-2'>
                <ActionsWrapper alignLeft>
                    <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
                    <Button iconLeft={IconsSolid.faSave}>Save</Button>
                </ActionsWrapper>
            </div>

            <div className='mb-2'>
                <ActionsWrapper directionCol >
                    <Button className="mb-2" iconLeft={IconsSolid.faCheck}>I am here</Button>
                    <Button btnType='secondary' iconLeft={IconsSolid.faSignOutAlt}>Sign out now</Button>
                </ActionsWrapper>
            </div>

            <div className='mb-2'>
                <ActionsWrapper >
                    <Button iconRight={IconsSolid.faChevronRight}>Continue</Button>
                </ActionsWrapper>
            </div>


            <div className='mb-2'>
                <ActionsWrapper alignLeft narrow>
                    <LinkWithIcon icon={IconsSolid.faPen} >
                        <a href="/" >Go to borrower step to edit</a>
                    </LinkWithIcon>
                </ActionsWrapper>
            </div>

            <div className='mb-2'>
                <ActionsWrapper removePadding removeBg>
                    <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
                    <Button iconLeft={IconsSolid.faSave}>Save</Button>
                </ActionsWrapper>
            </div>


            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Button, ActionsWrapper } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
       // Default align right with space between
       <ActionsWrapper>
        <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
        <Button iconLeft={IconsSolid.faSave}>Save</Button>
       </ActionsWrapper>

        // Actions with 50% width
        <ActionsWrapper>
            <Button btnType='secondary' className="w-50 action-spacer" iconLeft={IconsSolid.faChevronLeft}>Previous step</Button>
            <Button className="w-50" iconRight={IconsSolid.faChevronRight}>Next step</Button>
        </ActionsWrapper>

        // Align left with space between
        <ActionsWrapper alignLeft>
            <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
            <Button iconLeft={IconsSolid.faSave}>Save</Button>
        </ActionsWrapper>


        // Actions with width 100%
        <ActionsWrapper directionCol >
            <Button className="mb-2" iconLeft={IconsSolid.faCheck}>I am here</Button>
            <Button btnType='secondary' iconLeft={IconsSolid.faSignOutAlt}>Sign out now</Button>
        </ActionsWrapper>

        // Single action right
        <ActionsWrapper >
            <Button iconRight={IconsSolid.faChevronRight}>Continue</Button>
         </ActionsWrapper>

        // Single with link
        <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
                <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
        </ActionsWrapper>

        // Default align right with space between, no background and padding
        <ActionsWrapper removePadding removeBg>
            <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimesCircle}>Cancel</Button>
            <Button iconLeft={IconsSolid.faSave}>Save</Button>
        </ActionsWrapper>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" />alignLeft</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Align content to left side of the wrapper.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2"/>alignCenter</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Align content to center of the wrapper.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />narrow</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set style for the Action wrapper when links placed in it.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />directionCol</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Align actions one under the another.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2"/>removePadding</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Remove padding from the wrapper.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2"/>removeBg</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set background to transparent.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />className</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set class name to the wrapper.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ActionsWrapperDocs;