import React from 'react';

import {
  Button, Section, FormGroup, ActionsWrapper, Radio, IconsSolid,
  ReactAux, Select, MaskedInput, Input, Datepicker, DataPreviewText,
} from '@jkhy/vsg-design-system';

const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const PagePersonalDetailsFilled = () => {
  return (
    <ReactAux>
      <Section
        title="Personal Details"
        headerText="Header Text: If we recognized you, some information will already be filled in for you. Please verify for accuracy and make changes where needed."
        footerText="Footer Text: Please continue to the next step."
      >
        <DataPreviewText className="mb-2" label="First Name:" text="Jane" />
        <FormGroup className="mb-2" htmlFor="middleName" isRequired label="Middle Name">
          <Input type="text" name="middleName" id="middleName" />
        </FormGroup>
        <DataPreviewText className="mb-2" label="Last Name:" text="Doe" />
        <DataPreviewText className="mb-2" label="Social Security Number:" text="***_**-1234" />
        <FormGroup className="mb-2" htmlFor="maskPhone" isRequired label="Mobile Phone">
          <MaskedInput
            id="maskPhone"
            type="phone"
            mask={usPhoneCountryCodeMask}
            icon={IconsSolid.faPhoneAlt}
          />
        </FormGroup>
        <DataPreviewText className="mb-2" label="Email Address:" text="jane.doe@mailinator.com" />
        <DataPreviewText className="mb-2" label="Date of Birth:" text="1/12/1980" />
        <FormGroup className="mb-2" htmlFor="select" isRequired label="What is your US Citizenship status?">
          <Select
            id="select"
            options={[
              { value: '1', label: 'US Citizen' },
              { value: '2', label: 'Resident Alien' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="idNumber" isRequired label="ID Number">
          <Input type="number" name="idNumber" id="idNumber" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="dpDef2" isRequired label="ID Issue Date">
          <Datepicker id="dpDef2" name="dpDef2" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="maskPercent" isRequired label="What is your ownership % of the business?">
          <MaskedInput
            id="maskPercent"
            type='percent'
            icon={IconsSolid.faPercent}
          />
        </FormGroup>
        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Have you ever served, or are you currently serving, in the United States Armed Forces?">
          <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
          <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
        </FormGroup>
      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/8' }}>Next step</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PagePersonalDetailsFilled;