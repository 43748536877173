import React from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid, ReactAux } from '@jkhy/vsg-design-system';

const ReactAuxDocs = () => {

    return <div>
        <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> ReactAux</h1>

        <h2 className='mb-2'>Description</h2>
        <p className='mb-4'>A self-eradicating component for rendering multiple elements.</p>

        <div className='mb-2'>
            <ReactAux>
                <p>Hello, World!</p>
                <p>I am a demo for ReactAux.</p>
            </ReactAux>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { ReactAux } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <ReactAux>
        <p>Hello, World!</p>
        <p>I am a demo for ReactAux.</p>
    </ReactAux>
    );
}
export default Example;
    `}
        </Highlight>

        <Highlight className="HTML">
            {`
//Html render will be
<p>Hello, World!</p>
<p>I am a demo for ReactAux.</p>
            `}
        </Highlight>

    </div>;
}

export default ReactAuxDocs;
