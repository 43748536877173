
import React, {useState} from 'react';
import Highlight from 'react-highlight';
import { Select, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import DemoWrapper from './DemoWrapper';
import DesignGuidelinesLink from '../DesignGuidelinesLink/DesignGuidelinesLink';

const SelectDocs = () => {
    const [selectOne, setSelectOne] = useState('');
    const [selectTwo, setSelectTwo] = useState('strawberry');
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Select</h1>

            <p className='mb-4'><strong style={{ color: 'var(--color-danger)' }}>IMPORTANT:</strong> Add prop <strong>dsSelectWrapper</strong> to the component which uses and return <strong>Select</strong> component. Form Group component add some aditional props to
             Select Component, so you need to spread the props from your wrapper component to Select component..</p>

             <div className='mb-2'>
            <FormGroup label="Candy Options" htmlFor="candySelect" className="mb-2" >
                <DemoWrapper
                    dsSelectWrapper
                    id="candySelect"
                    placeholder="Select..."
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                    value={selectOne}
                    onChange={(obj: any) => setSelectOne(obj.value)}
                />
            </FormGroup>

            <FormGroup label="Candy Option 2" className="mb-2" htmlFor="candySelectTwo">
                <Select
                    id="candySelectTwo"
                    value={selectTwo}
                    placeholder="Select..."
                    onChange={(obj: any) => setSelectTwo(obj.value)}
                    options={[
                        { value: 'chocolate', label: 'Chocolate' },
                        { value: 'strawberry', label: 'Strawberry' },
                        { value: 'vanilla', label: 'Vanilla' }]}
                />
            </FormGroup>
            <FormGroup label="Candy Loading" className="mb-2" htmlFor="candyLoading">
                <Select
                    id="candyLoading"
                    isLoading
                    options={[]}
                    text={'Chocolate'}
                    value={'optionOne'}
                    noOptionsMessage={'No options ! Loading...'}
                />
            </FormGroup>
            <FormGroup label="Candy Disabled" className="mb-2" htmlFor="candyDisabled">
                <Select
                    id="candyDisabled"
                    placeholder="Select..."
                    options={[
                        { value: 'chocolate', label: 'Chocolate' }]}
                    disabled
                />
            </FormGroup>
        </div>

        <DesignGuidelinesLink link="/docs/radio-vs-select" />

        <Highlight className="React mb-4">
            {
                `
import React, { useState } from 'react';
import { Select, FormGroup } from '@jkhy/vsg-design-system';
import DemoWrapper from './DemoWrapper';

const Example = (props) => {
    const [selectOne, setSelectOne] = useState('');
    const [selectTwo, setSelectTwo] = useState('strawberry');
    return (
    <div>
        <FormGroup label="Candy Options" htmlFor="candySelect" className="mb-2" >
            <DemoWrapper
                dsSelectWrapper
                id="candySelect"
                placeholder="Select..."
                options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }]}
                value={selectOne}
                onChange={(obj: any) => setSelectOne(obj.value)}
            />
        </FormGroup>

        <FormGroup label="Candy Option 2" className="mb-2" htmlFor="candySelectTwo">
            <Select
                id="candySelectTwo"
                value={selectTwo}
                placeholder="Select..."
                onChange={(obj:any)=> setSelectTwo(obj.value)}
                options={[
                    { value: 'chocolate', label: 'Chocolate' },
                    { value: 'strawberry', label: 'Strawberry' },
                    { value: 'vanilla', label: 'Vanilla' }]}
            />
        </FormGroup>
        <FormGroup label="Candy Loading" className="mb-2" htmlFor="candyLoading">
            <Select
                id="candyLoading"
                isLoading
                options={[]}
                text={'Chocolate'}
                value={'optionOne'}
                noOptionsMessage={'No options ! Loading...'}
            />
        </FormGroup>
        <FormGroup label="Candy Disabled" className="mb-2" htmlFor="candyDisabled">
            <Select
                id="candyDisabled"
                placeholder="Select..."
                options={[
                    { value: 'chocolate', label: 'Chocolate' }]}
                disabled
            />
        </FormGroup>
    </div>
    );
}

export default Example;

// Base Example for Select Wrapper
import React from 'react';
import { Select } from '@jkhy/vsg-design-system';

const DemoWrapper = (props: any) => {

    return (
            <Select
                {...props}
            />
    );
}

export default DemoWrapper;
`
            }
        </Highlight>
        <p className="mb-1">Select has React properties which are prefixed with an underscore. <strong style={{ color: 'var(--color-warning)' }}>They must not be taken as public API.</strong></p>
        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>options</td>
                    <td>array of objects.<br />
                        object must have props: <strong>"value","label"</strong>
                    </td>
                    <td className='text-center'>-</td>
                    <td>Set select value and options.</td>
                </tr>
                <tr>
                    <td>isLoading</td>
                    <td>boolean</td>
                    <td>false</td>
                    <td>Indicates if the select should be displayed in loading state.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" />id</td>
                    <td>string</td>
                    <td className='text-center'>-</td>
                    <td>due to WCAG 2.0 and focus form control in form group on label click.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" />noOptionsMessage</td>
                    <td>string</td>
                    <td>"No options"</td>
                    <td>Set select menu, no options text.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" />text</td>
                    <td>string</td>
                    <td className='text-center'>-</td>
                    <td>Text, which is displayed as label of the current value, when there is a value, but the options array is empty.</td>
                </tr>
                <tr>
                    <td><PropVersionTag type="new" />menuPlacement</td>
                    <td>'auto' || 'top' || 'bottom'</td>
                    <td >"auto"</td>
                    <td>Set when opened menu placement.</td>
                </tr>
                <tr>
                    <td>some select native attr</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>"name", "id", "className"...</td>
                </tr>
            </tbody>
        </table>

        </div>
    )
}

export default SelectDocs;