import React from 'react';
import Highlight from 'react-highlight';
import { Input, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const InputDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Input</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The Input component extends the native input element.</p>

            <div className='mb-2'>
            <FormGroup label="Name" htmlFor="name" className="mb-2">
                <Input type="text" name="name" id="name" />
            </FormGroup>
            <FormGroup label="Age" htmlFor="age" className="mb-2">
                <Input type="number" name="age" id="age" />
            </FormGroup>
            <FormGroup label="Password" htmlFor="password" className="mb-2">
                <Input type="password" name="pass" id="password" />
            </FormGroup>
            <FormGroup label="Disabled Input" htmlFor="disabledText" className="mb-2">
                <Input type="text" name="name" placeholder="Placeholder" disabled id="disabledText" />
            </FormGroup>
        </div>

        <Highlight className="React mb-4">
            {
                `
import React from 'react';
import { Input, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
          <FormGroup label="Name" htmlFor="name" className="mb-2">
                <Input type="text" name="name" id="name"/>
            </FormGroup>
            <FormGroup label="Age" htmlFor="age" className="mb-2">
                <Input type="number" name="age" id="age"/>
            </FormGroup>
            <FormGroup label="Password" htmlFor="password" className="mb-2">
                <Input type="password" name="pass" id="password"/>
            </FormGroup>
            <FormGroup label="Disabled Input" htmlFor="disabledText" className="mb-2">
                <Input type="text" name="name" placeholder="Placeholder" disabled id="disabledText"/>
            </FormGroup>
    </div>
    );
}

export default Example; 
`
            }
        </Highlight>

        <table className="doc-table mb-3">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><PropVersionTag type="new" />id</td>
                    <td>string</td>
                    <td className='text-center'>-</td>
                    <td>due to WCAG 2.0 and focus form control in form group on label click.</td>
                </tr>
                <tr>
                    <td>all native input attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native input attributes like: "type", "disabled", "name"...</td>
                </tr>
            </tbody>
        </table>

        </div>
    )
}

export default InputDocs;