import React, { Component } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import {
  ReactAux, MainContainer, Header, NavMenu, Footer, LinkWithIcon,
  IconsSolid, ActionsWrapper, Button,
} from '@jkhy/vsg-design-system';

import HomePage from './ClientPortalPages/HomePage';
import RequestedPage from './ClientPortalPages/RequestedPage';
import RequestedHistoryPage from './ClientPortalPages/RequestedHistoryPage';
import CommercialRequestsPage from './ClientPortalPages/CommercialRequestsPage';
import ConsumerRequestsPage from './ClientPortalPages/ConsumerRequestsPage';
import RenewalsPage from './ClientPortalPages/RenewalsPage';
import LoanHistoryPage from './ClientPortalPages/LoanHistoryPage';
import CurrentAplicationsPage from './ClientPortalPages/CurrentAplicationsPage';

import bannerImg from './img/banner.jpeg';
import bannerTablet from './img/bannerTablet.png';

class ClientPortal extends Component<{ location: any }> {

  state = {
    navMenuVisible: false,
    accountVisible: false,
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if ((prevState.navMenuVisible !== this.state.navMenuVisible) || (prevState.accountVisible !== this.state.accountVisible)) {
      return;
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  handleOpenMenu = () => {
    this.setState({ navMenuVisible: true });
  }

  handleCloseMenu = () => {
    this.setState({ navMenuVisible: false });
  }

  handleOpenAccount = () => {
    this.setState({ accountVisible: true });
  }

  handleCloseAccount = () => {
    this.setState({ accountVisible: false });
  }

  render() {

    const currentPathname = this.props.location.pathname;

    const navMenu = <NavMenu
      onLinkClick={this.handleCloseMenu}
      sections={[
        {
          links: [
            { title: 'HOME', path: '/client-portal', isActive: currentPathname === '/client-portal' }
          ]
        },
        {
          title: 'Requested Documents',
          links: [
            { title: 'REQUESTED Document Long Content', path: '/client-portal/requested-page', number: 6, isActive: currentPathname === '/client-portal/requested-page' },
            { title: 'HISTORY', path: '/client-portal/requested-history-page', isActive: currentPathname === '/client-portal/requested-history-page' },
          ]
        },
        {
          title: 'Loan Applications',
          links: [
            { title: 'COMMERCIAL REQUESTS', path: '/client-portal/commercial-request-page', isActive: currentPathname === '/client-portal/commercial-request-page' },
            { title: 'CONSUMER REQUESTS', path: '/client-portal/consumer-request-page', isActive: currentPathname === '/client-portal/consumer-request-page' },
            { title: 'RENEWALS', path: '/client-portal/renewals-page', number: 14, isActive: currentPathname === '/client-portal/renewals-page' },
            { title: 'HISTORY', path: '/client-portal/loan-history-page', isActive: currentPathname === '/client-portal/loan-history-page' },
            { title: 'CURRENT APLICATIONS', path: '/client-portal/current-aplications-page', number: 1, isActive: currentPathname === '/client-portal/current-aplications-page' },
          ]
        }
      ]} />;

    const header = <Header
      accountBtn={{
        content: <>
          <h3 className="mb-2">Account</h3>
          <p className="mb-1">John Preston</p>
          <p className="mb-2">webuser35@mailinator.com</p>
          <LinkWithIcon className="mb-3" icon={IconsSolid.faLockAlt}>
            <Link to="/client-portal">
              Change password
            </Link>
          </LinkWithIcon>
          <ActionsWrapper directionCol>
            <Button btnType="secondary" iconLeft={IconsSolid.faSignOutAlt}>Sign out now</Button>
          </ActionsWrapper>
        </>,
        isVisible: this.state.accountVisible,
        onOpen: this.handleOpenAccount,
        onClose: this.handleCloseAccount,
      }}
      logoSimpleLink={{ path: '/client-portal' }}
      logoAccessibility={{
        title: 'Home',
        ariaLabel: 'Home link'
      }}
      navMenu={navMenu}
      isMenuVisible={this.state.navMenuVisible}
      onMenuOpen={this.handleOpenMenu}
      onMenuClose={this.handleCloseMenu}
    />;

    const bannerProps = {
      desktopImg :bannerImg,
      tabletImg : bannerTablet,
      mobileImg : bannerImg
    }

    const footer = <Footer
      topText='All users of our online services are subject to our Privacy Statement and agree to be bound by the Terms of Service. Please review.'
      bottomText='© Copyright 2020. Powered by Jack Henry'
      version="Version 1.22.33.44"
    />;

    const isCurrentAplicationsPage = currentPathname === '/client-portal/current-aplications-page';
    return (
      <ReactAux>
        <MainContainer
          header={header}
          leftSideElement={navMenu}
          isClientPortal
          bannerProps = {isCurrentAplicationsPage ? undefined : bannerProps}
          footer={footer}
        >
          <Switch>
            <Route path="/client-portal/current-aplications-page" component={CurrentAplicationsPage} />
            <Route path="/client-portal/requested-page" component={RequestedPage} />
            <Route path="/client-portal/requested-history-page" component={RequestedHistoryPage} />
            <Route path="/client-portal/commercial-request-page" component={CommercialRequestsPage} />
            <Route path="/client-portal/consumer-request-page" component={ConsumerRequestsPage} />
            <Route path="/client-portal/renewals-page" component={RenewalsPage} />
            <Route path="/client-portal/loan-history-page" component={LoanHistoryPage} />
            <Route path="/client-portal" component={HomePage} />
          </Switch>
        </MainContainer>

      </ReactAux>
    );
  }
}

export default ClientPortal;