import React, { useState } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio, Select,
    ScrollableContainer, Checkbox, Collapse
} from '@jkhy/vsg-design-system';

const PageWelcome = () => {
    const [collapse, setCollapse] = useState(false);
    return (
        <ReactAux>
            <Section title="Let’s get Started" footerText="Please continue to the next step.">
                <h4 className="mb-3">Thank you for starting a loan application with us. Please review the items below before continuing.</h4>
                <h3 className="mb-1">Declarations</h3>
                <h5 className="mb-2 text-muted">Please answer the questions below.</h5>
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you agree to have your credit card pulled?">
                    <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
                </FormGroup>
                <FormGroup className="mb-3" htmlFor="select" label="Location of Businesss" isBold>
                    <Select
                        id="select"
                        placeholder="Select..."
                        options={[
                            { value: 'AK', label: 'AK' },
                            { value: 'KK', label: 'KK' },
                            { value: 'MK', label: 'MK' }]}
                    />
                </FormGroup>
                <h3 className="mb-1">Disclosures</h3>
                <h5 className="mb-1 text-muted">Please review the below disclosures.</h5>
                <ScrollableContainer className="mb-1">
                    <p className="mb-2">
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                    <p>
                        This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant, they would combine into one with HTML text
                        HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
                    </p>
                </ScrollableContainer>
                <Checkbox htmlFor="accept" id="accept" name="accept">
                    I acknowledge that I have received and viewed these documents.
                </Checkbox>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/2', title: 'Continue' }}>Continue</Button>
            </ActionsWrapper>

            <Collapse
                className="mt-1 mb-0"
                title="Disclosures"
                expanded={collapse}
                onClick={() => setCollapse(!collapse)}
                dataUI="Disclosures"
                simple
                smallText
            >
                <p>When you apply for credit, your lender may calculate your debt-to-income (DTI) based on verified income and debt amounts, and the result may differ from the results shown on the page.</p>
            </Collapse>
        </ReactAux>
    );
}

export default PageWelcome;