import React, { useState } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio,
    Input, MaskedInput, Select,
} from '@jkhy/vsg-design-system';

const CoApplicantsStepOne = () => {
    const [invation, setInvation] = useState<string | null>(null);

    return (
        <ReactAux>
            <Section
                title="Co-Applicants"
                headerText="Header text: Would you like to a add a party to the application?"
                footerText="Footer text: Please continue to the next step."
            >
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Co-Applicant Type">
                    <Radio className="d-inline-block" htmlFor="yes" id="yes" name="question" value="yes">Individual</Radio>
                    <Radio className="d-inline-block" htmlFor="no" id="no" name="question" value="no">Business</Radio>
                </FormGroup>
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you want to add your co-applicant information now or invite Personal Details them via email to fill out their portion of the application?">
                    <Radio className="d-inline-block" htmlFor="yes2" id="yes2" name="question2" value="yes2" onClick={() => setInvation('email')}>Email Invitation</Radio>
                    <Radio className="d-inline-block" htmlFor="no2" id="no2" name="question2" value="no2" onClick={() => setInvation('now')}>Add Now</Radio>
                </FormGroup>
                {invation === 'email' && <ReactAux>
                    <FormGroup className="mb-2" htmlFor="firstName" isRequired label="First Name">
                        <Input type="text" name="firstName" id="firstName" />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="secondName" isRequired label="Last Name">
                        <Input type="text" name="secondName" id="secondName" />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="select2" isRequired label="Relationship">
                        <Select
                            id="select2"
                            placeholder="Select..."
                            options={[
                                { value: '1', label: 'Business Address Guarantor' },
                                { value: '2', label: 'Co-Borrower' },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="maskPercent" isRequired label="Ownership">
                        <MaskedInput
                            id="maskPercent"
                            type="percent"
                            icon={IconsSolid.faPercent}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="emailAddress" isRequired label="Email Address">
                        <Input type="email" name="emailAddress" id="emailAddress" />
                    </FormGroup>
                </ReactAux>}
                {invation === 'now' && <ReactAux>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Does this co-applicant live at the same address as the primary applicant?">
                        <Radio className="d-inline-block" htmlFor="yes3" id="yes3" name="question3" value="yes3">Yes</Radio>
                        <Radio className="d-inline-block" htmlFor="no3" id="no3" name="question3" value="no3">No</Radio>
                    </FormGroup>
                </ReactAux>}
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: `/views/pages/10-3` }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default CoApplicantsStepOne;