import React from 'react';

import {
  Button, Section, FormGroup, ActionsWrapper, Radio, IconsSolid,
  ReactAux, Select, MaskedInput, Input, Datepicker,
} from '@jkhy/vsg-design-system';

const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const PagePersonalDetails = () => {
  return (
    <ReactAux>
      <Section
        title="Personal Details"
        headerText="Header Text: If we recognized you, some information will already be filled in for you. Please verify for accuracy and make changes where needed."
        footerText="Footer Text: Please continue to the next step."
      >
        <FormGroup className="mb-2" htmlFor="firstName" isRequired label="First Name">
          <Input type="text" name="firstName" id="firstName" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="middleName" isRequired label="Middle Name">
          <Input type="text" name="middleName" id="middleName" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="lastName" isRequired label="Last Name">
          <Input type="text" name="lastName" id="lastName" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="ssn" isRequired label="Social Security Number">
          <Input type="text" name="ssn" id="ssn" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="maskPhone" isRequired label="Mobile Phone">
          <MaskedInput
            id="maskPhone"
            type="phone"
            mask={usPhoneCountryCodeMask}
            icon={IconsSolid.faPhoneAlt}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="maskPhone2" isRequired label="Home Phone">
          <MaskedInput
            id="maskPhone2"
            type="phone"
            mask={usPhoneCountryCodeMask}
            icon={IconsSolid.faPhoneAlt}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="emailAddress" isRequired label="Email Address">
          <Input type="email" name="emailAddress" id="emailAddress" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="dpDef" isRequired label="Date of Birth">
          <Datepicker id="dpDef" name="dpDef" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="select" isRequired label="What is your US Citizenship status?">
          <Select
            id="select"
            placeholder="Select..."
            options={[
              { value: '1', label: 'US Citizen' },
              { value: '2', label: 'Resident Alien' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="select2" isRequired label="ID Type">
          <Select
            id="select2"
            placeholder="Select..."
            options={[
              { value: '1', label: 'Driver\'s License' },
              { value: '2', label: 'Passport' },
              { value: '3', label: 'State Identification' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="idNumber" isRequired label="ID Number">
          <Input type="number" name="idNumber" id="idNumber" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="dpDef2" isRequired label="ID Issue Date">
          <Datepicker id="dpDef2" name="dpDef2" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="dpDef3" isRequired label="ID Expiration Date">
          <Datepicker id="dpDef3" name="dpDef3" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="select3" isRequired label="ID Issuer">
          <Select
            id="select3"
            placeholder="Select..."
            options={[
              { value: '1', label: 'AL' },
              { value: '2', label: 'AK' },
              { value: '3', label: 'AZ' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="maskPercent" isRequired label="What is your ownership % of the business?">
          <MaskedInput
            id="maskPercent"
            type='percent'
            icon={IconsSolid.faPercent}
          />
        </FormGroup>
        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Have you ever served, or are you currently serving, in the United States Armed Forces?">
          <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
          <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="select4" isRequired label="Shows when yes to above: What is your Military Service Status?">
          <Select
            id="select4"
            placeholder="Select..."
            options={[
              { value: '1', label: 'Active - Currently Serving' },
              { value: '2', label: 'Active - Reserve or Other' },
              { value: '3', label: 'etc.' },
            ]}
          />
        </FormGroup>
      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/7' }}>Next step</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PagePersonalDetails;