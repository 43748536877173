import React from 'react';
import { useHistory } from 'react-router-dom';
import { Section, InfoCard, LoanRequestBox } from '@jkhy/vsg-design-system';


const HomePage = () => {
    const history = useHistory();

    return (
        <Section title="Home">
            <p className="mb-3">Welcome to your Client Portal.  Any documents requested can be viewed on the Requested page or using the Details button in the Requested box below.  Any loan renewal invitations will be identified on the Renewals page or in the Renewals box below.</p>
            <InfoCard
                className="mb-3"
                titles={['Requested Documents']}
                description="You have 74 requested documents"
                link={{ label: 'DETAILS', path: '#' }} />

            <InfoCard
                className="mb-3"
                titles={['Renewals']}
                description="You have 6 renewals invitation"
                link={{ label: 'DETAILS', path: '#' }} />
            
            <LoanRequestBox className="mb-3" requestType="commercial" onButtonClick={()=>{history.push('/client-portal/commercial-request-page')}} />
            <LoanRequestBox requestType="consumer" onButtonClick={()=>{history.push('/client-portal/consumer-request-page')}} />
        </Section>
    );
}

export default HomePage;