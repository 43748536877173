import React from 'react';
import { Link } from 'react-router-dom';
import { IconsSolid, FontAwesomeIcon } from '@jkhy/vsg-design-system';

import './designGuidelinesLink.scss';

const DesignGuidelinesLink = (props: { link: string }) => (
  <div className="design-guidelines-link mt-3 mb-3">
    <div className="d-flex align-items-center mb-1">
      <FontAwesomeIcon icon={IconsSolid.faPen} size="s" className="mr-1" />
      <h3>Design Guidelines</h3>
    </div>
    <p>Read rules and tips for use in <Link to={props.link}>Design Guidelines.</Link></p>
  </div>
);

export default DesignGuidelinesLink;