
import React from 'react';
import Highlight from 'react-highlight';
import { Textarea, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const TextareaDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Textarea</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The Textarea component extends the native Textarea element.</p>

            <div className='mb-2'>
                <FormGroup htmlFor="textarea" label="Texarea">
                    <Textarea rows={4} id="textarea" />
                </FormGroup>
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Textarea, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
           <FormGroup htmlFor="textarea" label="Texarea">
                <Textarea rows={4} id="textarea"/>
            </FormGroup>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" />id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>due to WCAG 2.0 and focus form control in form group on label click.</td>
                    </tr>
                    <tr>
                        <td>all native textarea attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native textarea attributes like: "rows", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TextareaDocs;