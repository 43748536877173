import React from 'react';
import Highlight from 'react-highlight';
import { FontAwesomeIcon, IconsSolid, IconsLight, IconsRegular } from '@jkhy/vsg-design-system';

const FontAwesomeIconDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Font Awesome Icon</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-3'>FontAwesomeIcon components in QD Design System uses font awesome lib for react. Thre are 3 sizes : "s","m" and "l".</p>

            <div className='mb-2'>
                <h4>Solid</h4>
                <div className='mb-2'>
                    <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="s" className="mr-1" />
                    <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="m" className="mr-1" />
                    <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="l" />
                </div>

                <h4>Regular</h4>
                <div className='mb-2'>
                    <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="s" className="mr-1" />
                    <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="m" className="mr-1" />
                    <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="l" />
                </div>

                <h4>Light</h4>
                <FontAwesomeIcon icon={IconsLight.faCreditCard} size="s" className="mr-1" />
                <FontAwesomeIcon icon={IconsLight.faCreditCard} size="m" className="mr-1" />
                <FontAwesomeIcon icon={IconsLight.faCreditCard} size="l" />
            </div>
            <h3>Styled icons</h3>
            <p>Use utility classes to change the color of the icon</p>
            <div className='mb-2'>

                <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="s" className="mr-1 text-danger" />
                <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="m" className="mr-1 text-success" />
                <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="l" className="text-primary" />
            </div>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { FontAwesomeIcon, IconsSolid, IconsLight, IconsRegular} from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        // Solid
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="s" className="mr-1" />
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="m" className="mr-1" />
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="l" />
        // Regular
        <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="s" className="mr-1" />
        <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="m" className="mr-1" />
        <FontAwesomeIcon icon={IconsRegular.faCreditCard} size="l" />
        // Light
        <FontAwesomeIcon icon={IconsLight.faCreditCard} size="s" className="mr-1" />
        <FontAwesomeIcon icon={IconsLight.faCreditCard} size="m" className="mr-1" />
        <FontAwesomeIcon icon={IconsLight.faCreditCard} size="l" />

        // Use utility classes to change the color of the icon
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="s" className="mr-1 text-danger" />
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="m" className="mr-1 text-success" />
        <FontAwesomeIcon icon={IconsSolid.faCreditCard} size="l" className="text-primary" />
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td>IconsSolid.faCreditCard, IconsSolid.faCalendar ...</td>
                        <td className='text-center'>-</td>
                        <td>Set icon.</td>
                    </tr>
                    <tr>
                        <td>size</td>
                        <td>"s" | "m" | "l"</td>
                        <td className='text-center'>"m"</td>
                        <td>Set icon size</td>
                    </tr>
                    <tr>
                        <td>all native span attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native span attributes like: "id", "class"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default FontAwesomeIconDocs;