import React from 'react';

const DisplayUtilsDocs = () => (
  <div>
    <h1 className="mb-3">Display utility classes</h1>
    <ul className="basic-list">
      <li><b>.d-flex</b> - Sets <i>display:flex</i> to the target element</li>
      <li><b>.d-inline-block</b> - Sets <i>display:inline-block</i> to the target element</li>
      <li><b>.justify-content-between</b> - Justifies the content with <i>space-between</i> for the given flex container</li>
      <li><b>.flex-space-between-center</b> - Applies the above two utilities and adds <i>display:flex</i> to the target element</li>
      <li><b>.align-items-start</b> - Does start alignment of items in the given flex container</li>
      <li><b>.align-items-end</b> - Does end alignment of items in the given flex container</li>
      <li><b>.align-items-center</b> - Does center alignment of items in the given flex container</li>
      <li><b>.align-items-baseline</b> - Does baseline alignment of items in the given flex container</li>
      <li><b>.align-items-stretch</b> - Does stretch alignment of items in the given flex container</li>
    </ul>
  </div>
);
export default DisplayUtilsDocs;