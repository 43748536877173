import React, { useState } from 'react';

import {
    Button, ActionsWrapper, IconsSolid, IconsLight, ReactAux, Section, HorizontalInfoCard,
    Modal
} from '@jkhy/vsg-design-system';

const CoApplicantsStepSeven = () => {
    const [confirmDelete, setConfirmDelte] = useState(false);
    return (
        <ReactAux>
            <Section
                title="Co-Applicants"
                headerText="Header text: Would you like to a add a party to the application?"
                footerText="Footer text: Please continue to the next step."
            >

                <HorizontalInfoCard
                    className="mb-2"
                    title="Sally Hansen"
                    icon={IconsLight.faUser}
                    onEdit={() => { }}
                    onDelete={() => setConfirmDelte(true)}
                />

                <HorizontalInfoCard
                    className="mb-2"
                    title="Jammes Potter"
                    icon={IconsLight.faUser}
                    onEdit={() => { }}
                    onDelete={() => setConfirmDelte(true)}
                />

                <HorizontalInfoCard
                    className="mb-2"
                    title="Sally Hansen"
                    icon={IconsLight.faUser}
                    onEdit={() => { }}
                    onDelete={() => setConfirmDelte(true)}
                />
                <Button iconRight={IconsSolid.faPlus} link={{ path: '/views/pages/10-2' }}>Add co-applicant</Button>

            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/11' }}>Next step</Button>
            </ActionsWrapper>

            <Modal
                title="confirm to delete"
                isVisible={confirmDelete}
                isClosable
                closeBtnName="Cancel"
                closeBtnIcon={IconsSolid.faTimesCircle}
                onClose={() => setConfirmDelte(false)}
                actionBtnName="YES, DELETE"
                actionBtnIcon={IconsSolid.faTrash}
                onSubmit={() => setConfirmDelte(false)}
                size="S"
            >
                <p>Are you sure you want to delete this?</p>
            </Modal>
        </ReactAux>
    );
}

export default CoApplicantsStepSeven;