import React from 'react';
import Highlight from 'react-highlight';
import { Button, ProgressBar, FontAwesomeIcon, IconsSolid, } from '@jkhy/vsg-design-system';

class ProgressBarDocs extends React.Component {

    state = {
        showProgress: false,
        currentStep: 0
    };

    toggleProgressBar() {
        this.setState({
            showProgress: !this.state.showProgress
        });
    }

    addStep() {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render() {
        return (
            <div>

                <Button className="mb-4" style={{ zIndex: 100, position: 'relative' }} onClick={() => { this.toggleProgressBar() }}>Toggle Progress Bar</Button>
                <Button className="mb-4 ml-2" style={{ zIndex: 100, position: 'relative' }} onClick={() => { this.addStep() }}>Add Step</Button>
                <ProgressBar
                    isVisible={this.state.showProgress}
                    steps={8}
                    currentStep={this.state.currentStep} />


                <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Progress Bar</h1>

                <h2 className='mb-2'>Description</h2>
                <p className='mb-4'>A progress bar can be used to show the level of completion of a given process.</p>

                <Highlight className="JS mb-4">
                    {
                        `
import React from 'react';
import { Button, ProgressBar } from '@jkhy/vsg-design-system';
 
class Example extends React.Component {
    
    state = {
        showProgress: false,
        currentStep: 0
    };

    toggleProgressBar() {
        this.setState({
            showProgress: !this.state.showProgress
        });
    }

    addStep() {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    render(){
        return(
            <div>
                // Buttons is for demo only
                <Button onClick={() => { this.toggleProgressBar() }}>Toggle Progress Bar</Button>
                <Button onClick={() => { this.addStep() }}>Add Step</Button>

                <ProgressBar
                    isVisible={this.state.showProgress}
                    steps={8}
                    currentStep={this.state.currentStep} />
            </div>
        )
    }
}

export default Example; 
    `
                    }
                </Highlight>

                <table className="doc-table mb-3">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td>'Please wait...'</td>
                            <td>Set title to the progress bar.</td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>string</td>
                            <td>'Please wait while we process your request. This may take a few minutes.'</td>
                            <td>Set description to the progress bar.</td>
                        </tr>
                        <tr>
                            <td>isVisible</td>
                            <td>boolean</td>
                            <td className='text-center'>-</td>
                            <td>Set progress bar visibility. This prop is <strong>required</strong>.</td>
                        </tr>
                        <tr>
                            <td>steps</td>
                            <td>number</td>
                            <td className='text-center'>-</td>
                            <td>Set progress bar total steps. This prop is <strong>required</strong>.</td>
                        </tr>
                        <tr>
                            <td>currentStep</td>
                            <td>number</td>
                            <td className='text-center'>-</td>
                            <td>Determines the current step. This prop is <strong>required</strong>.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ProgressBarDocs;