import React, { Component } from 'react';
import Highlight from 'react-highlight';
import { Datepicker, DatepickerUtils, FontAwesomeIcon, FormGroup, IconsSolid } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

class DatepickerDocs extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            dpOne: null,
            dpValue: new Date(),
            dpIsValid: true,
            dpInvalidValue: null
        }
    }

    componentDidMount(){
        // Set value after time to check value update
        setTimeout(() => {
            this.setState({
                dpOne: DatepickerUtils.dateFromString('/Date(603064800000+0200)'),
            })
        }, 1000)
    };


render() {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Datepicker</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-2'>The Datepicker component in QD Design System is a custom form control that handles date inputs,
                using an interactive calendar. It is built on top of  <a href="https://www.telerik.com/kendo-react-ui/components/dateinputs/datepicker/" target="_blank" rel="noopener noreferrer">KendoReact Datepicker</a>.</p>
            <p className='mb-4'>The DatepickerUtils object contains helpful functions for date-related cases. See the <a href="#api-datepicker-utils">API reference</a> below.</p>

            <h5 className='mb-2'>Default</h5>
            <FormGroup label="Datepicker" htmlFor="dpDefault">
                <Datepicker
                    id="dpDefault"
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YY"
                    value={this.state.dpOne}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                        this.setState({
                            dpOne: e.target.value
                        })
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange)
                    }}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup label="Datepicker disabled" htmlFor="dpDisabled">
                <Datepicker
                    placeholder="MM/DD/YY"
                    disabled
                    value={new Date()}
                    className="mb-3"
                    name="dpDisabled"
                    id="dpDisabled"
                />
            </FormGroup>
            <h5 className='mb-2'>DateTimePicker</h5>
            <FormGroup
                className="mb-2"
                label="Datetimepicker with validation"
                htmlFor='dpTime'
                isValid={this.state.dpIsValid}
                validationMessage={`Invalid date! Input ${this.state.dpInvalidValue} is not valid date!`}
            >
                <Datepicker
                    id="dpTime"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + '\n Value:' + value)
                        this.setState({
                            dpIsValid: value !== '' ? false : true,
                            dpInvalidValue: value,
                        })
                    }}
                    value={this.state.dpValue}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                        this.setState({
                            dpIsValid: true,
                            dpValue: e.target.value,
                            dpInvalidValue: '',
                        })
                    }}
                    notClearableOnInvalid
                    rangeEnd={new Date(new Date().setDate(new Date().getDate() + 1))}
                />
            </FormGroup>

            <FormGroup label="Datetimepicker disabled" htmlFor="dpTimeDisabled">
                <Datepicker
                    id="dpTimeDisabled"
                    name="dpTimeDisabled"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    dateTime={true}
                    disabled
                    value={new Date()}
                    className="mb-3"
                />
            </FormGroup>
            <h5 className='mb-2'>Datepicker with range value</h5>
            <FormGroup htmlFor="dpRange" label="Datepicker range start">
                <Datepicker
                    id="dpRange"
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + "\n value:" + value)
                    }}
                    rangeStart={new Date('2021-03-23T00:00:00')}
                    value={new Date('2021-03-23T00:00:00')}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup htmlFor="dpRangeEnd" label="Datepicker range end">
                <Datepicker
                    id="dpRangeEnd"
                    name="datepickerEnd"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + "\n value:" + value)
                    }}
                    rangeEnd={'2021-03-23T00:00:00'}
                    value={'2021-03-23T00:00:00'}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup label='Datetimepicker range end' htmlFor="dpTimeRangeEnd">
                <Datepicker
                    id="dpTimeRangeEnd"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker-end"
                    dateTime={true}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + "\n value:" + value);
                    }}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name, "ONCHANGE")
                    }}
                    rangeEnd={new Date()}
                    value={new Date()}
                    className="mb-3"
                />
            </FormGroup>

            <FormGroup label='Datetimepicker range between' htmlFor="dpTimeRange">
                <Datepicker
                    id="dpTimeRange"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                        console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + "\n value:" + value);
                    }}
                    onChange={(e: any) => {
                        console.log(e.target.value, e.target.name, "ONCHANGE")
                    }}
                    rangeStart={new Date('2021-03-01')}
                    rangeEnd={'2021-03-23T00:00:00'}
                    value={'2021-03-12T10:00:00'}
                    className="mb-3"
                />
            </FormGroup>
            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Datepicker, DatepickerUtils } from '@jkhy/vsg-design-system';

class Example extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            dpValue: new Date(),
            dpIsValid: true,
            dpInvalidValue: null
        }
    }
    render () {
        return (
            <div>
                /* Default */
              <FormGroup label="Datepicker" htmlFor="dpDefault">
                <Datepicker
                    id="dpDefault"
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YY"
                    value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange)
                    }}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup label="Datepicker disabled" htmlFor="dpDisabled">
                <Datepicker
                    placeholder="MM/DD/YY"
                    disabled
                    value={new Date()}
                    className="mb-3"
                    id="dpDisabled"
                />
            </FormGroup>

            /* DateTimePicker */
            <FormGroup
                    className="mb-2"
                    label="Datetimepicker with validation"
                    htmlFor='dpTime'
                    isValid={this.state.dpIsValid}
                    validationMessage={"Invalid date! Input " + this.state.dpInvalidValue + " is not valid date!"}
                >
                    <Datepicker
                        id="dpTime"
                        placeholder="MM/DD/YYYY hh:mm"
                        format="MM/DD/YYYY hh:mm"
                        name="datetime-picker"
                        dateTime={true}
                        onInvalidDate={(isValid: boolean, isDateInRange: boolean, value: any) => {
                            console.log("Is Date valid:" + isValid + "\n Is Date in range:" + isDateInRange + '\n Value:' + value)
                            this.setState({
                                dpIsValid: value !== '' ?  false : true,
                                dpInvalidValue: value,
                            })
                        }}
                        value={this.state.dpValue}
                        onChange={(e) => {
                            console.log(e.target.value, e.target.name)
                            this.setState({
                                dpIsValid: true,
                                dpValue: e.target.value,
                                dpInvalidValue: '',
                            })
                        }}
                        notClearableOnInvalid
                        rangeEnd={new Date(new Date().setDate(new Date().getDate() + 1))}
                    />
                </FormGroup>


            <FormGroup label="Datetimepicker disabled" htmlFor="dpTimeDisabled">
                <Datepicker
                    id="dpTimeDisabled"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    dateTime={true}
                    disabled
                    value={new Date()}
                    className="mb-3"
                />
            </FormGroup>

            /* Datepicker with range value */
            <FormGroup htmlFor="dpRange" label="Datepicker range start">
                <Datepicker
                    id="dpRange"
                    name="datepicker"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value:any) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange + "\\n value:" + value)
                    }}
                    rangeStart={new Date('2021-03-23T00:00:00')}
                    value={new Date('2021-03-23T00:00:00')}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup htmlFor="dpRangeEnd" label="Datepicker range end">
                <Datepicker
                    id="dpRangeEnd"
                    name="datepickerEnd"
                    placeholder="MM/DD/YY"
                    format="MM/DD/YYYY"
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name)
                    }}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean, value:any) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange + "\\n value:" + value)
                    }}
                    rangeEnd={'2021-03-23T00:00:00'}
                    value={'2021-03-23T00:00:00'}
                    className="mb-2"
                />
            </FormGroup>

            <FormGroup label='Datetimepicker range end' htmlFor="dpTimeRangeEnd">
                <Datepicker
                    id="dpTimeRangeEnd"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker-end"
                    dateTime={true}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean,value:any) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange +"\\n value:" + value);
                    }}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name,"ONCHANGE")
                    }}
                    rangeEnd={new Date()}
                    value={new Date()}
                    className="mb-3"
                />
            </FormGroup>

            <FormGroup label='Datetimepicker range between' htmlFor="dpTimeRange">
                <Datepicker
                    id="dpTimeRange"
                    placeholder="MM/DD/YYYY hh:mm"
                    format="MM/DD/YYYY hh:mm"
                    name="datetime-picker"
                    dateTime={true}
                    onInvalidDate={(isValid: boolean, isDateInRange: boolean,value:any) => {
                        console.log("Is Date valid:" + isValid + "\\n Is Date in range:" + isDateInRange +"\\n value:" + value);
                    }}
                    onChange={(e) => {
                        console.log(e.target.value, e.target.name,"ONCHANGE")
                    }}
                    rangeStart={new Date('2021-03-01')}
                    rangeEnd={'2021-03-23T00:00:00'}
                    value={'2021-03-12T10:00:00'}
                    className="mb-3"
                />
            </FormGroup>  
        </div>
        }
    );
}

export default Example; 
    `}
            </Highlight>

            <h6 className="mb-3">Datepicker API</h6>
            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dateTime</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Configures the datepicker to contain a time picker.</td>
                    </tr>
                    <tr>
                        <td>format</td>
                        <td>string</td>
                        <td>"MM/DD/YYYY"</td>
                        <td>Sets the date format.</td>
                    </tr>
                    <tr>
                        <td>placeholder</td>
                        <td>string</td>
                        <td>'MM/DD/YYYY'</td>
                        <td>Sets the placeholder in the input.</td>
                    </tr>
                    <tr>
                        <td>rangeStart</td>
                        <td>Date</td>
                        <td className='text-center'>-</td>
                        <td>Sets the datepicker starting date.</td>
                    </tr>
                    <tr>
                        <td>rangeEnd</td>
                        <td>Date</td>
                        <td className='text-center'>-</td>
                        <td>Sets the datepicker end date.</td>
                    </tr>
                    <tr>
                        <td>onInvalidDate</td>
                        <td>function(isValid: boolean, isDateInRange: boolean)</td>
                        <td className='text-center'>-</td>
                        <td>Function, triggered when you try to set an invalid date or a date out of range.</td>
                    </tr>
                    <tr>
                        <td>value</td>
                        <td>Date</td>
                        <td className='text-center'>-</td>
                        <td>Sets datepicker and input value.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>function</td>
                        <td className='text-center'>-</td>
                        <td>A callback, executed after the datepicker date is changed or the input field is blurred.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>Set <strong>id</strong> due to WCAG 2.0. Id is also used in DP component to fix close btn func due to know KendoreactDP issue.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="4.2" />notClearableOnInvalid</td>
                        <td>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Set input to not clear it's value onInvalid date.</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "disabled", "name", etc.</td>
                    </tr>
                </tbody>
            </table>

            <h6 id="api-datepicker-utils" className="mb-3">Datepicker Utils API</h6>
            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Functions</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Params</strong>
                        </td>
                        <td>
                            <strong>Return type</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>dateFromString</td>
                        <td>(date:string, format?:string)</td>
                        <td>Date</td>
                        <td>Converts string to date. The default format is "MM/DD/YYYY".</td>
                    </tr>
                    <tr>
                        <td>formatedDateString</td>
                        <td>(date:Date, format:string)</td>
                        <td>string</td>
                        <td>Applies the target formatting to the given date.</td>
                    </tr>
                </tbody>
            </table>

            <h6 className="mb-3">Known Issues</h6>
            <p>
                DateTimepicker - Bug on Safari when open dp on Date('tab') and click on Time('tab') the content of dp goes to left and you don't see almost anything. The same problem appear when select date from Date('tab'). <br />
                For the custom icon btns is used temporary fix to workaround the issue.
            </p>
        </div>
    )
}
}

export default DatepickerDocs;