import { Alert } from '@jkhy/vsg-design-system';
import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const MigrationGuide = () => (
  <div>
    <h1 className="mb-3">Migration from v.4.0 to v.4.1</h1>
    <p className="mb-5">The 4.1 release of VSG Design System adds significant amount of redesign to components
    and layouts, as well as more customization options. The majority of the updates are backwards compatible, but there
    are, however, several exceptions. All of them will be listed below.</p>

    <h2 className="mb-1">Import paths</h2>
    <Alert className="mb-3" type="danger" text="Breaking change!" />
    <p className="mb-3">A major change, compared to the previous version, is the new approach for loading
    component modules from the design system library. All components are now imported from the same entry point:</p>

    <Highlight className="React mb-5">
      {
        `
  // Previously:
  import { Button} from '@jkhy/vsg-design-system/Button';
  import { ActionsWrapper } from '@jkhy/vsg-design-system/ActionsWrapper';

  // New approach:
  import { Button, ActionsWrapper } from '@jkhy/vsg-design-system';
  `
      }
    </Highlight>

    <h2 className="mb-1">Private npm packages</h2>
    <Alert className="mb-3" type="danger" text="Breaking change!" />
    <p className="mb-1">So far, the <strong><i>.npmrc</i></strong> file in the client application contained only the credentials for the
    package registry of the design system library. Since the 4.1 version uses the Pro version of FontAwesome icons,
    a private authentication token must be set, as shown in the example below:</p>
    <Highlight className="yaml mb-5">
      {
        `
# Configuration for the design system module
@jkhy:registry=https://npm.pkg.github.com/jkhy
//npm.pkg.github.com/:_authToken=PRIVATE-AUTH-TOKEN

registry=https://registry.npmjs.org/


# Configuration for the FontAwesome icons module
@fortawesome:registry=https://npm.fontawesome.com/
//npm.fontawesome.com/:_authToken=PRIVATE-ICONS-AUTH-TOKEN
        `
      }
    </Highlight>

    <h2 className="mb-1">CSS Variables</h2>
    <Alert className="mb-3" type="warning" text="Important update!" />
    <p className="mb-5">There are many new CSS variables (for shadows, background images, etc.), which need to be properly
    configured by the client application. See the full list of CSS variables <Link to="/docs/introduction">here</Link>.</p>

    <h2 className="mb-1">Fonts</h2>
    <Alert className="mb-3" type="warning" text="Important update!" />
    <p className="mb-1">Font files are no longer loaded from CDNs, thus making the VSG Design System indepented from
    external resources. All available fonts are exported from the design system library in the <strong><i>fonts</i></strong> folder.</p>
    <p className="mb-5">By default, all fonts are loaded by the <strong><i>styles.css</i></strong> stylesheet, so there
    is <strong>no need to import font resources</strong> on their own.</p>

    <h2 className="mb-1">New props for components</h2>
    <Alert className="mb-3" type="warning" text="Important update!" />
    <p className="mb-3">In general, the API of the components is kept as much backwards-compatible as possible. There
    are still some updates on props that need to be reflected such as new props or obsolete ones. They are flagged by specific tags in the props tables
     in the documentation of components, as showcased here:</p>

    <table className="doc-table mb-5">
      <thead>
        <tr>
          <th colSpan={4}>Props</th>
        </tr>
        <tr>
          <td>
            <strong>Name</strong>
          </td>
          <td>
            <strong>Options</strong>
          </td>
          <td>
            <strong>Default</strong>
          </td>
          <td>
            <strong>Description</strong>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><PropVersionTag type="new" /> newDemoProp </td>
          <td>string</td>
          <td className="text-center">-</td>
          <td>Example for a prop, that is introduced in the new version.</td>
        </tr>
        <tr>
          <td>demoProp </td>
          <td>string</td>
          <td className="text-center">-</td>
          <td>Example for a prop, that is not changed.</td>
        </tr>
        <tr>
          <td><PropVersionTag type="obsolete" />removedDemoProp</td>
          <td>string</td>
          <td className="text-center">-</td>
          <td>Example for a prop, that is no longer available.</td>
        </tr>
      </tbody>
    </table>

    <h2 className="mb-1">Build setup</h2>
    <Alert className="mb-3" type="info" text="Relates only to the development build setup." />
    <p className="mb-3">The new development configuration is more scalable and fixes the issues with untracked changes to the library module
      in the documentation app. A brief showcase of the new workfow is displayed below:</p>

    <Highlight className="React mb-4">
      {
        `
  // 1. Initialize the development setup
  npm run init

  // 2. Open a new terminal, watch for changes in the component library
  npm run dev:lib

  // 3. Open a new terminal, start the documentation app
  npm run dev:docs
  `
      }
    </Highlight>

    <p className="mb-5">For more detailed explanation of each step, refer to the <strong><i>README.md</i></strong> file of the project.</p>
  </div>
);

export default MigrationGuide;