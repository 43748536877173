import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import '@jkhy/vsg-design-system/styles.css';

import Docs from './docs/Docs';
import App from './App';
import DemoPages from './views/DemoPages';
import ClientPortal from './views/ClientPortal';
import DocsThemeChanger from './helpers/DocsThemeChanger';

const IndexContainer = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect exact from="/" to="/docs/introduction" />
        <Redirect exact from="/docs" to="/docs/introduction" />
        <Route path="/docs" component={Docs} />
        <Route path="/components" exact render={props => <App {...props} />} />
        <Route path="/views" render={props => <DemoPages {...props} />} />
        <Route path="/client-portal" render={props => <ClientPortal {...props} />} />
        <Route path="/renewals" render={props => <DemoPages {...props} renewals />} />
      </Switch>
      <DocsThemeChanger />
    </BrowserRouter>
  )
};

ReactDOM.render(<IndexContainer />, document.getElementById('root'));
