import React, { useState } from 'react';

import {
  Button, ActionsWrapper, Collapse, IconsSolid, LinkWithIcon, ReactAux, FontAwesomeIcon,
  Section, DataPreviewText,
} from '@jkhy/vsg-design-system';

const PageReviewApplication = () => {

  const [collapses, setCollapses] = useState<any>({
    collapse1: false,
    collapse2: false,
    collapse3: false,
    collapse4: false,
    collapse5: false,
    collapse6: false,
    collapse7: false,
    collapse8: false,
    collapse9: false,
    collapse10: false,
    collapse11: false,
  });

  const onChangeCollapse = (collapse: any) => {
    setCollapses({ ...collapses, [collapse]: !collapses[collapse] });
  }

  return (
    <ReactAux>
      <Section title="Review Application" headerText="Expand a specifiv section to view details or exapnd all sections. Selecting edit will navigate you to the section of the application to make any needed changes.">
        <LinkWithIcon
          className="mb-3"
          icon={Object.keys(collapses).every((k: any) => collapses[k] === true) ? IconsSolid.faCompressAlt : IconsSolid.faExpandAlt} >
          <a href="/" onClick={(e) => {
            e.preventDefault();

            const allExpand = collapses;
            if (Object.keys(collapses).every((k: any) => collapses[k] === true)) {
              Object.keys(allExpand).forEach(v => allExpand[v] = false)
            } else {
              Object.keys(allExpand).forEach(v => allExpand[v] = true)
            }

            setCollapses({ ...allExpand });
          }}
          >
            {Object.keys(collapses).every((k: any) => collapses[k] === true) ? 'Collapse all sections' : 'Expand all sections'}
          </a>
        </LinkWithIcon>

        <h3 className="mb-1">Purpose / Product</h3>
        <Collapse
          title="Purpose of Loan:"
          subTitle="Vehicle Purchase"
          expanded={collapses.collapse1}
          onClick={() => onChangeCollapse('collapse1')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Purpose of Loan:" text="Vehicle Purchase" />
          <DataPreviewText className="mb-2" bold="label" label="Vehicle Purchase:" text="Term Loan" />
          <DataPreviewText className="mb-2" bold="label" label="Amount Requested:" text="$ 79,000" />
          <DataPreviewText className="mb-2" bold="label" label="Term Length:" text="5 Years" />
          <DataPreviewText className="mb-2" bold="label" label="Specic Reason:" text="Purchase a 2021 Chevy Corvette" />
          <DataPreviewText className="mb-2" bold="label" label="Location of Business:" text="NC" />
          <DataPreviewText className="mb-2" bold="label" label="County of Business:" text="Catawba County" />
          <DataPreviewText className="mb-2" bold="label" label="Existing Deposit Customer?" text="No" />
          <DataPreviewText className="mb-2" bold="label" label="Date Business Started:" text="12/2000" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Borrower</h3>
        <Collapse
          title="Business Name:"
          subTitle="Deerwood Fasteners International"
          expanded={collapses.collapse2}
          onClick={() => onChangeCollapse('collapse2')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Business Name:" text="Deerwood Fasteners International" />
          <DataPreviewText className="mb-2" bold="label" label="Doing Business As (DBA), if applicable:" />
          <DataPreviewText className="mb-2" bold="label" label="Street Address (No PO Boxes):" text="638 Reese Dr SW" />
          <DataPreviewText className="mb-2" bold="label" label="City/State/Zip Code:" text="Conover, NC, 28613" />
          <DataPreviewText className="mb-2" bold="label" label="Mailing Address (If different fromabove):" text="PO Box 638" />
          <DataPreviewText className="mb-2" bold="label" label="City/State/Zip Code:" text="Conover, NC, 28613" />
          <DataPreviewText className="mb-2" bold="label" label="Type of Business:" text="Fasteners" />
          <DataPreviewText className="mb-2" bold="label" label="State Formed:" text="NC" />
          <DataPreviewText className="mb-2" bold="label" label="Telephone:" text="(828) 469-1075" />
          <DataPreviewText className="mb-2" bold="label" label="Organization Type:" text="Corporation" />
          <DataPreviewText className="mb-2" bold="label" label="# of Employees:" text="500" />
          <DataPreviewText className="mb-2" bold="label" label="Tax Entity Type:" text="Limited Liability Company" />
          <DataPreviewText className="mb-2" bold="label" label="Tax ID:" text="568352169" />
          <DataPreviewText className="mb-2" bold="label" label="Website Address:" text="www.deerwoodfasteners.com" />
          <DataPreviewText className="mb-2" bold="label" label="Business Email Address (If differentfrom Owner below):" text="sandra.bishop@mailinator.com" />
          <DataPreviewText className="mb-2" bold="label" label="Most Recent Year End Date:" text="12/31/2019" />
          <DataPreviewText className="mb-2" bold="label" label="Annual Sales:" text="$ 850,000" />
          <DataPreviewText className="mb-2" bold="label" label="Net Income:" text="600000" />
          <DataPreviewText className="mb-2" bold="label" label="Business Checking Avg. Balance:" text="300000" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Owner Information</h3>
        <Collapse
          title="Name:"
          subTitle="Sandra L Bishop"
          expanded={collapses.collapse3}
          onClick={() => onChangeCollapse('collapse3')}
        >
          <DataPreviewText className="mb-2" bold="label" label="First Name:" text="Sandra" />
          <DataPreviewText className="mb-2" bold="label" label="Middle Name:" text="L" />
          <DataPreviewText className="mb-2" bold="label" label="Last Name:" text="Bishop" />
          <DataPreviewText className="mb-2" bold="label" label="SSN:" text="666293688" />
          <DataPreviewText className="mb-2" bold="label" label="Street Address:" text="17795 SW 112th Ave" />
          <DataPreviewText className="mb-2" bold="label" label="City/State/Zip Code:" text="Beaverton, OR, 97260" />
          <DataPreviewText className="mb-2" bold="label" label="Telephone:" text="(931) 645-1258" />
          <DataPreviewText className="mb-2" bold="label" label="Date of Birth:" text="01/01/1962" />
          <DataPreviewText className="mb-2" bold="label" label="Employer Name:" text="Deerwood Fasteners International" />
          <DataPreviewText className="mb-2" bold="label" label="Job Title:" text="President" />
          <DataPreviewText className="mb-2" bold="label" label="Annual Income:" text="$ 200,000" />
          <DataPreviewText className="mb-2" bold="label" label="% Ownership:" text="100%" />
          <DataPreviewText className="mb-2" bold="label" label="Are you a U.S. Citizen?" text="Yes" />
          <DataPreviewText className="mb-2" bold="label" label="Email Address:" text="sandra.bishop@mailinator.com" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Business References</h3>
        <Collapse
          title="Accountant"
          expanded={collapses.collapse4}
          onClick={() => onChangeCollapse('collapse4')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Accountant:" text="Jhon Miller" />
          <DataPreviewText className="mb-2" bold="label" label="Email:" text="jhon@mailinator.com" />
          <DataPreviewText className="mb-2" bold="label" label="Citi/State/Zip Code:" text="CHICAGO, IL, 29829" />
          <DataPreviewText className="mb-2" bold="label" label="Phone Number:" text="(910) 128-4894" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>
        <Collapse
          title="Attorney"
          expanded={collapses.collapse5}
          onClick={() => onChangeCollapse('collapse5')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Attorney:" text="Daniel Garcia" />
          <DataPreviewText className="mb-2" bold="label" label="Email:" text="daniel@mailinator.com" />
          <DataPreviewText className="mb-2" bold="label" label="Citi/State/Zip Code:" text="Los Angeles, CA, 28498" />
          <DataPreviewText className="mb-2" bold="label" label="Phone Number:" text="(219) 910-1030" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>
        <Collapse
          title="Insurance Agent"
          expanded={collapses.collapse6}
          onClick={() => onChangeCollapse('collapse6')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Insurance Agent:" text="Amy Sater" />
          <DataPreviewText className="mb-2" bold="label" label="Email:" text="amy@mailinator.com" />
          <DataPreviewText className="mb-2" bold="label" label="Citi/State/Zip Code:" text="Bronx, NY, 92919" />
          <DataPreviewText className="mb-2" bold="label" label="Phone Number:" text="(219) 910-1030" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Collateral</h3>
        <Collapse
          title="Collateral to Secure Your Business Loan"
          expanded={collapses.collapse7}
          onClick={() => onChangeCollapse('collapse7')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Category:" text="TRANSPORTATION" />
          <DataPreviewText className="mb-2" bold="label" label="Type:" text="Motor Vehicles-Personal (Cars," />
          <DataPreviewText className="mb-2" bold="label" label="Description:" text="New 2021 Corvette Stongray RWDCoupe 3LT Elkhart Lake Blue Metallic" />
          <DataPreviewText className="mb-2" bold="label" label="Current Value:" text="$ 71,945" />
          <DataPreviewText className="mb-2" bold="label" label="Current Loan:" />
          <DataPreviewText className="mb-2" bold="label" label="Owner:" text="Sandra Bishop" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Debts / Deposits</h3>
        <Collapse
          title="Business Obligations"
          expanded={collapses.collapse8}
          onClick={() => onChangeCollapse('collapse8')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Creditor:" text="Wells Fargo" />
          <DataPreviewText className="mb-2" bold="label" label="Loan Type:" text="Term Loan" />
          <DataPreviewText className="mb-2" bold="label" label="Current Balance:" text="$ 500,000" />
          <DataPreviewText className="mb-2" bold="label" label="Monthly Payment:" text="$ 7,800" />
          <DataPreviewText className="mb-2" bold="label" label="Collateral:" text="Real estate: 638 Reese Dr SW, Conover,NC 28613 1 acre with 15,000 SFwarehouse building" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>
        <Collapse
          title="Business Deposit Relationships"
          expanded={collapses.collapse9}
          onClick={() => onChangeCollapse('collapse9')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Deposit Type:" text="Checking" />
          <DataPreviewText className="mb-2" bold="label" label="Current Balance:" text="$ 85,000" />
          <DataPreviewText className="mb-2" bold="label" label="Bank Name:" text="Wells Fargo" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Declarations</h3>
        <Collapse
          title="Sandra L Bishop"
          expanded={collapses.collapse10}
          onClick={() => onChangeCollapse('collapse10')}
        >
          <DataPreviewText className="mb-2" bold="label" label="Do you agree to have your creditpulled?" text="Yes" />
          <DataPreviewText className="mb-2" bold="label" label="If you have a banking relationship witha current banker, please select theirname from the drop down:" text="Robin Tufts" />
          <DataPreviewText className="mb-2" bold="label" label="Do you consent to receivingcommunication electronically via e-mail? If you do not, the onlineapplication cannot be processed,Please contact us at 555-555-5555 forassistance with obtaining a loan:" text="Yes" />
          <DataPreviewText className="mb-2" bold="label" label="Are you an Executive Ocer of OvationFinancial?" text="No" />
          <DataPreviewText className="mb-2" bold="label" label="How did you hear about this online loanapplication?" text="Email" />
          <ActionsWrapper alignLeft narrow>
            <LinkWithIcon icon={IconsSolid.faPen} >
              <a href="/" >Go to borrower step to edit</a>
            </LinkWithIcon>
          </ActionsWrapper>
        </Collapse>

        <h3 className="mb-1 mt-4">Disclosures</h3>
        <Collapse
          title="Truth in Lending"
          expanded={collapses.collapse11}
          onClick={() => onChangeCollapse('collapse11')}
        >
          <DataPreviewText bold="label" label="Truth in Lending:" />
          <p><FontAwesomeIcon icon={IconsSolid.faThumbsUp} size="s" /></p>
        </Collapse>
      </Section>

      <ActionsWrapper className="mt-4">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/12' }}>Submit Applicant and Continue</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PageReviewApplication;