import React from 'react';
import Highlight from 'react-highlight';

import PropVersionTag from '../PropVersionTag/PropVersionTag';
import { Section, FontAwesomeIcon, IconsSolid } from '@jkhy/vsg-design-system';


const ContainersDocs = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Section</h1>
            <h2 className='mb-2'>Description</h2>
            <p className="mb-4">
                Section component separates your content with horizontal line. Optionally you can add "link" and "title" to it.
            </p>

            <Section className="mb-4" title="Section with title">
                <p>Content...</p>
            </Section>
            <Section className="mb-4" title="Section with title, header and footer" headerText="Section header text" footerText="Section footer text">
                <p>Content...</p>
            </Section>
            <Section className="mb-4" title="Section with title and header" headerText="Section header text">
                <p>Content...</p>
            </Section>
            <Section className="mb-4" title="Section with HTML" headerText={'Section <strong>header</strong> text <p>Paragraph</p><p>Paragraph</p><p>Paragraph</p><a href="/">Link</a>'} headerTextHasHTML footerText="Section <i>footer</i> text" footerTextHasHTML>
                <p>Test Content...</p>
            </Section>

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Section } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        <Section className="mb-4" title="Section with title">
            <p>Content...</p>
        </Section>
        <Section className="mb-4" title="Section with title, header and footer" headerText="Section header text" footerText="Section footer text">
            <p>Content...</p>
        </Section>
        <Section className="mb-4" title="Section with title and header" headerText="Section header text">
            <p>Content...</p>
        </Section>
        <Section className="mb-4" title="Section with HTML" headerText={'Section <strong>header</strong> text <p>Paragraph</p><p>Paragraph</p><p>Paragraph</p><a href="/">Link</a>'} headerTextHasHTML footerText="Section <i>footer</i> text" footerTextHasHTML>
            <p>Test Content...</p>
        </Section>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set title of the section.</td>
                    </tr>
                    <tr>
                        <td>headerText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set header text of the section.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="v.4.2" /> headerTextHasHTML</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>When is true will parse headerText (string) to HTML.</td>
                    </tr>
                    <tr>
                        <td>footerText</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set footer text of the section.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" version="v.4.2" /> footerTextHasHTML</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>When is true will parse footerText (string) to HTML.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ContainersDocs;
