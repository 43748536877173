import React from 'react';
import Highlight from 'react-highlight';
import { Radio, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';
import DesignGuidelinesLink from '../DesignGuidelinesLink/DesignGuidelinesLink';

const RadioDocs = () => {
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Radio</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>The Radio component extends the native input type radio element.</p>

            <div className='mb-2'>
                <h4 className="mb-1">Default</h4>
                <Radio className="mb-05" htmlFor="male" id="male" name="gender" value="male">Male</Radio>
                <Radio className="mb-05" htmlFor="female" id="female" name="gender" value="female">Female</Radio>
                <Radio className="mb-05" htmlFor="other" id="other" name="gender" value="other">Other</Radio>
                <Radio className="mb-05" htmlFor="other2" id="other2" disabled name="gender" value="other2">Other 2</Radio>
            </div>

            <div className="mb-2">
                <h4 className="mb-1">Inline by adding utility class: "d-inline-block"</h4>
                <Radio className="d-inline-block mr-05" htmlFor="male2" id="male2" name="gender-inline" value="male">Male</Radio>
                <Radio className="d-inline-block mr-05" htmlFor="female2" id="female2" name="gender-inline" value="female">Female</Radio>
                <Radio className="d-inline-block" htmlFor="other3" id="other3" name="gender-inline" value="other">Other</Radio>
            </div>

            <div className="mb-2">
                <h4 className="mb-1">In FormGroup</h4>
                <FormGroup label='Radio' checkboxOrRadio isBold={true} className="mb-2">
                    <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
                    <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
                </FormGroup>
            </div>

            <DesignGuidelinesLink link="/docs/radio-vs-select" />

            <Highlight className="React mb-4">
                {
                    `
import React from 'react';
import { Radio, FormGroup } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
    <div>
        // Default
        <Radio className="mb-05" htmlFor="male" id="male" name="gender" value="male">Male</Radio>
        <Radio className="mb-05" htmlFor="female" id="female" name="gender" value="female">Female</Radio>
        <Radio className="mb-05" htmlFor="other" id="other" name="gender" value="other">Other</Radio>
        <Radio className="mb-05" htmlFor="other2" id="other2" disabled name="gender" value="other2">Other 2</Radio>

        // Inline
        <Radio className="d-inline-block mr-05" htmlFor="male2" id="male2" name="gender-inline" value="male">Male</Radio>
        <Radio className="d-inline-block mr-05" htmlFor="female2" id="female2" name="gender-inline" value="female">Female</Radio>
        <Radio className="d-inline-block" htmlFor="other3" id="other3" name="gender-inline" value="other">Other</Radio>
    
        // In FormGroup
        <FormGroup label='Radio' checkboxOrRadio isBold={true} className="mb-2">
            <Radio className="d-inline-block" htmlFor="agreeYes" id="agreeYes" name="agree" value="yes">Yes</Radio>
            <Radio className="d-inline-block" htmlFor="agreeNo" id="agreeNo" name="agree" value="no">No</Radio>
        </FormGroup>
    
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><PropVersionTag type="new" /> htmlFor</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set <strong>Label</strong> htmlFor text due to WCAG 2.0.</td>
                    </tr>
                    <tr>
                        <td>all native input type radio attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input type radio attributes like: "name", "value", "checked"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default RadioDocs;