import React from 'react';

import {
    Button, Section, ReactAux, ActionsWrapper, IconsSolid,
} from '@jkhy/vsg-design-system';

const CoApplicantsStepFive = () => {
    return (
        <ReactAux>
            <Section title="Co-Applicants Information">
                <h3 className="mb-2">Good News!</h3>
                <p>We recognize you as an existing client. Shortly you will be presented with questions to verify your indentity so you can continue the application.</p>
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/10-6' }}>Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default CoApplicantsStepFive;