import React from 'react';

import {
    Button, Section, FormGroup, ActionsWrapper, MultipleFormGroup, IconsSolid,
    ReactAux, Select, MaskedInput, Textarea,
} from '@jkhy/vsg-design-system';

const PageRequest = () => {
    return (
        <ReactAux>
            <Section
                title="Request"
                headerText="Header Text: Let us know how much money you are looking to borrow."
                footerText="Footer Text: Please continue to the next step."
            >
                <FormGroup className="mb-2" htmlFor="select" isRequired label="What type of credit request are you applying for?">
                    <Select
                        id="select"
                        placeholder="Select..."
                        options={[
                            { value: '1', label: 'Joint' },
                            { value: '2', label: 'Single' },
                            { value: '3', label: 'etc.' }
                        ]}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="select2" isRequired label="What type of request are you applying for?">
                    <Select
                        id="select2"
                        placeholder="Select..."
                        options={[
                            { value: '1', label: 'Purchase Equipment' },
                            { value: '2', label: 'Working Capital' },
                            { value: '3', label: 'Unsecured' }
                        ]}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="select3" isRequired label="What type of product are you requesting?">
                    <Select
                        id="select3"
                        placeholder="Select..."
                        options={[
                            { value: '1', label: 'Term Loan' },
                            { value: '2', label: 'Line of Credit' }
                        ]}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="select4" isRequired label="How many years do you want to pay this loan for?">
                    <Select
                        id="select4"
                        placeholder="Select..."
                        options={[
                            { value: '1', label: '1 Year' },
                            { value: '2', label: '2 Years' },
                            { value: '3', label: '3 Years' },
                            { value: '4', label: '4 Years' },
                        ]}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="maskNumber" isRequired label="How much of a loan are you looking for?">
                    <MaskedInput
                        id="maskNumber"
                        type="number"
                        icon={IconsSolid.faDollarSign}
                    />
                </FormGroup>
                <FormGroup className="mb-2" htmlFor="textarea" isRequired label="Please describe how you plan to use the funds?">
                    <Textarea rows={4} id="textarea" />
                </FormGroup>
                <MultipleFormGroup
                    className="mb-2"
                    multiFormGroupProps={{
                        label: 'Date Business Started - Month & Year',
                        isRequired: true,
                    }}
                    groupColProps={{
                        lg: '12',
                    }}
                    groupElements={[
                        {
                            elementColSize: 6,
                            element: <FormGroup
                                htmlFor="mMonth"
                                isMultipleGroup
                                isRequired
                                label="Month"
                            >
                                <Select
                                    id="mMonth"
                                    options={[
                                        { value: 'Jan', label: 'January' },
                                        { value: 'Feb', label: 'February' },
                                        { value: 'Mar', label: 'March' },
                                        { value: 'Apr', label: 'April' },
                                        { value: 'May', label: 'May' },
                                        { value: 'June', label: 'June' },
                                        { value: 'July', label: 'July' },
                                        { value: 'Aug', label: 'August' },
                                        { value: 'Sept', label: 'September' },
                                        { value: 'Oct', label: 'October' },
                                        { value: 'Nov', label: 'November' },
                                        { value: 'Dec', label: 'December' },
                                    ]}
                                />
                            </FormGroup>,
                        },
                        {
                            elementColSize: 6,
                            element: <FormGroup
                                htmlFor="mYear"
                                isMultipleGroup
                                isRequired
                                label="Year"
                            >
                                <MaskedInput
                                    className="text-left"
                                    id="mYear"
                                    type="number"
                                    mask={[/\d/, /\d/, /\d/, /\d/]}
                                    placeholder="YYYY"
                                />
                            </FormGroup>,
                        },
                    ]}
                />
            </Section>

            <ActionsWrapper className="mt-2">
                <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/5' }}>Matched Party Next step</Button>
            </ActionsWrapper>
        </ReactAux>
    );
}

export default PageRequest;