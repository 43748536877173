import React from 'react';
import Highlight from 'react-highlight';
import { Link } from 'react-router-dom';
import { IconsSolid, FontAwesomeIcon, ListBox, ListBoxContainer } from '@jkhy/vsg-design-system';

const ListBoxContainerDoc = () => {
    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> List Box Container</h1>
            <h2 className="mb-2">Description</h2>
            <p className="mb-4">ListBoxContainer component helps you to align <Link to='/docs/listbox'>ListBox</Link> components.</p>

            <ListBoxContainer>
                <ListBox
                    boxItems={
                        [
                            {
                                title: {
                                    text: 'Primary Borrower:',
                                    isBold: true,
                                },
                                description: 'Sally Jones'
                            },
                            {
                                title: {
                                    text: 'Date Started:',
                                    isBold: true
                                },
                                description: '01/02/2021'
                            },
                            {
                                title: {
                                    text: 'Amount Requested:',
                                    isBold: true

                                },
                                description: '$35,000'
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faInfoCircle,
                                    className: 'text-primary'
                                },
                                description: 'You have been added to this application.'
                            }
                        ]
                    } />
                <ListBox
                    boxItems={
                        [
                            {
                                iconSettings: {
                                    icon: IconsSolid.faComments,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Need Help?'
                                },
                                description: <Link to="/" title="Chat with us"> Chat with us</Link>
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faIdCard,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Banker’s Name:',
                                    isBold: true
                                },
                                description: 'John Banker'
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faPhone,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Mobile:',
                                    isBold: true
                                },
                                description: <a href="tel:555-555-5555" title="555-555-5555">555-555-5555</a>
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faEnvelope,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'E-mail:',
                                    isBold: true
                                },
                                description: <a href="mailto:john@banker.com" title="john@banker.com">john@banker.com </a>
                            }
                        ]
                    } />
            </ListBoxContainer>

            <Highlight className="React mb-4">
                {`
import React from 'react';
import { Link } from 'react-router-dom';
import { IconsSolid, FontAwesomeIcon, ListBox, ListBoxContainer } from '@jkhy/vsg-design-system';

const Example = (props) => {
    return (
       
    <ListBoxContainer>
                <ListBox
                    boxItems={
                        [
                            {
                                title: {
                                    text: 'Primary Borrower:',
                                    isBold: true,
                                },
                                description: 'Sally Jones'
                            },
                            {
                                title: {
                                    text: 'Date Started:',
                                    isBold: true
                                },
                                description: '01/02/2021'
                            },
                            {
                                title: {
                                    text: 'Amount Requested:',
                                    isBold: true

                                },
                                description: '$35,000'
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faInfoCircle,
                                    className: 'text-primary'
                                },
                                description: 'You have been added to this application.'
                            }
                        ]
                    } />
                <ListBox
                    boxItems={
                        [
                            {
                                iconSettings: {
                                    icon: IconsSolid.faComments,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Need Help?'
                                },
                                description: <Link to="/" title="Chat with us"> Chat with us</Link>
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faIdCard,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Banker’s Name:',
                                    isBold: true
                                },
                                description: 'John Banker'
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faPhone,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'Mobile:',
                                    isBold: true
                                },
                                description: <a href="tel:555-555-5555" title="555-555-5555">555-555-5555</a>
                            },
                            {
                                iconSettings: {
                                    icon: IconsSolid.faEnvelope,
                                    className: 'text-primary'
                                },
                                title: {
                                    text: 'E-mail:',
                                    isBold: true
                                },
                                description: <a href="mailto:john@banker.com" title="john@banker.com">john@banker.com </a>
                            }
                        ]
                    } />
            </ListBoxContainer>

    );
}

export default Example; 
    `}
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native div attributes</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "class", "id"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ListBoxContainerDoc;