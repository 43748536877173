import React from 'react';
import { Select } from '@jkhy/vsg-design-system';

const DemoWrapper = (props: any) => {

    return (
            <Select
                {...props}
            />
    );
}

export default DemoWrapper;
