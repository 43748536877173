import React, { useState } from 'react';
import { FontAwesomeIcon, FormGroup, IconsSolid, Input, MaskedInput, Radio, ReactAux, Select } from '@jkhy/vsg-design-system';

const InterdependentFormControlsDocs = () => {

    const [agree, setAgree] = useState(false);
    const [searchBy, setSearchBy] = useState<string | null>(null);

    return (
        <div>
            <h1 className="mb-3"><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Interdependent Form Controls</h1>

            <h2 className="mb-3">Example: Form controls that depend on radio choice</h2>
            <h3>How to show form controls that are being shown/hidden based on another field.</h3>
            <ul className="basic-list mb-2">
                <li>Whenever you need to hide/show form controls that depend on user's choice, no additional styling is needed. We do not group these form controls in a wrapper, nor do we put many controls inside one and the same {'<FormGroup>'}.</li>
                <li>Form controls should look regularly and independent from one another.</li>
            </ul>
            <p className="mb-2">Here is an example with fields from "AUTO DETAILS" page:</p>

            <div style={{ maxWidth: "500px" }}>
                <FormGroup className="mb-2" isRequired checkboxOrRadio label="Do you know the vehicle information?">
                    <Radio htmlFor="agreeYes" id="agreeYes" name="agree" value="yes" onClick={() => setAgree(true)}>Yes - Enter the details now.</Radio>
                    <Radio htmlFor="agreeNo" id="agreeNo" name="agree" value="no" onClick={() => { setAgree(false); setSearchBy(null); }}>No - Continue with the application and return later to enter the details.</Radio>
                </FormGroup>

                {agree && <ReactAux>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Is the vehicle New or Used?">
                        <Radio className="d-inline-block" htmlFor="new" id="new" name="newOrUsed" value="new">New</Radio>
                        <Radio className="d-inline-block" htmlFor="used" id="used" name="newOrUsed" value="used">Used</Radio>
                    </FormGroup>
                    <FormGroup className="mb-2" isRequired checkboxOrRadio label="Search by:">
                        <Radio className="d-inline-block" htmlFor="VIN" id="VIN" name="searchBy" value="VIN" onClick={() => { setSearchBy('VIN'); setTimeout(() => document.getElementById('maskNumber')?.focus()); }}>VIN Lookup</Radio>
                        <Radio className="d-inline-block" htmlFor="selector" id="selector" name="searchBy" value="selector" onClick={() => { setSearchBy('selector'); setTimeout(() => document.getElementById('maskNumber')?.focus()); }}>Vehicle Selector</Radio>
                    </FormGroup>
                </ReactAux>
                }

                {searchBy && <FormGroup className="mb-2" htmlFor="maskNumber" isRequired label="Value">
                    <MaskedInput
                        id="maskNumber"
                        type="number"
                        icon={IconsSolid.faDollarSign}
                    />
                </FormGroup>}

                {searchBy === 'VIN' && <ReactAux>
                    <FormGroup className="mb-2" htmlFor="VIN2" isRequired label="Vehicle Identification Number (VIN)">
                        <Input type="text" name="VIN2" id="VIN2" />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="mileage" label="Mileage">
                        <Input type="text" name="mileage" id="mileage" />
                    </FormGroup>
                </ReactAux>
                }

                {searchBy === 'selector' && <ReactAux>
                    <FormGroup className="mb-2" htmlFor="select" isRequired label="Year">
                        <Select
                            id="select"
                            placeholder="Select..."
                            options={[
                                { value: '1', label: '2021' },
                                { value: '2', label: '2020' },
                                { value: '3', label: '2019' },
                                { value: '4', label: '2018' },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="select2" isRequired label="Make">
                        <Select
                            id="select2"
                            placeholder="Select..."
                            options={[
                                { value: '1', label: 'Ford' },
                                { value: '2', label: 'Chevrolet' },
                                { value: '3', label: 'Toyota' },
                                { value: '4', label: 'Lexus' },
                                { value: '5', label: 'Volkswagen' },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="select3" isRequired label="Model">
                        <Select
                            id="select3"
                            placeholder="Select..."
                            options={[
                                { value: '1', label: 'Corvette' },
                                { value: '2', label: 'Cavalier' },
                                { value: '3', label: 'Trailblazer' },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2" htmlFor="select4" isRequired label="Body">
                        <Select
                            id="select4"
                            placeholder="Select..."
                            options={[
                                { value: '1', label: 'Convertible' },
                                { value: '2', label: 'Coupe' },
                            ]}
                        />
                    </FormGroup>
                </ReactAux>
                }
            </div>
        </div>
    )
}

export default InterdependentFormControlsDocs;