import React from 'react';
import Highlight from 'react-highlight';

const TypographyDocs = () => {

	return <div>
		<h1 className='mb-3'>Typography</h1>

		<h2 className='mb-2'>Basic elements</h2>
		<p className='mb-1'>One of the key features of the CSS framework in VSG Design System is the set
		of typographical elements. Notice that they are not React components, but pure HTML elements instead.</p>
		<p className="mb-1">An important thing to keep in mind is the need for environment without other stylesheets, except
		the one of VSG Design System. The typography is easy to use via global styles, which can easily be
		overriden by other style rules, thus breaking the design system.</p>
		<p className="mb-4">Notice also that the typographical elements in the CSS framework of VSG Design System do not have
		any spacings(paddings/margins) by default. This is a big contrast with many other CSS frameworks and even with the
		browser defaults. Spacing between all components and/or elements in VSG Design System is achieved
		through <b>utility classes</b>.</p>

		<h3 className='mb-3'>Headings</h3>

		<div className='mb-2'>
			<h1>Heading - H1</h1>
			<h2>Heading - H2</h2>
			<h3>Heading - H3</h3>
			<h4>Heading - H4</h4>
			<h5>Heading - H5</h5>
			<h6>Heading - H6</h6>
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';

const Example = (props) => {
    return (
    <div>
	<h1>Heading - H1</h1>
	<h2>Heading - H2</h2>
	<h3>Heading - H3</h3>
	<h4>Heading - H4</h4>
	<h5>Heading - H5</h5>
	<h6>Heading - H6</h6>
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>

		<h3 className='mb-3'>Paragraphs/Body</h3>
		<p className="mb-3">Variations in the looks of the paragraph elements are implemented as
		utility classes.</p>

		<div className='mb-2'>
			<p>Paragraph: The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
			<br />
			<p className="body1">Utility body1 (no difference with the basic paragraph): The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
			<br />
			<p className="body2">Utility body2: The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p>
		</div>

		<Highlight className="React mb-4">
			{
				`
import React from 'react';

const Example = (props) => {
    return (
    <div>
	<p>Paragraph: The point of using Lorem Ipsum...</p>
	<p className="body1">Utility body1 (no difference with the basic paragraph): The point of using Lorem Ipsum...</p>
	<p className="body2">Utility body2: The point of using Lorem Ipsum...</p>
    </div>
    );
}

export default Example; 
`
			}
		</Highlight>
	</div>;
}

export default TypographyDocs;