import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
    Button, ActionsWrapper, IconsSolid, ReactAux, Section, FormGroup, Radio, Input,
    HorizontalInfoCard, IconsLight, MaskedInput,
} from '@jkhy/vsg-design-system';

const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const home = (history: any) => {
    return <ReactAux>
        <Section
            title="Business References"
            headerText="Header text: Please enter details about your accountant, attorney and insurance agent."
            footerText="Footer text: Please continue to the next step."
        >
            <HorizontalInfoCard
                className="mb-2"
                title="Accountant - Sam Sloth"
                icon={IconsLight.faCalculator}
                onEdit={() => { }}
                onDelete={() => { }}
            />
            <Button
                className="mb-3"
                iconLeft={IconsSolid.faPlus}
                onClick={() => history.push('/views/pages/14-1')}
            >
                Add Reference
            </Button>
        </Section>

        <ActionsWrapper className="mt-2">
            <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/15' }}>Next Step</Button>
        </ActionsWrapper>
    </ReactAux>
}

const addNew = (history: any) => {
    return <ReactAux>
        <Section
            title="Business References"
            headerText="Header text: Please enter details about your accountant, attorney and insurance agent."
            footerText="Footer text: Please continue to the next step."
        >
            <FormGroup className="mb-2" isRequired checkboxOrRadio label="Reference Type.">
                <Radio htmlFor="referenceType1" id="referenceType1" name="referenceType" value="referenceType1">Accountant</Radio>
                <Radio htmlFor="referenceType2" id="referenceType2" name="referenceType" value="referenceType2">Attorney</Radio>
                <Radio htmlFor="referenceType3" id="referenceType3" name="referenceType" value="referenceType3">Insurance</Radio>
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="name" isRequired label="Name">
                <Input type="text" name="name" id="name" />
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="maskPhone" isRequired label="Phone Number">
                <MaskedInput
                    id="maskPhone"
                    type="phone"
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                />
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="emailAddress" isRequired label="Email Address">
                <Input type="email" name="emailAddress" id="emailAddress" />
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="city" isRequired label="City">
                <Input type="text" name="city" id="city" />
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="state" isRequired label="State">
                <Input type="text" name="state" id="state" />
            </FormGroup>
            <FormGroup className="mb-2" htmlFor="zipCode" isRequired label="Zip Code">
                <Input type="text" name="zipCode" id="zipCode" />
            </FormGroup>
        </Section>

        <ActionsWrapper className="mt-2">
            <Button className="action-spacer" iconRight={IconsSolid.faTimesCircle} link={{ path: '/views/pages/14' }}>Cancel</Button>
            <Button iconRight={IconsSolid.faSave} link={{ path: '/views/pages/14' }}>Save</Button>
        </ActionsWrapper>
    </ReactAux>
}

const PageBusinessReferences = () => {
    const history = useHistory();

    return (
        <Switch>
            <Route path="/views/pages/14-1" component={() => addNew(history)} />
            <Route path="/views/pages/14" component={() => home(history)} />
        </Switch>
    );
}

export default PageBusinessReferences;