import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { MaskedInput, FontAwesomeIcon, IconsSolid, FormGroup } from '@jkhy/vsg-design-system';
import PropVersionTag from '../PropVersionTag/PropVersionTag';

const MaskedInputDocs = () => {
    const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
    const ssn = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
    const creditCardMask = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const [percent, setPercent] = useState();
    const [percentMinMax, setPercentMinMax] = useState(0.99);
    return (
        <div>
            <h1 className='mb-3'><FontAwesomeIcon className="text-success" icon={IconsSolid.faCheckCircle} size="s" /> Input with mask</h1>

            <h2 className='mb-2'>Description</h2>
            <p className='mb-4'>QD Design System MaskedInput uses "Text Mask" library. Check demo <a href="https://text-mask.github.io/text-mask/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a target="_blank" rel="noopener noreferrer" href="https://github.com/text-mask/text-mask/tree/master/react#readme">here</a>.</p>

            <div className='mb-2'>
                <FormGroup label="Phone mask" htmlFor="maskPhone" className="mb-2">
                    <MaskedInput
                        id="maskPhone"
                        type='phone'
                        mask={usPhoneCountryCodeMask}
                        icon={IconsSolid.faPhoneAlt}
                    />
                </FormGroup>

                <FormGroup label="SSN mask" htmlFor="maskSSN" className="mb-2">
                    <MaskedInput
                        id="maskSSN"
                        type="password"
                        mask={ssn}
                        onChange={(e) => console.log(e.target.value)}
                    />
                </FormGroup>

                <FormGroup label="Number mask" htmlFor="maskNumber" className="mb-2">
                    <MaskedInput
                        id="maskNumber"
                        type='number'
                        icon={IconsSolid.faDollarSign}
                        onChange={(e: any) => console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
                    />
                </FormGroup>

                <FormGroup label="Percent mask default" htmlFor="maskPercent" className="mb-2">
                    <MaskedInput
                        id="maskPercent"
                        type='percent'
                        icon={IconsSolid.faPercent}
                        value={percent}
                        onChange={(e: any) => {
                            console.log("onChange value:", e.target.value);
                            setPercent(e.target.value);
                        }}
                        onBlur={(e: any) => {
                            console.log("onBlur value:", e.target.value);
                            setPercent(e.target.value);
                        }}
                    />
                </FormGroup>

                <FormGroup label="Percent mask min=20 - max=80.54" htmlFor="maskPercentMinMax" className="mb-2">
                    <MaskedInput
                        id="maskPercentMinMax"
                        type='percent'
                        icon={IconsSolid.faPercent}
                        maxPercent={80.54}
                        minPercent={20}
                        value={percentMinMax}
                        decimalLimit={2}
                        onChange={(e: any) => {
                            console.log("onChange value:", e.target.value);
                            setPercentMinMax(e.target.value);
                        }}
                        onBlur={(e: any) => {
                            console.log("onBlur value:", e.target.value);
                            setPercentMinMax(e.target.value);
                        }}
                    />
                </FormGroup>

                <FormGroup label="Zipcode mask" htmlFor="maskZip" className="mb-2">
                    <MaskedInput
                        id="maskZip"
                        mask={zipCodeMask}
                        type='zip-code'
                        icon={IconsSolid.faMapPin}
                    />
                </FormGroup>
                <FormGroup label="Card mask" htmlFor="maskCard" className="mb-2">
                    <MaskedInput
                        id="maskCard"
                        mask={creditCardMask}
                        type='credit-card'
                        icon={IconsSolid.faCreditCard}
                    />
                </FormGroup>
                <FormGroup label="Phone mask disabled" htmlFor="maskPhoneD" className="mb-2">
                    <MaskedInput
                        id="maskPhoneD"
                        type='phone'
                        mask={usPhoneCountryCodeMask}
                        icon={IconsSolid.faPhoneAlt}
                        disabled
                    />
                </FormGroup>
            </div>

            <Highlight className="React mb-4">
                {
                    `
import React, { useState } from 'react';
import {FormGroup, MaskedInput, IconsSolid } from '@jkhy/vsg-design-system';

const Example = (props) => {
const usPhoneCountryCodeMask = ['+', '1', ' ', '(', /[1-9]/, /\\d/, /\\d/, ')', ' ', /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/];
const zipCodeMask = [/\\d/, /\\d/, /\\d/, /\\d/, /\\d/];
const ssn = [/\\d/, /\\d/, /\\d/, /\\d/, /\\d/, /\\d/, /\\d/, /\\d/, /\\d/]
const creditCardMask = [/\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/, '-', /\\d/, /\\d/, /\\d/, /\\d/];
const [percent, setPercent] = useState();
const [percentMinMax, setPercentMinMax] = useState(0.99);
return (
    <div>
            <FormGroup label="Phone mask" htmlFor="maskPhone" className="mb-2">
                <MaskedInput
                    id="maskPhone"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                />
            </FormGroup>

            <FormGroup label="SSN mask" htmlFor="maskSSN" className="mb-2">
                <MaskedInput
                    id="maskSSN"
                    type="password"
                    mask={ssn}
                    onChange={(e)=> console.log(e.target.value)}
                />
            </FormGroup>

            <FormGroup label="Number mask" htmlFor="maskNumber" className="mb-2">
                <MaskedInput
                    id="maskNumber"
                    type='number'
                    icon={IconsSolid.faDollarSign}
                    onChange={(e: any) => console.log("displayed value:" + e.target.value, "formatted value:" + e.target.formattedValue)}
                />
            </FormGroup>

            <FormGroup label="Percent mask default" htmlFor="maskPercent" className="mb-2">
                <MaskedInput
                    id="maskPercent"
                    type='percent'
                    icon={IconsSolid.faPercent}
                    value = {percent}
                    onChange={(e:any)=> {
                        console.log("onChange value:", e.target.value);
                        setPercent(e.target.value);
                    }}
                    onBlur = {(e:any)=>{
                        console.log("onBlur value:", e.target.value);
                        setPercent(e.target.value);
                    }}
                />
            </FormGroup>

            <FormGroup label="Percent mask min=20 - max=80.54" htmlFor="maskPercentMinMax" className="mb-2">
                <MaskedInput
                    id="maskPercentMinMax"
                    type='percent'
                    icon={IconsSolid.faPercent}
                    maxPercent={80.54}
                    minPercent={20}
                    value={0.99}
                    decimalLimit={2}
                    onChange={(e:any)=> {
                        console.log("onChange value:", e.target.value);
                        setPercentMinMax(e.target.value);
                    }}
                    onBlur = {(e:any)=>{
                        console.log("onBlur value:", e.target.value);
                        setPercentMinMax(e.target.value);
                    }}
                />
             </FormGroup>

            <FormGroup label="Zipcode mask" htmlFor="maskZip" className="mb-2">
                <MaskedInput
                    id="maskZip"
                    mask={zipCodeMask}
                    type='zip-code'
                    icon={IconsSolid.faMapPin}
                />
            </FormGroup>

            <FormGroup label="Card mask" htmlFor="maskCard" className="mb-2">
                <MaskedInput
                    id="maskCard"
                    mask={creditCardMask}
                    type='credit-card'
                    icon={IconsSolid.faCreditCard}
                />
            </FormGroup>

              <FormGroup label="Phone mask disabled" htmlFor="maskPhoneD" className="mb-2">
                <MaskedInput
                    id="maskPhoneD"
                    type='phone'
                    mask={usPhoneCountryCodeMask}
                    icon={IconsSolid.faPhoneAlt}
                    disabled
                />
            </FormGroup>
    </div>
    );
}

export default Example; 
`
                }
            </Highlight>

            <table className="doc-table mb-3">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>type</td>
                        <td>"phone", "number", "percent", "zip-code", "credit-card", "password"</td>
                        <td>number</td>
                        <td>Set style for alignment and for some types and length of the value.</td>
                    </tr>
                    <tr>
                        <td>mask</td>
                        <td>regex</td>
                        <td>decimal number with separator comma</td>
                        <td>Set input pattern. Use <strong>only</strong> for type: "phone", "zip-code", "credit-card"</td>
                    </tr>
                    <tr>
                        <td>guide</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>Set input mask guide when user types</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>font awesome icon</td>
                        <td className='text-center'>-</td>
                        <td>Set icon for the input.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />id</td>
                        <td>string</td>
                        <td className='text-center'>-</td>
                        <td>due to WCAG 2.0 and focus form control in form group on label click.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />decimalLimit</td>
                        <td>number</td>
                        <td>3</td>
                        <td>Set decimals on MasketInput type="percent". The value should be bigger than 0.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />maxPercent</td>
                        <td>number</td>
                        <td>99.999</td>
                        <td>Set MasketInput type="percent" max value.</td>
                    </tr>
                    <tr>
                        <td><PropVersionTag type="new" />minPercent</td>
                        <td>number</td>
                        <td className='text-center'>-</td>
                        <td>Set MasketInput type="percent" min value.</td>
                    </tr>
                    <tr>
                        <td>native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td><strong>Except "type"</strong>, you can use all other native input attributes: "disabled", "name", "placeholder"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default MaskedInputDocs;