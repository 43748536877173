import React from 'react';
import { Alert } from '@jkhy/vsg-design-system';
import Highlight from 'react-highlight';
const GitFlow = () => {
    return (
        <div>
            <h1 className='mb-3'>GIT FLOW</h1>

            <h2 className='mb-2'>Standard flow with feature branches</h2>
            <div className='mb-5'>
                <h3 className='text-color mb-1'>1. Create a new branch from the latest <span className="text-primary">"develop"</span>.</h3>
                <h3 className='text-color mb-1'>2. When ready, merge to <span className="text-primary">"develop"</span></h3>
                <h3 className="text-color">3. The patch version of the npm package should be automatically updated: <span className="text-primary">1.2.3 =&gt; 1.2.4 </span>.</h3>
            </div>

            <h2 className='mb-2'>Custom flow with breaking changes</h2>
            <div>
                <h3 className='text-color mb-2'>1. Create a new branch from the latest <span className="text-primary">"develop"</span>.</h3>
                <Alert className="mb-3" type="warning" text="UXP team is responsible to notify for the upcoming breaking changes." />

                <h3 className="text-color">2. When changes are done and the code should be delivered as an npm package, create a new support branch.</h3>
                <ul className="basic-list">
                    <li>
                        <p>
                            The branch name should be constructed in the following pattern <strong className="text-primary">"support/#version#"</strong>.<br />
                            The version has to be in format <strong className="text-primary">"(major).(minor+1).0"</strong>.<br />
                            For example, if the latest version is <strong className="text-primary">"1.2.3"</strong>, the support branch should be named <strong className="text-primary">"support/1.3.0"</strong>.
                        </p>
                        <Highlight className="React mb-3">
                            {
                                `
-- latest "develop"
version: 1.2.3 ("develop")

-- pushing a branch named "support/1.3.0"
version: 1.3.0 ("support/1.3.0")
version: 1.2.3 ("develop", still available)

  `
                            }
                        </Highlight>

                        <Alert className="mb-2" type="info" text="Creating a new support branch does not impact the current version flow of the 'develop' channel." />
                    </li>

                    <li>
                        <p>
                            If additional updates have to be made after the support branch has already been published, pushing changes to that same channel will result in bumping the patch number of the support branch version:
                        </p>
                        <Highlight className="React mb-3">
                            {
                                `
-- pushing a branch named "support/1.3.0"
version: 1.2.3 ("develop")

-- commit to the same branch
version: 1.3.1 ("support/1.3.0")

-- commit to the same branch
version: 1.3.2 ("support/1.3.0")
...

  `
                            }
                        </Highlight>
                    </li>
                </ul>

                <h3 className="text-color">3. There can be multiple support branches in development at the same time.</h3>
                <ul className="basic-list">
                    <li>
                        <p>
                            If a new feature with breaking changes is going to be developed, the version of the new support branch has to increase the minor version number of the last support branch. <br />
                            For example, if there already is a support branch called <strong className="text-primary">"support/1.3.0"</strong>, the new one should be <strong className="text-primary">"support/1.4.0"</strong>.
                        </p>
                        <Highlight className="React mb-3">
                            {
                                `
-- latest "develop"
version: 1.2.3 ("develop")

-- pushing a branch named "support/1.3.0"
version: 1.3.0 ("support/1.3.0")
version: 1.2.3 ("develop")

-- pushing a branch named "support/1.4.0
version: 1.4.0 ("support/1.4.0")
version: 1.3.0 ("support/1.3.0")
version: 1.2.3 ("develop")
  `
                            }
                        </Highlight>
                    </li>
                </ul>

                <h3 className="text-color mb-2">4. When the breaking changes are integrated.</h3>
                <Alert className="mb-2" type="warning" text="VSG team is responsible to notify that the code from the support branch is ready for the 'develop' channel" />
                <ul className="basic-list">
                    <li>
                        <p>
                            The support branch has to pull the latests changes from  <strong className="text-primary">"develop"</strong>, resolve potential conflicts, etc.
                        </p>
                        <Highlight className="React mb-3">
                            {
                                `
-- latest "develop"
version: 1.2.3 ("develop")

-- pushing a branch named "support/1.3.0"
version: 1.3.0 ("support/1.3.0")
version: 1.2.3 ("develop")

-- support branch is integrated

-- pulling the latest "develop" changes
version: 1.3.1 ("support/1.3.0", containing all from "develop")
version: 1.2.3 ("develop")
  `
                            }
                        </Highlight>
                    </li>
                    <li>
                        <p>
                            A new version will be published with an update on the patch number of  <strong className="text-primary">"develop"</strong>.<br />
                            For example, merging <strong className="text-primary">"support/1.3.0"</strong> to <strong className="text-primary">"develop"</strong> will result in version <strong className="text-primary">"1.2.4"</strong>:
                        </p>
                        <Highlight className="React mb-3">
                            {
                                `
-- latest versions
version: 1.3.1 ("support/1.3.0", containing all from "develop")
version: 1.2.3 ("develop")

-- merging the support branch to "develop"
version: 1.2.4 ("develop", containting all from "support/1.3.0")
version: 1.3.1 ("support/1.3.0", still available)
  `
                            }
                        </Highlight>
                    </li>
                </ul>
                <Alert className="mb-3" type="warning" text="UXP team is responsible to notify that all changes are migrated to 'develop'." />

                <h3 className="text-color mb-2">5. The support branches, that are already integrated can be safely deleted.</h3>
                <Alert className="mb-2" type="warning" text="IMPORTANT To make sure there is no version overlaping, the latest support branch should be kept. Whenever a next breaking change has to be made and new support branch is created, the old one can be safely removed." />
            </div>

        </div >
    )
}

export default GitFlow;