import React, { useState } from 'react';

import {
  Button, Section, ReactAux, ActionsWrapper, IconsSolid, FormGroup, Input, Datepicker,
  Select, Checkbox, AddressAutocomplete
} from '@jkhy/vsg-design-system';

type AddressFormFields = {
  fullAddress: string, streetAddress: string, city: string,
  state: string, zipCode: string, country: string
};
const PageAddress = () => {

  const [formValue, setFormValue] = useState<AddressFormFields>({
    fullAddress: '', streetAddress: '', city: '', state: '', zipCode: '', country: ''
  });
  const [disabledControls, setDisabledControls] = useState(true);

  const updateAutocompleteValue = (newValue: string) => {
    if (newValue !== formValue.fullAddress) {
      setFormValue({ ...formValue, fullAddress: newValue });
    }
  };

  const configureAddressControls = (currentAddress: AddressFormFields) => {
    const fields = {
      city: '',
      state: '',
      zipCode: '',
      country: '',
      streetAddress: ''
    };
    setFormValue({ ...fields, ...currentAddress });
    enableAddressFormControls();
  };

  const handleAddressAutocompleteBlur = () => {
    enableAddressFormControls();
  };

  const enableAddressFormControls = () => {
    if (disabledControls) {
      setDisabledControls(false);
    }
  };

  return (
    <ReactAux>
      <Section title="Address" footerText="Click ‘Save’ when finished.">
        <h4 className="mb-3">Please complete your address information for the past 2 years.</h4>
        <FormGroup className="mb-2" htmlFor="currentAddress" isRequired label="Current Address">
          <AddressAutocomplete
            type="text"
            name="currentAddress"
            id="currentAddress"
            value={formValue.fullAddress}
            onChange={(e: any) => updateAutocompleteValue(e)}
            onAddressSelect={(e: any) => configureAddressControls(e)}
            onBlur={() => handleAddressAutocompleteBlur()}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="streetAddress" isRequired label="Street Address">
          <Input type="text" name="streetAddress" id="streetAddress" onChange={(e: any) => setFormValue({ ...formValue, streetAddress: e.target.value },)} value={formValue.streetAddress} disabled={disabledControls} />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="city" isRequired label="City">
          <Input type="text" name="city" id="city" onChange={(e: any) => setFormValue({ ...formValue, city: e.target.value },)} value={formValue.city} disabled={disabledControls} />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="state" isRequired label="State">
          <Input type="text" name="state" id="state" onChange={(e: any) => setFormValue({ ...formValue, state: e.target.value },)} value={formValue.state} disabled={disabledControls} />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="zipCode" isRequired label="Zip Code">
          <Input type="text" name="zipCode" id="zipCode" onChange={(e: any) => setFormValue({ ...formValue, zipCode: e.target.value },)} value={formValue.zipCode} disabled={disabledControls} />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="country" isRequired label="Country">
          <Input type="text" name="country" id="country" onChange={(e: any) => setFormValue({ ...formValue, country: e.target.value },)} value={formValue.country} disabled={disabledControls} />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="dpDef" isRequired label="Address Start Date">
          <Datepicker id="dpDef" />
        </FormGroup>
        <FormGroup
          htmlFor="housingStatus"
          className="mb-2"
          label="What is your current housing status?">
          <Select
            id="housingStatus"
            options={[
              { value: 'status-1', label: 'Status 1' },
              { value: 'status-2', label: 'Status 2' }
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="differentAddress" isRequired label="Mailing Address" checkboxOrRadio>
          <Checkbox className="mb-05" htmlFor="differentAddress" id="differentAddress" name="differentAddress">Different from above</Checkbox>
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="mailingAddress" isRequired label="Enter Mailing Address">
          <Input type="text" name="mailingAddress" id="mailingAddress" disabled />
        </FormGroup>
      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/4' }}>Save</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PageAddress;