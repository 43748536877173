import React from 'react';

import {
  Button, Section, FormGroup, Select, Radio, Input, ActionsWrapper, IconsSolid,
  ReactAux, ScrollableContainer, Checkbox,
} from '@jkhy/vsg-design-system';

const PageInformation = () => {
  return (
    <ReactAux>
      <Section
        title="Information"
        headerText="Header text: Text box where FI can indicate what the purpose of this page is for."
        footerText="Footer text: Text box where FI can indicate what the purpose of this page is for."
      >
        <h3 className="mb-2">Declarations</h3>
        <FormGroup className="mb-2" isRequired checkboxOrRadio label="Custom Questions can be added by the FI.">
          <Radio htmlFor="yes" id="yes" className="d-inline-block" name="question" value="yes">Yes</Radio>
          <Radio htmlFor="no" id="no" className="d-inline-block" name="question" value="no">No</Radio>
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="branch" isRequired label="Plese select which branch you prefer to work with:">
          <Select
            id="branch"
            placeholder="Select..."
            options={[
              { value: '1', label: 'North - 123 Main St' },
              { value: '2', label: 'Test City' },
              { value: '3', label: 'South - 456 Main St' },
              { value: '4', label: 'Test City' },
              { value: '5', label: 'etc.' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="currentBanker" isRequired label="If you have a banking relationship with a current banker, please select their name:">
          <Select
            id="currentBanker"
            placeholder="Select..."
            options={[
              { value: '1', label: 'Dave' },
              { value: '2', label: 'Kevin' },
              { value: '3', label: 'Bob' },
              { value: '4', label: 'Stuart' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="zipcode" isRequired label="Please enter the zip code of where the business is located?">
          <Input type="text" name="zipcode" id="zipcode" />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="stateLocated" isRequired label="Select the State where the business is located?">
          <Select
            id="stateLocated"
            placeholder="Select..."
            options={[
              { value: '1', label: 'Alabama' },
              { value: '2', label: 'Alaska' },
              { value: '3', label: 'Arizona' },
              { value: '4', label: 'Arkansas' },
              { value: '5', label: 'etc.' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-2" htmlFor="countyLocated" isRequired label="Select the County where the business is located?">
          <Select
            id="countyLocated"
            placeholder="Select..."
            options={[
              { value: '1', label: 'USA' },
              { value: '2', label: 'Mexico' },
              { value: '3', label: 'etc.' },
            ]}
          />
        </FormGroup>
        <FormGroup className="mb-3" isRequired checkboxOrRadio label="Do you consent to receive communication electronically via email? If you do not, the online application cannot be process. Please contact us a 888-888-8888 for assistance with obtaining a loan.">
          <Radio htmlFor="yes2" id="yes2" className="d-inline-block" name="question2" value="yes">Yes</Radio>
          <Radio htmlFor="no2" id="no2" className="d-inline-block" name="question2" value="no">No</Radio>
        </FormGroup>

        <h3 className="mb-2">Declarations</h3>
        <ScrollableContainer className="mb-1">
          <h3 className="mb-05">Truth in Lending</h3>
          <p className="mb-4">
            This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin</p>
          <h3 className="mb-05">Application Notification</h3>
          <p className="mb-4">
            HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
          </p>
          <h3 className="mb-05">Application Notification</h3>
          <p>
            This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin
            HTML text allows the FI to bold or use bullet point to make the text easily readable.This would be te location of any Disclosures to be displayed to the applicant that is is customizable by the FI. The can assing separate disclosures in Admin but on the application to the applicant,  text
          </p>
        </ScrollableContainer>
        <Checkbox htmlFor="accept" id="accept" name="accept">
          I have read and agree to the disclosures and terms above.
        </Checkbox>
      </Section>

      <ActionsWrapper className="mt-2">
        <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/3' }}>Next step</Button>
      </ActionsWrapper>
    </ReactAux>
  );
}

export default PageInformation;