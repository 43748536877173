import React from 'react';

import './propVersionTag.scss';

const LATEST_VERSION = 'v.4.1';

const PropVersionTag = (props: { type: 'new' | 'obsolete', version?: string }) => (
  <div>
    <span className={`prop-version-tag ${props.type === 'new' ? 'tag-new' : 'tag-obsolete'}`}>
      {props.type === 'new' ? `new in ${props.version || LATEST_VERSION}` : 'obsolete'}
    </span>
  </div>
);

export default PropVersionTag;